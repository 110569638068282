import React, { useEffect, useState } from "react";
import "./Logistics.css";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import TableHeader from "../../../App/components/shared/TableHeader";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {
  ModalForm,
  ModalQuestion,
} from "../../../App/components/shared/Modals";
import _ from "lodash";
import {
  faTimesCircle,
  faFilePdf,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import { Modal_Edit_Requirement } from "./ModalEdit";
import Loading from "../../../App/components/Loading/Loading";
import { useCookies } from "react-cookie";
import { ModalFetch } from "../../../App/components/shared/Modals";
import { saveAs } from "file-saver";
import { PDFDownloadLink, pdf, PDFViewer } from "@react-pdf/renderer";
import Remision from "./Remision";

function Logistics() {
  const vehicles = [
    {
      value: "Grúa Basculante",
      label: "Grúa Basculante",
    },
    {
      value: "Tracto-mula",
      label: "Tracto-mula",
    },
    {
      value: "Brazo Grúa 20 pies",
      label: "Brazo Grúa 20 pies",
    },
    {
      value: "Brazo Grúa 40 pies",
      label: "Brazo Grúa 40 pies",
    },
    {
      value: "Grúa PH",
      label: "Grúa PH",
    },
    {
      value: "Montacargas",
      label: "Montacargas",
    },
    {
      value: "Moto",
      label: "Moto",
    },
    {
      value: "Camión Estacas 2.5 T",
      label: "Camión Estacas 2.5 T",
    },
    {
      value: "Camión Planchón",
      label: "Camión Planchón",
    },
    {
      value: "Turbo",
      label: "Turbo",
    },
    {
      value: "Sencillo",
      label: "Sencillo",
    },
  ];
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [sortAscen, setSortAscen] = useState();
  const [json_response, setJson_response] = useState([]);
  const [clicked_column, setClicked_column] = useState("");
  const [backupJson_response, setBackupJson_response] = useState();
  const [open_array, setOpen_array] = useState([]);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [messageModal, setMessageModal] = useState();
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [req_to_edit, setReq_to_edit] = useState({});
  const [infoRemision, setInfoRemision] = useState();
  const [filtered_cars, setFiltered_cars] = useState([]);

  //fetch states
  const [isLoading, setIsLoading] = useState();
  const [openModalFetch, setOpenModalFetch] = useState();
  const [fetchSuccess, setFetchSuccess] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_centro_log;

  //modalquestion
  const [indexToDelete, setIndexToDelete] = useState(false);
  const [openModalQuestion, setOpenModalQuestion] = useState(false);

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });

    setIsLoading(true);
    fetch(`${global.url_back}/logistic/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setJson_response(json_response);
            setBackupJson_response(json_response);

            const arr_open = json_response.logistic_tasks?.map((task) => false);
            setOpen_array(arr_open);
            setIsLoading(false);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  }, []);

  const onCloseModalFetch = () => {
    setOpenModalFetch(false);
  };

  const onCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
  };

  const onCloseModalQuestion = () => {
    setOpenModalQuestion(false);
  };

  const callbackModalQuestion = ({ value, params }) => {
    if (value === "true") {
      deleteReq(params);
    } else {
      setOpenModalQuestion(false);
    }
  };

  const deleteReq = async (index) => {
    let tasks = JSON.parse(JSON.stringify(json_response.logistic_tasks));
    const fetch_result = await fetch_to_save(tasks[index], "DELETE");

    if (fetch_result) {
      tasks.splice(index, 1);
      setJson_response({ ...json_response, logistic_tasks: tasks });
      setOpenModalQuestion(false);
    }
  };

  const fetch_to_save = async (row, method) => {
    // --PENDING--
    setIsLoading(true);
    console.log(row);

    return fetch(`${global.url_back}/logistic/${row.nro_req}/`, {
      method: `${method}`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(row),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);

          return true;
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  const ClickEditReq = (row, index) => {
    row["index"] = index;
    setReq_to_edit(row);
    setOpenModalEdit(true);
  };

  const onSubmitEditModal = async (valuesModal) => {
    const index_to_edit = valuesModal.index;
    let tasks = json_response.logistic_tasks;
    tasks[index_to_edit] = valuesModal;
    const row = tasks[index_to_edit];
    await fetch_to_save(row, "PUT");
    setJson_response({ ...json_response, logistic_tasks: tasks });
    setOpenModalEdit(false);
  };

  const validate = (row) => {
    console.log(row);
    const errors = {};

    if (!row.ref_contenedor && row.tipo_req != "Movimiento") {
      errors.ref_contenedor = 'Falta completar "Referencia"';
    }
    if (!row.fecha_logistica) {
      errors.fecha_logistica = 'Falta completar "Fecha"';
    }
    if (!row.nombre_conductor) {
      errors.nombre_conductor = 'Falta completar "Nombre Conductor"';
    }
    if (!row.cedula) {
      errors.cedula = 'Falta completar "Cedula"';
    }
    if (!row.telefono_conductor) {
      errors.telefono_conductor = 'Falta completar "Telefono Conductor"';
    }
    if (!row.placa) {
      errors.placa = 'Falta completar "Placa"';
    }
    if (!row.tipo_vehiculo) {
      errors.tipo_vehiculo = 'Falta completar "Tipo Vehículo"';
    }
    if (!row.direccion) {
      errors.direccion = 'Falta completar "Dirección"';
    }
    if (!row.adiciones) {
      errors.adiciones = 'Falta completar "Adiciones"';
    }
    if (!row.documentacion_enviada) {
      errors.documentacion_enviada = 'Falta completar "Documentación Enviada"';
    }
    // if (!row.montacarga) {
    //   errors.montacarga = 'Falta completar "Programar Montacargas"';
    // }

    return errors;
  };

  const styles_react_select = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderWidth: 0,
      height: "25px",
      minHeight: "25px",
      maxWidth: "300px",
      borderRadius: "5px",
      // backgroundColor: "blue",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "13px",
      maxWidth: "300px",
      // backgroundColor: "green",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      padding: "0 6px",
      maxWidth: "300px",
      // backgroundColor: "pink",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0",
      maxWidth: "300px",
      // backgroundColor: "red",
      fontSize: "13px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
      // backgroundColor: "yellow",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      maxWidth: "300px",
      // backgroundColor: "orange",
    }),

    placeholder: (styles) => ({
      ...styles,
      fontSize: "13px",
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: "13px",
    }),
  };

  const change_select_table = async (event, index) => {
    const { name, value } = event.target;
    let tasks = JSON.parse(JSON.stringify(json_response.logistic_tasks));
    tasks[index][name] = value;

    if (name === "ref_contenedor" || name === "fecha_logistica") {
      const fetch_result = await fetch_to_save(tasks[index], "PUT");
      if (fetch_result) {
        setJson_response({ ...json_response, logistic_tasks: tasks });
      }
    } else {
      setJson_response({ ...json_response, logistic_tasks: tasks });
    }
  };

  const update_color_after_pdf = (index) => {
    const name = "color";
    let tasks = JSON.parse(JSON.stringify(json_response.logistic_tasks));
    tasks[index][name] = "green";
    setJson_response({ ...json_response, logistic_tasks: tasks });
  };

  const change_checkbox = (event, index, montacarga = false) => {
    let { name, checked, value } = event.target;
    let tasks = json_response.logistic_tasks;
    if (montacarga) {
      name = "montacarga";
      value = value === "true";
      tasks[index][name] = value;
    } else {
      tasks[index][name] = checked;
    }
    setJson_response({ ...json_response, logistic_tasks: tasks });
  };

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response.logistic_tasks];
    listTasks.sort((a, b) =>
      a[sub].toString().localeCompare(b[sub].toString())
    );
    if (!sortAscen) {
      setJson_response(listTasks.reverse());
    }
    setJson_response({ ...json_response, logistic_tasks: listTasks });
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const handleSearch = (value) => {
    let newList = [...json_response.logistic_tasks];
    if (value !== "") {
      newList = newList.filter(
        (row) =>
          row.nombre_contacto.toLowerCase().includes(value.toLowerCase()) ||
          row.nombre_cliente.toLowerCase().includes(value.toLowerCase()) ||
          row.ref_contenedor.toLowerCase().includes(value.toLowerCase())
      );
      setJson_response({ ...json_response, logistic_tasks: newList });
    } else {
      setJson_response(backupJson_response);
    }
  };

  const click_open_detail = (index) => {
    let arr_open = [...open_array];
    arr_open[index] = !arr_open[index];
    setOpen_array(arr_open);
  };

  const click_select_driver = (index, value) => {
    let tasks = json_response.logistic_tasks;
    tasks[index]["nombre_conductor"] = value.label;

    if (!value.__isNew__) {
      tasks[index]["id_conductor"] = value.value;
      tasks[index]["cedula"] = value.cedula;
      tasks[index]["telefono_conductor"] = value.telefono_conductor;
      tasks[index]["plates_to_choose"] = value.carros;
      const cars = json_response.drivers.filter(
        (obj) => obj.value === value.value
      );
      setFiltered_cars(cars[0]?.carros);
    } else {
      tasks[index]["id_conductor"] = null;
      tasks[index]["cedula"] = "";
      tasks[index]["telefono_conductor"] = "";
      tasks[index]["plates_to_choose"] = [];
      setFiltered_cars(json_response.carros);
    }
    setJson_response({ ...json_response, logistic_tasks: tasks });
  };

  const click_select_plate = (index, value) => {
    let tasks = json_response.logistic_tasks;
    tasks[index]["placa"] = value.label;

    if (!value.__isNew__) {
      tasks[index]["id_placa"] = value.value;
      tasks[index]["tipo_vehiculo"] = value.tipo_vehiculo;
    } else {
      tasks[index]["id_placa"] = null;
      tasks[index]["tipo_vehiculo"] = "";
    }
    console.log(tasks[index]);
    setJson_response({ ...json_response, logistic_tasks: tasks });
  };

  const click_select_vehicle = (index, value) => {
    let tasks = json_response.logistic_tasks;
    tasks[index]["tipo_vehiculo"] = value.label;
    setJson_response({ ...json_response, logistic_tasks: tasks });
  };

  const send_task = async (index, edit_contact = false) => {
    const row = json_response.logistic_tasks[index];
    await fetch_to_save(row, "PUT");
    if (!edit_contact) {
      click_open_detail(index);
    }
  };

  const onFocusSearch = () => {
    setBackupJson_response(json_response);
  };

  const generatePDF = (row, index) => {
    const errors = validate(row);

    if (!_.isEmpty(errors, index)) {
      setSuccessModal(false);
      setMessageModal(errors);
      setOpenModalForm(true);
      return;
    }

    fetch(`${global.url_back}/remision/${row.nro_req}/`, {
      method: `GET`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            generate_docs(json_response);
            update_color_after_pdf(index);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  async function generate_docs(data_tables) {
    const contract = <Remision data_tables={data_tables} />;

    const asPdf_contract = pdf(); // {} is important, throws without an argument
    asPdf_contract.updateContainer(contract);
    const blob_contract = await asPdf_contract.toBlob();
    saveAs(blob_contract, `${data_tables.title}.pdf`);
  }

  return (
    <>
      {openModalEdit && (
        <Modal_Edit_Requirement
          open={openModalEdit}
          onClose={onCloseModalEdit}
          editReq={req_to_edit}
          onSubmitEditModal={onSubmitEditModal}
        />
      )}
      <ModalForm
        open={openModalForm}
        onClose={onCloseModalForm}
        successModal={successModal}
        messageModal={messageModal}
      />

      <ModalFetch
        open={openModalFetch}
        onClose={onCloseModalFetch}
        fetchSuccess={fetchSuccess}
      />

      <ModalQuestion
        open={openModalQuestion}
        question="¿Está seguro que desea cerrar este requerimiento?"
        callback={callbackModalQuestion}
        onClose={onCloseModalQuestion}
        params={indexToDelete}
      />

      <div className="wrapped__container_top_bar ">
        <Loading isLoading={isLoading} />

        <div className="top-bar">
          <h3>Logística</h3>
        </div>
        <div className="search_div">
          <div className="search_container">
            <input
              type="text"
              onChange={(event) => handleSearch(event.target.value)}
              onFocus={() => onFocusSearch()}
            />
            <FontAwesomeIcon icon={faSearch} color="#383838" />
          </div>
          <div className="guides_2">
            <div className="sub_guide">
              <div
                className="square_estado"
                style={{ backgroundColor: "red", marginRight: "5px" }}
              ></div>
              <p>Sin Gestionar</p>
            </div>
            <div className="sub_guide">
              <div
                className="square_estado"
                style={{ backgroundColor: "green", marginRight: "5px" }}
              ></div>
              <p>Gestionada</p>
            </div>
          </div>
        </div>
        <div className="task-content">
          <table className="table_tasks">
            <tr className="rowHeaders">
              <th style={{ width: "3%" }} />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="No. Req"
                txt_filter="nro_req"
                width="6%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Tipo Req."
                txt_filter="tipo_req"
                width="9%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Referencia"
                txt_filter="ref_contenedor"
                width="12%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Producto"
                txt_filter="producto"
                width="10%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Cliente"
                txt_filter="nombre_cliente"
                width="17%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Contacto"
                txt_filter="nombre_contacto"
                width="11%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Celular"
                txt_filter="celular"
                width="10%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Fecha"
                txt_filter="fecha_logistica"
                width="10%"
              />
              <TableHeader col_name="Detalle" width="3%" />
              <TableHeader col_name="Act." width="3%" />
              <TableHeader col_name="PDF" width="3%" />
              <TableHeader col_name="Cerrar" width="3%" />
            </tr>
            {json_response?.logistic_tasks?.map((row, index) => (
              <>
                <tr className="rowTasks">
                  <td>
                    <div className="wrapper-estado">
                      <div
                        className="square_estado"
                        style={{ backgroundColor: `${row.color}` }}
                      ></div>
                    </div>
                  </td>
                  <td>{row.nro_req}</td>
                  <td>{row.tipo_req}</td>
                  <td>
                    <select
                      className="select_table"
                      name="ref_contenedor"
                      value={row.ref_contenedor}
                      onChange={(e) => change_select_table(e, index)}
                    >
                      <option>-</option>
                      {json_response?.available_refs?.map((ref) => (
                        <option value={ref}>{ref}</option>
                      ))}
                      {row.ref_contenedor && (
                        <option value={row.ref_contenedor}>
                          {row.ref_contenedor}
                        </option>
                      )}
                    </select>
                  </td>
                  <td>{row.producto}</td>
                  <td>{row.nombre_cliente}</td>
                  <td>{row.nombre_contacto}</td>
                  <td>{row.celular}</td>
                  <td>
                    <input
                      className="input_table"
                      type="date"
                      name="fecha_logistica"
                      value={row.fecha_logistica}
                      onChange={(e) => change_select_table(e, index)}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#383838"
                      className="iconRow"
                      onClick={() => ClickEditReq(row, index)}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faClipboard}
                      color="#383838"
                      className="iconRow"
                      onClick={() => click_open_detail(index)}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      color="#383838"
                      className="iconRow"
                      onClick={() => generatePDF(row, index)}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="#383838"
                      className="iconRow"
                      onClick={() => {
                        setIndexToDelete(index);
                        setOpenModalQuestion(true);
                      }}
                    />
                  </td>
                </tr>
                {open_array && open_array[index] && (
                  <tr>
                    <td colspan="13" className="open_logistic">
                      <div className="detail_logistic_container">
                        <div className="detail_logistic_container_left">
                          <div
                            className="bar_detail_logistic"
                            style={{ backgroundColor: "#78B994" }}
                          />
                          <div className="detail_logistic_container_flex">
                            <p
                              className="bold"
                              style={{
                                marginBottom: "15px",
                                color: "#266E45",
                                fontSize: "14px",
                              }}
                            >
                              Información Conductor
                            </p>

                            <div className="simple_flex">
                              <p>Nombre Conductor</p>
                              <div className="teste">
                                <CreatableSelect
                                  className="basic-single"
                                  classNamePrefix="select"
                                  placeholder="Seleccione..."
                                  isSearchable={true}
                                  defaultValue={
                                    row.nombre_conductor
                                      ? {
                                          value: row.id_conductor,
                                          label: row.nombre_conductor,
                                        }
                                      : null
                                  }
                                  name="nombre_conductor"
                                  options={json_response?.drivers}
                                  styles={styles_react_select}
                                  onChange={(value) =>
                                    click_select_driver(index, value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="simple_flex">
                              <p>Cedula</p>
                              {row.id_conductor ? (
                                <p>{row.cedula}</p>
                              ) : (
                                <input
                                  className="input_detail_logistic"
                                  value={row.cedula}
                                  name="cedula"
                                  onChange={(e) =>
                                    change_select_table(e, index)
                                  }
                                />
                              )}
                            </div>
                            <div className="simple_flex">
                              <p>Telefono</p>
                              <input
                                className="input_detail_logistic"
                                value={row.telefono_conductor}
                                name="telefono_conductor"
                                onChange={(e) => change_select_table(e, index)}
                              />
                            </div>
                            <div className="simple_flex">
                              <p>Placa</p>
                              <CreatableSelect
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Seleccione..."
                                isSearchable={true}
                                defaultValue={
                                  row.placa
                                    ? {
                                        value: row.id_placa,
                                        label: row.placa,
                                      }
                                    : null
                                }
                                name="placa"
                                options={filtered_cars}
                                styles={styles_react_select}
                                onChange={(value) =>
                                  click_select_plate(index, value)
                                }
                              />
                            </div>
                            <div className="simple_flex">
                              <p>Tipo Vehiculo</p>
                              {row.id_placa ? (
                                <p>{row.tipo_vehiculo}</p>
                              ) : (
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  placeholder="Seleccione..."
                                  isSearchable={true}
                                  name="tipo_vehiculo"
                                  defaultValue={{
                                    value: row.tipo_vehiculo,
                                    label: row.tipo_vehiculo,
                                  }}
                                  styles={styles_react_select}
                                  options={vehicles}
                                  onChange={(value) =>
                                    click_select_vehicle(index, value)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="detail_logistic_container_center">
                          <div
                            className="bar_detail_logistic"
                            style={{ backgroundColor: "#FFBF8E" }}
                          />
                          <div className="detail_logistic_container_flex">
                            <p
                              className="bold"
                              style={{
                                marginBottom: "15px",
                                color: "#FF7913",
                                fontSize: "14px",
                              }}
                            >
                              Informacion Entrega
                            </p>
                            <div className="simple_flex">
                              <p>Direccion Exacta</p>
                              <input
                                type="text"
                                className="input_detail_logistic"
                                name="direccion"
                                value={row.direccion}
                                onChange={(e) => change_select_table(e, index)}
                              />
                            </div>
                            <div className="simple_flex">
                              <p>Hora</p>
                              <input
                                type="time"
                                className="input_detail_logistic"
                                name="time"
                                value={row.time}
                                onChange={(e) => change_select_table(e, index)}
                              />
                            </div>
                            <div className="simple_flex">
                              <p>Adiciones Entrega</p>
                            </div>
                            <textarea
                              className="textarea_detail_logistic"
                              name="adiciones"
                              value={row.adiciones}
                              onChange={(e) => change_select_table(e, index)}
                              style={{ whiteSpace: "pre-line" }}
                            />
                          </div>
                        </div>
                        <div className="detail_logistic_container_right">
                          <div className="detail_logistic_container_flex">
                            <div className="flex_checkbox_detail_logistic">
                              <input
                                type="checkbox"
                                name="documentacion_enviada"
                                checked={row.documentacion_enviada}
                                onChange={(e) => change_checkbox(e, index)}
                              />
                              <p className="bold">Documentacion Enviada</p>
                            </div>
                            <div className="montacarga_section">
                              <p className="bold">Programar Montacargas</p>
                              <div
                                className="simple_flex"
                                style={{ justifyContent: "space-around" }}
                              >
                                <div>
                                  <input
                                    type="Radio"
                                    value="true"
                                    name={`montacarga${index}`}
                                    checked={
                                      row.montacarga === true ? true : false
                                    }
                                    onClick={(e) =>
                                      change_checkbox(e, index, true)
                                    }
                                  />
                                  <label className="label_radio_detail_logistic">
                                    Si
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="Radio"
                                    name={`montacarga${index}`}
                                    value="false"
                                    checked={
                                      row.montacarga === false ? true : false
                                    }
                                    onClick={(e) =>
                                      change_checkbox(e, index, true)
                                    }
                                  />
                                  <label className="label_radio_detail_logistic">
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <button
                              style={{
                                margin: "57px 0 0 auto ",
                                padding: "3px 15px",
                              }}
                              onClick={() => send_task(index)}
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </table>
        </div>
      </div>
    </>
  );
}

export default Logistics;
