const styles_react_select = {
  control: (styles) => ({
    ...styles,
    fontFamily: "Roboto, sans-serif",
    borderWidth: 1,
    height: "27px",
    minHeight: "27px",
    // maxWidth: "150px",
    borderRadius: "5px",
    // backgroundColor: "blue",
  }),
  option: (styles) => ({
    ...styles,
    color: "#383838",
    fontFamily: "Roboto, sans-serif",
    fontSize: "13px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "27px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    marginTop: "-2.5px",

    fontSize: "13px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "27px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
  }),

  placeholder: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),

  singleValue: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),
};

const styles_react_select_cost_centers = {
  control: (styles) => ({
    ...styles,
    fontFamily: "Roboto, sans-serif",
    borderWidth: 1,
    height: "27px",
    minHeight: "27px",
    minWidth: "200px",
    borderRadius: "5px",
  }),
  option: (styles) => ({
    ...styles,
    color: "#383838",
    fontFamily: "Roboto, sans-serif",
    fontSize: "13px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "27px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    marginTop: "-2.5px",

    fontSize: "13px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "27px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0px",
  }),

  placeholder: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),

  singleValue: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),
};

export { styles_react_select, styles_react_select_cost_centers };
