import React, { useEffect, useState } from "react";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useRouteMatch, useHistory } from "react-router-dom";
import "./Movements.css";
import { ModalForm, ModalFetch } from "../../../App/components/shared/Modals";
import _ from "lodash";
import Loading from "../../../App/components/Loading/Loading";
import { useCookies } from "react-cookie";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CreatableSelect from "react-select/creatable";
import { url_centro_log_logistics } from "../../constants/constants";

function Movements() {
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [formValues, setFormValues] = useState({});
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [messageModal, setMessageModal] = useState();
  const history = useHistory();

  //fetch states
  const [isLoading, setIsLoading] = useState();
  const [json_response, setJson_response] = useState([]);
  const [openModalFetch, setOpenModalFetch] = useState();
  const [fetchSuccess, setFetchSuccess] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_centro_log;

  const onCloseModalFetch = () => {
    setOpenModalFetch(false);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
  };

  const styles_react_select = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      border: "1px solid #707070",
      height: "32px",
      minHeight: "32px",
      borderRadius: "10px",
      width: "100%",
      marginBottom: "15px",
      // backgroundColor: "blue",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      // backgroundColor: "green",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
      // backgroundColor: "pink",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0",
      // backgroundColor: "red",
      fontSize: "16px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
      // backgroundColor: "yellow",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      // backgroundColor: "orange",
    }),

    placeholder: (styles) => ({
      ...styles,
      fontSize: "16px",
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: "16px",
    }),
  };

  const MapsConfig = {
    apiKey: "AIzaSyBLW2u3Jr70NZTMheJVqo9NLGN75VtS1AI",
    apiOptions: {
      language: "es-419",
      region: "co",
    },
    autocompletionRequest: {
      componentRestrictions: {
        country: "co",
      },
    },
    minLengthAutocomplete: 3,
    selectProps: {
      styles: styles_react_select,
      placeholder: "Seleccione...",
      onChange: (value, e) => onChangeReactSelect(value, e),
    },
  };

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });

    setIsLoading(true);
    fetch(`${global.url_back}/movement/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setJson_response(json_response);
            setIsLoading(false);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  }, []);

  const sendForm = (e) => {
    e.preventDefault();
    console.log(formValues);
    setIsLoading(true);
    fetch(`${global.url_back}/movement/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    })
      .then((response) => {
        if (response.status === 200) {
          setFetchSuccess(true);
          history.push(url_centro_log_logistics);
          //clean form
          let values = { ...formValues };
          Object.keys(values).forEach((k) => (values[k] = ""));
          setFormValues(values);
        } else {
          setFetchSuccess(false);
        }
        setOpenModalFetch(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  const ChangeInput = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const onChangeReactSelect = (value, e) => {
    const name = e.name;
    const values = { ...formValues };
    if (name === "nombre_conductor" && !value.__isNew__) {
      values["id_conductor"] = value.value;
      values["cedula_conductor"] = value.cedula;
      values["telefono_conductor"] = value.telefono_conductor;
    } else {
      values["id_conductor"] = undefined;
      values["cedula_conductor"] = "";
    }
    values[name] = value.label;
    setFormValues(values);
  };

  return (
    <>
      <ModalForm
        open={openModalForm}
        onClose={onCloseModalForm}
        successModal={successModal}
        messageModal={messageModal}
      />

      <ModalFetch
        open={openModalFetch}
        onClose={onCloseModalFetch}
        fetchSuccess={fetchSuccess}
      />
      <div className="main_container_movements">
        <Loading isLoading={isLoading} />
        <h2>MOVIMIENTOS</h2>
        <form onSubmit={(e) => sendForm(e)}>
          <div className="flex_mov_sec1">
            <div className="flex_mov_sec1_col">
              <p className="p_mov">Nombre</p>
              <input
                type="text"
                required
                onChange={(e) => ChangeInput(e)}
                name="nombre"
                value={formValues.nombre}
                className="input_mov"
              />
              <p className="p_mov">Cédula</p>
              <input
                type="text"
                required
                onChange={(e) => ChangeInput(e)}
                name="cedula"
                value={formValues.cedula}
                className="input_mov"
              />
              <p className="p_mov">Celular</p>
              <input
                type="text"
                required
                onChange={(e) => ChangeInput(e)}
                name="celular"
                value={formValues.celular}
                className="input_mov"
              />
            </div>
            <div className="flex_mov_sec1_col">
              <p className="p_mov">Origen</p>
              <GooglePlacesAutocomplete
                // apiKey={MapsConfig.apiKey}
                autocompletionRequest={MapsConfig.autocompletionRequest}
                selectProps={{
                  ...MapsConfig.selectProps,
                  name: "origen",
                }}
                apiOptions={MapsConfig.apiOptions}
                minLengthAutocomplete={MapsConfig.minLengthAutocomplete}
              />
              <p className="p_mov">Destino</p>
              <GooglePlacesAutocomplete
                // apiKey={MapsConfig.apiKey}
                autocompletionRequest={MapsConfig.autocompletionRequest}
                selectProps={{
                  ...MapsConfig.selectProps,
                  name: "destino",
                }}
                apiOptions={MapsConfig.apiOptions}
                minLengthAutocomplete={MapsConfig.minLengthAutocomplete}
              />
              <p className="p_mov">Valor</p>
              <input
                type="number"
                required
                onChange={(e) => ChangeInput(e)}
                name="valor"
                value={formValues.valor}
                className="input_mov"
              />
            </div>
          </div>
          <h4>Información Conductor</h4>
          <div className="flex_mov_sec2">
            <div className="flex_mov_sec1_col">
              <p className="p_mov">Nombre</p>
              <CreatableSelect
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                isSearchable={true}
                name="nombre_conductor"
                options={json_response}
                styles={styles_react_select}
                onChange={(value, e) => onChangeReactSelect(value, e)}
                value={{
                  value: formValues.id_conductor || "",
                  label: formValues.nombre_conductor,
                }}
              />
            </div>
            <div className="flex_mov_sec1_col">
              <p className="p_mov">Cedula</p>
              {formValues.id_conductor != undefined ? (
                <p style={{ marginTop: "10px" }}>
                  {formValues.cedula_conductor}
                </p>
              ) : (
                <input
                  type="text"
                  required
                  onChange={(e) => ChangeInput(e)}
                  name="cedula_conductor"
                  value={formValues.cedula_conductor}
                  className="input_mov"
                />
              )}
            </div>
          </div>
          <h4>Descripción de la Carga</h4>
          <div className="mov_sec3">
            <textarea
              onChange={(e) => ChangeInput(e)}
              name="descripcion_carga"
              value={formValues.descripcion_carga}
            />
            <button type="submit">Guardar</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Movements;
