import React, { useState, useEffect } from "react";
import "./ContactAdd.css";
import { Prompt } from "react-router";

import Select from "react-select";
import Cookies from "universal-cookie";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { useFormik } from "formik";
import {
  Cities,
  Industries,
  Month_numbers,
  Months,
} from "../../../App/services/services";
import { CountryDropdown } from "react-country-region-selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faIndustry,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from "react-router-dom";
import { url_crm_contacts } from "../../../CRM/constants/constants";

function EditContact(props) {
  const location = useLocation();
  const json_response = location.state.state.json_response;

  const history = useHistory();

  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseClients, setResponseClients] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [countryClient, setCountryClient] = useState("Colombia");

  const [isSearch, setIsSearch] = useState(false);
  const [searchBy, setSearchBy] = useState("Nombre");

  const [edit, setEdit] = useState(false);

  const [shouldBlockNavigation, setBlock] = useState(false);

  const razon = json_response?.razon_social;

  const [query, setQuery] = useState(razon.value);

  const doSomething = () => {
    var input;
    input = window.confirm("Desea salir sin guardar los cambios?");
    global.cancel_rut = input;

    return input;
  };

  const formik = useFormik({
    initialValues: {
      pk: json_response?.data.pk,
      contactName: json_response?.data.nombres,
      contactLastName: json_response?.data.apellidos,
      position: json_response?.data.cargo,
      email: json_response?.data.email,
      nroInd: json_response?.data.indic_telefono,
      telephone: json_response?.data.telefono,
      extension: json_response?.data.extension,
      telephone: json_response?.data.telefono,
      cellphone: json_response?.data.celular,
      gender: json_response?.data.genero,

      city: json_response?.data.ciudad,
      dia: json_response?.data.dia_cumpleaños,
      mes: json_response?.data.mes_cumpleaños,

      profesion: json_response?.data.profesion,
    },

    onSubmit: (values) => {
      setBlock(false);
      setIsSubmitting(true);
      if (query === "") {
        alert("Debes seleccionar una empresa para agregar tu contacto.");
        setIsSubmitting(false);
      } else {
        // alert(JSON.stringify(values, null, 2));
        fetch(`${global.url_back}/get_contact_view/`, {
          method: "PUT",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            values: values,
            razonSocial: query,
            countryClient: countryClient,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitting(false);
              setFetchSuccess(true);
              setOpenDialog(true);
            } else {
              setIsSubmitting(false);
              setFetchSuccess(false);
              setOpenDialog(true);
              console.log("error fetchingform-clientAdd");
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            setFetchSuccess(false);
            setOpenDialog(true);
            console.log(error);
          });
      }
    },
  });

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    setIsSearch(!isSearch);
    if (!isSearch) {
      setIsLoading(true);
      fetch(`${global.url_back}/get_clients/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json_response) => {
              setResponseClients(json_response);
              setIsLoading(false);
            });
          } else {
            console.log("error Clients");
            setFetchSuccess(false);
            setOpenDialog(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsLoading(false);
        });
    }
  }, []);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderColor: "#9e9e9e",
      padding: 0,
      height: "32px",
      minHeight: "32px",
      maxWidth: "300px",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      maxWidth: "300px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
      maxWidth: "300px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      maxWidth: "300px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      maxWidth: "300px",
    }),
  };

  return (
    <div className="container__contactadd">
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          if (fetchSuccess) {
            history.push(url_crm_contacts);
          }
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          {fetchSuccess ? (
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fetchSuccess
              ? "Contacto creado con Exito"
              : "Algo salio mal, intenta nuevamente"}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className="header_contactadd">
        <FontAwesomeIcon
          icon={faUserPlus}
          color="#383838"
          className="icon__contactadd"
        />
        <h4>Contacto Cliente</h4>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="conctadd_add_razon">
          <p className="tagSearch bold">Razon Social *</p>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleccione"
            defaultValue={razon}
            isLoading={isLoading}
            required
            isDisabled={edit ? false : true}
            isSearchable={true}
            name="color"
            options={
              searchBy === "Nit" ? responseClients?.ids : responseClients?.names
            }
            styles={colourStyles}
            onChange={(value) => {
              setQuery(value.value);
            }}
          />
        </div>

        <div className="other_info_addcontact">
          <div
            className="col-contact"
            style={{ marginRight: "10%", marginTop: "15px" }}
          >
            <div className="input_tag_addclient">
              <p className="labeltag">Nombres *</p>
              <input
                type="text"
                className="input__info full-width"
                onChange={formik.handleChange}
                name="contactName"
                value={formik.values.contactName}
                required
                disabled={edit ? false : true}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Apellidos *</p>
              <input
                type="text"
                className="input__info full-width"
                name="contactLastName"
                onChange={formik.handleChange}
                value={formik.values.contactLastName}
                disabled={edit ? false : true}
                required
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Cargo</p>
              <input
                type="text"
                className="input__info full-width"
                onChange={formik.handleChange}
                name="position"
                value={formik.values.position}
                disabled={edit ? false : true}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Email *</p>
              <input
                type="email"
                className="input__info full-width"
                style={{ width: "300px" }}
                onChange={formik.handleChange}
                name="email"
                value={formik.values.email}
                required
                disabled={edit ? false : true}
              />
            </div>
            <div className="flex_triple_input">
              <div className="flex_triple_input_div1">
                <p className="labeltag">Ind.</p>
                <input
                  type="number"
                  className="flex_triple_input_ind"
                  onChange={formik.handleChange}
                  name="nroInd"
                  value={formik.values.nroInd}
                  disabled={edit ? false : true}
                />
              </div>
              <div className="flex_triple_input_div2">
                <p className="labeltag">Telefono</p>
                <input
                  type="number"
                  className="flex_triple_input_tel"
                  onChange={formik.handleChange}
                  name="telephone"
                  value={formik.values.telephone}
                  required={formik.values.cellphone === "" ? true : false}
                  disabled={edit ? false : true}
                />
              </div>
              <div className="flex_triple_input_div3">
                <p className="labeltag">Ext.</p>
                <input
                  type="number"
                  className="flex_triple_input_ext"
                  onChange={formik.handleChange}
                  name="extension"
                  value={formik.values.extension}
                  disabled={edit ? false : true}
                />
              </div>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Celular *</p>
              <input
                type="number"
                className="input__info full-width"
                onChange={formik.handleChange}
                name="cellphone"
                value={formik.values.cellphone}
                required={formik.values.telephone === "" ? true : false}
                disabled={edit ? false : true}
              />
            </div>
          </div>
          <div className="col-contact">
            <div className="input_tag_addclient">
              <p className="labeltag">Genero *</p>
              <select
                className="select__addclient"
                onChange={formik.handleChange}
                name="gender"
                value={formik.values.gender}
                required
                disabled={edit ? false : true}
              >
                <option value="">-</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
              </select>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Pais *</p>
              <CountryDropdown
                value={countryClient}
                onChange={(val) => setCountryClient(val)}
                disabled={edit ? false : true}
                style={{ marginBottom: "15px" }}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Ciudad *</p>
              <input
                required
                type="text"
                className="input__info"
                list="ciudades"
                name="city"
                onChange={formik.handleChange}
                disabled={edit ? false : true}
                value={formik.values.city}
                required
              />
              {countryClient === "Colombia" ? <Cities id="ciudades" /> : null}
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Cumpleaños</p>
              <div className="flex-cumpleaños"></div>
              <select
                className="select__addclient"
                name="dia"
                onChange={formik.handleChange}
                value={formik.values.dia}
                disabled={edit ? false : true}
                style={{ width: "30%" }}
              >
                <Month_numbers />
              </select>
              <select
                className="select__addclient"
                name="mes"
                onChange={formik.handleChange}
                value={formik.values.mes}
                disabled={edit ? false : true}
                style={{ width: "60%" }}
              >
                <Months />
              </select>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Profesion</p>
              <input
                type="text"
                className="input__info full-width"
                onChange={formik.handleChange}
                disabled={edit ? false : true}
                name="profesion"
                value={formik.values.profesion}
              />
            </div>

            {edit ? (
              <button
                className="btn-userconfig"
                type="submit"
                style={{ margin: "20px 0 0 auto" }}
              >
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  "Guardar"
                )}
              </button>
            ) : null}
            {edit ? null : (
              <button
                type="button"
                className="btn-userconfig"
                style={{ margin: "20px 0 0 auto" }}
                onClick={() => {
                  setBlock(true);

                  if (shouldBlockNavigation) {
                    window.onbeforeunload = undefined;
                  } else {
                    window.onbeforeunload = () => true;
                  }

                  setEdit(true);
                }}
              >
                Editar
              </button>
            )}
          </div>
        </div>
      </form>
      <Prompt
        when={edit}
        message={(location, action) => {
          if (action === "PUSH") {
          }

          return location.pathname.startsWith("/app") ? true : doSomething();
        }}
      />
    </div>
  );
}

export default EditContact;
