import React, { useEffect, useState } from "react";
import "./UserConfig.css";
import Cookies from "universal-cookie";
import { useFormik } from "formik";
import { CountryDropdown } from "react-country-region-selector";
import { Cities } from "../../../App/services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
  faDoorOpen,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useHistory } from "react-router-dom";
import {
  init_crm_location,
  url_crm_dashboard,
} from "../../../CRM/constants/constants";
import { url_centro_log_dashboard } from "../../../Centro_Logistico/constants/constants";

function UserConfig({ module }) {
  const cookies = new Cookies();
  let token;
  if (module === "crm") {
    token = cookies.get("token_crm");
  } else if (module === "centro_log") {
    token = cookies.get("token_centro_log");
  } else {
    token = cookies.get("token_inventario");
  }
  const codigo_vendedor = cookies.get("codigo_vendedor");

  const history = useHistory();

  const [responseLogin, setResponseLogin] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [country, setCountry] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);

  const logout = () => {
    console.log("logging out");
    cookies.remove("token_crm", { path: "/" });
    cookies.remove("codigo_vendedor", { path: "/" });
    cookies.remove("super_user", { path: "/" });
    cookies.remove("token_centro_log", { path: "/" });
    cookies.remove("super_user_inventario", { path: "/" });
    cookies.remove("token_inventario", { path: "/" });

    if (module === "crm") {
      window.location.href = "/log/crm";
    } else if (module === "centro_log") {
      window.location.href = "/log/centro_log";
    } else {
      window.location.href = "/log/inventario";
    }
  };

  useEffect(() => {
    fetch(`${global.url_back}/userinfo/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        codigo_vendedor: codigo_vendedor,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseLogin(json_response);
            if (json_response.pais === "None") {
              setCountry("Colombia");
            } else {
              setCountry(json_response.pais);
            }
          });
        } else {
          console.log("error UserConfig");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nombres: responseLogin.nombres,
      apellidos: responseLogin.apellidos,
      fechaNacimiento: responseLogin.fecha_nacimiento,
      tipoId: responseLogin.tipo_id,
      nroId: responseLogin.nro_id,
      ciudad: responseLogin.ciudad,
      direccion: responseLogin.direccion,
      telefonoFijo: responseLogin.telefono,
      celular: responseLogin.celular,
      correo: responseLogin.email,
      cargo: responseLogin.cargo,
      usuario: responseLogin.user?.username,
      contraseña: "",
    },
    onSubmit: (values) => {
      console.log(values);
      setIsSubmitting(true);
      fetch(`${global.url_back}/userinfo/`, {
        method: "PUT",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          values: values,
          pais: country,
          codigo_vendedor: codigo_vendedor,
          module: module,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setIsSubmitting(false);
            setFetchSuccess(true);
            setOpenDialog(true);
          } else {
            setIsSubmitting(false);
            setFetchSuccess(false);
            setOpenDialog(true);
            console.log("error fetchingform-userconfig");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setFetchSuccess(false);
          setOpenDialog(true);
          console.log(error);
        });
    },
  });

  return (
    <div className="container__userconfig">
      <div className="avatar">
        <img
          src={require("../../../App/images/user.svg")}
          alt=""
          className="avatar__img"
        />
        <h4>
          {formik.values.nombres} {formik.values.apellidos} - Codigo:{" "}
          {codigo_vendedor}
        </h4>
        <div className="logout-div" onClick={() => logout()}>
          <FontAwesomeIcon icon={faSignOutAlt} size="2x" color="#383838" />
        </div>
      </div>
      <div className="relative-button"></div>
      <form className="wrapper-info" onSubmit={formik.handleSubmit}>
        <div className="info margin__info">
          <p className="ubicacion-tag">Informacion Personal</p>
          <p className="labeltag">Nombres</p>
          <input
            required
            type="text"
            className="input__info"
            name="nombres"
            onChange={formik.handleChange}
            value={formik.values.nombres}
          />
          <p className="labeltag">Apellidos</p>
          <input
            required
            type="text"
            className="input__info"
            name="apellidos"
            onChange={formik.handleChange}
            value={formik.values.apellidos}
          />
          <p className="labeltag">Fecha de Nacimiento</p>
          <input
            required
            type="date"
            className="input__info"
            name="fechaNacimiento"
            onChange={formik.handleChange}
            value={formik.values.fechaNacimiento}
          />
          <p className="labeltag">Tipo de Identificacion</p>
          <select
            className="input__info"
            name="tipoId"
            onChange={formik.handleChange}
            value={formik.values.tipoId}
          >
            <option value="cc">C.C</option>
            <option value="pasaporte">Pasaporte</option>
            <option value="otro">Otro</option>
          </select>

          <p className="labeltag">Nro. Identificacion</p>
          <input
            required
            type="text"
            className="input__info"
            name="nroId"
            onChange={formik.handleChange}
            value={formik.values.nroId}
          />
          <p className="ubicacion-tag">Ubicacion</p>
          <p className="labeltag">Pais</p>
          <CountryDropdown
            value={country}
            onChange={(val) => setCountry(val)}
            style={{ marginBottom: "15px" }}
          />
          <p className="labeltag">Ciudad</p>
          <input
            required
            type="text"
            className="input__info"
            list="ciudades"
            name="ciudad"
            onChange={formik.handleChange}
            value={formik.values.ciudad}
          />

          {country === "Colombia" ? <Cities id="ciudades" /> : null}

          <p className="labeltag">Direccion</p>
          <input
            required
            type="text"
            className="input__info"
            name="direccion"
            onChange={formik.handleChange}
            value={formik.values.direccion}
          />
        </div>
        <div className="info">
          <p className="labeltag">Telefono Fijo</p>
          <input
            required
            type="number"
            className="input__info"
            name="telefonoFijo"
            onChange={formik.handleChange}
            value={formik.values.telefonoFijo}
          />
          <p className="labeltag">Celular</p>
          <input
            required
            type="number"
            className="input__info"
            name="celular"
            onChange={formik.handleChange}
            value={formik.values.celular}
          />
          <p className="labeltag">Correo Empresarial</p>
          <input
            required
            type="email"
            className="input__info"
            name="correo"
            onChange={formik.handleChange}
            value={formik.values.correo}
          />
          <p className="labeltag">Cargo</p>
          <input
            required
            type="text"
            className="input__info"
            name="cargo"
            onChange={formik.handleChange}
            value={formik.values.cargo}
          />

          <div className="square__account">
            <div className="square__info">
              <p className="label_tag_account em">Informacion de la Cuenta</p>
              <p className="label_tag_account">Usuario</p>
              <input
                type="text"
                className="input__info2"
                name="usuario"
                onChange={formik.handleChange}
                value={formik.values.usuario}
              />
              <p className="label_tag_account">Contraseña</p>
              <input
                type="password"
                className="input__info"
                name="contraseña"
                onChange={formik.handleChange}
                value={formik.values.contraseña}
              />
            </div>
          </div>
          <button className="btn-userconfig" type="submit">
            {isSubmitting ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-spin"
                style={{ fontSize: "25px" }}
              />
            ) : (
              "Guardar"
            )}
          </button>
        </div>
      </form>
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          if (fetchSuccess) {
            if (module == "crm") {
              history.push(url_crm_dashboard);
            } else {
              history.push(url_centro_log_dashboard);
            }
          }
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          {fetchSuccess ? (
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fetchSuccess
              ? "El registro se ha actualizado con éxito"
              : "Algo salio mal, intenta nuevamente"}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UserConfig;
