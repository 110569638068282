import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  faUser,
  faClipboard,
  faCalendarTimes,
} from "@fortawesome/free-regular-svg-icons";
import "./Dashboard.css";
import UserConfig from "../../../App/components/UserConfig/UserConfig";
import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
import CurrencyFormat from "react-currency-format";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Bar,
  BarChart,
} from "recharts";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";

function Dashboard() {
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();

  const cookies = new Cookies();

  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [responseVendors, setResponseVendors] = useState();

  const token = cookies.get("token_crm");
  const [openDialog, setOpenDialog] = useState(false);
  const codigo_vendedor = cookies.get("codigo_vendedor");
  global.widt_grpah = 0;

  const ref = useRef(null);

  const [width_graph, setWidth] = useState(0);
  const [height_graph, setHeight] = useState(0);

  useEffect(() => {
    dispatch(
      {
        type: "SET_URL",
        actual_url: path,
      },
      []
    );

    if (ref.current !== null) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.clientHeight);
    }

    fetch(`${global.url_back}/get_homeinfo/${codigo_vendedor}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseVendors(json_response);
          });
        } else {
          console.log("error UserConfig");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        <div className="container__dashboard">
          <div className="header">
            <h3 className="name">Hola {responseVendors?.name_user}!</h3>
            <Link to={`${url}/userconfig`}>
              <FontAwesomeIcon
                className="icon2"
                icon={faUser}
                color="#383838"
                size="2x"
              />
            </Link>
          </div>
          <div className="stadistics_sgto">
            <div className="stadistics_sgto_square">
              <div className="circle-img">
                <FontAwesomeIcon
                  className="icon"
                  icon={faClipboard}
                  color="#266e45"
                />
              </div>
              <div className="stadistics_sgto_text">
                <h4>Seguimientos Para Hoy</h4>
                <h4 className="grey bold" style={{ marginTop: "10px" }}>
                  <span style={{ color: "black" }}>
                    {responseVendors?.num_pendientes}
                  </span>
                </h4>
              </div>
            </div>

            <div className="stadistics_sgto_square">
              <div className="circle-img">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCalendarTimes}
                  color="#266e45"
                />
              </div>
              <div className="stadistics_sgto_text">
                <h4>Seguimientos Vencidos</h4>
                <h4 className="grey bold" style={{ marginTop: "10px" }}>
                  <span style={{ color: "black" }}>
                    {responseVendors?.num_vencidas}
                  </span>
                </h4>
              </div>
            </div>
          </div>

          <div className="stadistics_total">
            <div className="stadistics_total_text">
              <h4>
                Total Cotizaciones Activas:
                <span style={{ color: "black", fontFamily: "Roboto" }}>
                  {" "}
                  {responseVendors?.num_activas}
                </span>
              </h4>
              <h4 style={{ display: "flex", flexDirection: "row" }}>
                Monto Total:{" "}
                <span style={{ color: "black", fontFamily: "Roboto" }}>
                  <CurrencyFormat
                    renderText={(value) => (
                      <>
                        <p>
                          <strong>{value}</strong>
                        </p>
                      </>
                    )}
                    decimalScale={2}
                    value={responseVendors?.total}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
              </h4>
            </div>
          </div>

          <div className="header">
            <h3 className="name">Historial Cotizaciones</h3>
          </div>
          <div ref={ref} className="stadistics_graph">
            <BarChart
              width={width_graph}
              height={height_graph}
              data={responseVendors?.data}
              margin={{ top: 10, right: 20, left: 5, bottom: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis dataKey="cantidad" />
              <Tooltip />

              <Bar dataKey="cantidad" fill="#78b994" />
            </BarChart>
          </div>
        </div>
      </Route>

      <Route path={`${path}/userconfig`}>
        <UserConfig module="crm" />
      </Route>
    </Switch>
  );
}

export default Dashboard;
