import React, { useState, useEffect } from "react";
import Select from "react-select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  ModalForm,
  ModalQuestion,
} from "../../../App/components/shared/Modals";
import _ from "lodash";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useCookies } from "react-cookie";

// import "./Modals.css";

function ModalSalida({ open, onClose, initValuesModalSalida }) {
  const init_form_values = {
    fecha_salida: "",
    hora_salida: "",
    prestatario: "",
    salida_mantenimiento: "",
    fecha: "",
    tipo_mantenimiento: "",
    realizado_por: "",
    razon_social: "",
    entrega_estimada: "",
    observaciones: "",
  };

  const [formValues, setFormValues] = useState({
    ...initValuesModalSalida,
    ...init_form_values,
  });
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [cookies] = useCookies();
  const [showRazonSocial, setShowRazonSocial] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [initIsLoading, setInitIsLoading] = useState(false);
  const [json_response, setJson_response] = useState();
  const [valueSelect, setValueSelect] = useState();

  const token = cookies.token_inventario;

  const styles_react_select = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderWidth: 1,
      height: "27px",
      minHeight: "27px",
      // maxWidth: "150px",
      borderRadius: "5px",
      // backgroundColor: "blue",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "13px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "27px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      marginTop: "-2.5px",

      fontSize: "13px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "27px",
    }),

    placeholder: (styles) => ({
      ...styles,
      fontSize: "13px",
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: "13px",
    }),
  };

  const change_input = (event) => {
    var { name, value } = event.target;
    if (name === "salida_mantenimiento") {
      value = value === "true";
    }
    if (name === "realizado_por") {
      if (value === "proveedor") {
        setShowRazonSocial(true);
      } else {
        setShowRazonSocial(false);
      }
    }
    let values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  };

  const validate = () => {
    const errors = {};

    if (!formValues.fecha_salida) {
      errors.fecha_salida = 'Falta Selecionar "Fecha Salida"';
    }

    if (!formValues.hora_salida) {
      errors.hora_salida = 'Falta Selecionar "Hora"';
    }

    if (!formValues.prestatario) {
      errors.prestatario = 'Falta Selecionar "Prestatario"';
    }

    if (!formValues.tipo_mantenimiento && formValues.salida_mantenimiento) {
      errors.tipo_mantenimiento = 'Falta Selecionar "Tipo Mantenimiento"';
    }

    if (!formValues.realizado_por && formValues.salida_mantenimiento) {
      errors.realizado_por = 'Falta Selecionar "Realizado Por"';
    }

    if (formValues.realizado_por === "proveedor" && !formValues.razon_social) {
      errors.razon_social = 'Falta Selecionar "Razón Social"';
    }

    if (!formValues.entrega_estimada && formValues.salida_mantenimiento) {
      errors.entrega_estimada = 'Falta Selecionar "Entrega Estimada"';
    }

    return errors;
  };

  const send_request = async () => {
    console.log(formValues);
    const errors = validate();
    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
    setIsLoading(true);
    const response = await fetch_backend(
      "tools/outflows/",
      "POST",
      token,
      dispatch,
      formValues
    );

    setIsLoading(false);
    if (response.status === 200) {
      onClose(200);
    }
  };

  useEffect(() => {
    var values = { ...formValues };
    const today = new Date();
    console.log(today);
    var date = today.toISOString().slice(0, 10);
    console.log(date);
    var time = today
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .slice(0, 5);
    console.log(time);
    values.fecha_salida = date;
    values.hora_salida = time.toString();
    setFormValues(values);

    async function send_request() {
      setInitIsLoading(true);
      const response = await fetch_backend(
        "tools/outflows/",
        "GET",
        token,
        dispatch
      );
      if (response.status === 200) {
        response.prestatarios.push({
          value: "mantenimiento",
          label: "Mantenimiento",
        });
      }
      setJson_response(response);
      setInitIsLoading(false);
    }
    send_request();
  }, []);

  const change_react_select = (value, name) => {
    let values = { ...formValues };
    values[name] = value.value;
    if (name === "prestatario") {
      setValueSelect({ value: value.value, label: value.label });
      if (value.value === "mantenimiento") {
        values.salida_mantenimiento = true;
      } else {
        values.salida_mantenimiento = false;
      }
    }
    setFormValues(values);
  };

  return (
    <>
      <ModalForm
        open={openModalForm}
        onClose={() => setOpenModalForm(false)}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <Dialog
        onClose={() => onClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h3>Salida</h3>
        </DialogTitle>
        <DialogContent>
          <p
            style={{ marginBottom: "20px" }}
            className="label_input_modal p_modal_herramientas"
          >
            {initValuesModalSalida.descripcion}
          </p>
          <div className="row_modal">
            <div>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                Fecha Salida
              </p>
              <input
                type="date"
                name="fecha_salida"
                onChange={(e) => change_input(e)}
                value={formValues?.fecha_salida}
              />
            </div>
            <div>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                Hora
              </p>
              <input
                type="time"
                name="hora_salida"
                onChange={(e) => change_input(e)}
                value={formValues?.hora_salida}
              />
            </div>
          </div>
          <div className="row_modal">
            <div>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                Prestatario
              </p>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                isSearchable={true}
                name="prestatario"
                options={json_response?.prestatarios}
                styles={styles_react_select}
                onChange={(value) => change_react_select(value, "prestatario")}
                isLoading={initIsLoading}
                value={valueSelect}
              />
            </div>

            <div />
          </div>
          <div className="row_modal" style={{ marginBottom: "5px" }}>
            <div style={{ flex: 1 }}>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "normal" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Observaciones Herramienta:{" "}
                </span>
                {initValuesModalSalida.observaciones_herramienta}
              </p>
            </div>
          </div>

          {formValues.prestatario === "mantenimiento" && (
            <>
              <div className="row_modal">
                <p className="label_input_modal">Fecha: 2020-02-02</p>
              </div>

              <div className="row_modal">
                <div>
                  <p className="label_input_modal">Tipo Mantenimiento</p>
                  <div className="simple_flex" style={{ marginTop: "10px" }}>
                    <input
                      type="radio"
                      name="tipo_mantenimiento"
                      value="programado"
                      onClick={(e) => change_input(e)}
                    />
                    <p
                      className="label_input_modal"
                      style={{ fontWeight: "normal", marginLeft: "7px" }}
                    >
                      Programado
                    </p>
                  </div>
                  <div className="simple_flex" style={{ marginTop: "10px" }}>
                    <input
                      type="radio"
                      name="tipo_mantenimiento"
                      value="correctivo"
                      onClick={(e) => change_input(e)}
                    />
                    <p
                      className="label_input_modal"
                      style={{ fontWeight: "normal", marginLeft: "7px" }}
                    >
                      Correctivo
                    </p>
                  </div>
                </div>
              </div>

              <div className="row_modal">
                <div>
                  <p className="label_input_modal">Realizado Por</p>
                  <div className="simple_flex" style={{ marginTop: "10px" }}>
                    <input
                      type="radio"
                      name="realizado_por"
                      value="propio"
                      onClick={(e) => change_input(e)}
                    />
                    <p
                      className="label_input_modal"
                      style={{ fontWeight: "normal", marginLeft: "7px" }}
                    >
                      Propio
                    </p>
                  </div>
                  <div className="simple_flex" style={{ marginTop: "10px" }}>
                    <input
                      type="radio"
                      name="realizado_por"
                      value="proveedor"
                      onClick={(e) => change_input(e)}
                    />
                    <p
                      className="label_input_modal"
                      style={{ fontWeight: "normal", marginLeft: "7px" }}
                    >
                      Proveedor
                    </p>
                  </div>
                </div>
              </div>
              {showRazonSocial && (
                <div className="row_modal">
                  <div style={{ flex: 1 }}>
                    <p
                      className="label_input_modal p_modal_herramientas"
                      style={{ fontWeight: "bold" }}
                    >
                      Razón Social
                    </p>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Seleccione..."
                      isSearchable={true}
                      name="razon_social"
                      options={json_response?.proveedores}
                      styles={styles_react_select}
                      isLoading={initIsLoading}
                      onChange={(value) => {
                        change_react_select(value, "razon_social");
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row_modal">
                <div>
                  <p
                    className="label_input_modal p_modal_herramientas"
                    style={{ fontWeight: "bold" }}
                  >
                    Entrega Estimada
                  </p>
                  <input
                    type="date"
                    name="entrega_estimada"
                    onChange={(e) => change_input(e)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="row_modal">
            <div style={{ flex: 1 }}>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                Observaciones
              </p>
              <textarea
                className="obs_salida_textarea"
                name="observaciones"
                onChange={(e) => change_input(e)}
              />
            </div>
          </div>
          <button
            style={{
              padding: "3px 15px",
              margin: "0 0 0 auto",
            }}
            onClick={() => send_request()}
          >
            Guardar
            {isLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-spin"
                style={{ marginLeft: "8px" }}
              />
            )}
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}

export default ModalSalida;
