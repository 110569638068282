import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useRouteMatch, Link } from "react-router-dom";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import "./Dashboard.css";
import "./Calendar.css";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { PieChart } from "react-minimal-pie-chart";
import Loading from "../../../App/components/Loading/Loading";
import { useCookies } from "react-cookie";
import { ModalFetch } from "../../../App/components/shared/Modals";
import Cookies from "universal-cookie";

function Dashboard() {
  const cookies_code = new Cookies();
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [json_response, setJson_response] = useState();

  //fetch states
  const [isLoading, setIsLoading] = useState();
  const [openModalFetch, setOpenModalFetch] = useState();
  const [fetchSuccess, setFetchSuccess] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_centro_log;

  const codigo_vendedor = cookies_code.get("codigo_vendedor");

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
    setIsLoading(true);
    // --PENDING --
    fetch(`${global.url_back}/home_centro_log/${codigo_vendedor}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setIsLoading(false);
            setJson_response(json_response);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  }, []);

  const onCloseModalFetch = () => {
    setOpenModalFetch(false);
  };

  const click_day_calendar = (full_date) => {
    console.log(full_date);

    // --PENDING --
    setIsLoading(true);
    fetch(`${global.url_back}/home_centro_log_date/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date: full_date }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((res_pendigs_day) => {
            setIsLoading(false);

            setJson_response({
              ...json_response,
              pendings_day: res_pendigs_day,
            });
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  return (
    <div className="main_container_dashboard">
      <ModalFetch
        open={openModalFetch}
        onClose={onCloseModalFetch}
        fetchSuccess={fetchSuccess}
      />
      <Loading isLoading={isLoading} />
      <div className="col1_dashboard">
        <div className="row0">
          <h3>Hola {json_response?.name_user}!</h3>
        </div>
        <div className="row1">
          <h4>Pendientes</h4>
        </div>
        <Calendar onClickDay={(full_date) => click_day_calendar(full_date)} />
        <div className="pending_table_scroll">
          <table className="pendings_table">
            <tr>
              <th className="table_type">Tipo</th>
              <th className="table_client">Cliente</th>
              <th className="table_time">Hora</th>
            </tr>
            {json_response?.pendings_day.map((pending) => (
              <tr>
                <td>{pending.item_type}</td>
                <td className="cell_nombre_cliente">
                  {pending.nombre_cliente}
                </td>
                <td>{pending.time}</td>
              </tr>
            ))}
          </table>
        </div>
        <h1></h1>
      </div>
      <div className="col2_dashboard">
        <div className="row0">
          <Link to={`${url}/userconfig`}>
            <div style={{ margin: "0 0 0 auto", width: "30px" }}>
              <FontAwesomeIcon
                className="icon2"
                icon={faUser}
                color="#383838"
                size="2x"
              />
            </div>
          </Link>
        </div>
        <div className="row1">
          <div className="pending_logistic">
            <div className="bar_pending_logistic" />
            <div className="flex_space_evenly">
              <h4 className="col_white">Req. Logísticos Pendientes</h4>
              <div className="circle_pending_logistic">
                <h4 className="col_white">
                  {json_response?.pending_logistics_req}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row2">
          <div className="rented_containers">
            <h4>Contenedores Alquilados</h4>
            <div className="flex_pie_chart">
              <div className="pie_container">
                <PieChart
                  radius={PieChart.defaultProps.radius - 15}
                  data={json_response?.rented_containers}
                  lineWidth={45}
                  animate
                  label={({ dataEntry }) =>
                    Math.round(dataEntry.percentage) + "%"
                  }
                  labelPosition={70}
                  labelStyle={{
                    fill: "#fff",
                    opacity: 1,
                    pointerEvents: "none",
                    fontSize: "5px",
                  }}
                />
              </div>
              <div className="pie_labels_container">
                {json_response?.rented_containers.map((type) => (
                  <div style={{ display: "flex" }}>
                    <div
                      className="bullet_label_pie"
                      style={{ backgroundColor: type.color }}
                    />
                    <h4
                      style={{
                        fontSize: "20px",
                        width: "85px",
                        marginRight: "8px",
                      }}
                    >
                      {type.title}
                    </h4>
                    <h4 style={{ fontSize: "20px", color: "black" }}>
                      {type.value}
                    </h4>
                  </div>
                ))}
                <div style={{ display: "flex", marginTop: "28px" }}>
                  <div
                    className="bullet_label_pie"
                    style={{ backgroundColor: "none" }}
                  />
                  <h4
                    style={{
                      fontSize: "24px",
                      width: "85px",
                      marginLeft: "-5px",
                    }}
                  >
                    Total
                  </h4>
                  <h4 style={{ fontSize: "24px", color: "black" }}>
                    {json_response?.rented_containers
                      .map((container) => container.value)
                      .reduce((acc, container) => container + acc)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row3">
          <div className="pending_maintenance">
            <h4>Mantenimientos Pendientes</h4>
            <div className="pending_maintenance_table_scroll">
              <table className="pendings_maintenance_table">
                <tr>
                  <th className="table_client_maintenance">Cliente</th>
                  <th className="table_date_maintenance">Fecha</th>
                </tr>
                {json_response?.pendings_maintenances.map((pending) => (
                  <tr>
                    <td className="cell_nombre_cliente">
                      {pending.nombre_cliente}
                    </td>
                    <td>{pending.date}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Dashboard };
