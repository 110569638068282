import React, { useState } from "react";
import "./SideBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faHome,
  faToolbox,
  faSortAmountDownAlt,
  faLayerGroup,
  faCartArrowDown,
  faWarehouse,
  faIndustry,
} from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-regular-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faSearch, faware } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useDataLayerValue } from "../reducer/DataLayer";
import {
  init_crm_location,
  url_crm_dashboard,
  url_crm_clients,
  url_crm_contacts,
  url_crm_tasks,
  url_crm_quote,
  url_crm_search,
} from "../../CRM/constants/constants";
import {
  init_centro_log_location,
  url_centro_log_dashboard,
  url_centro_log_entrances,
  url_centro_log_logistics,
  url_centro_log_movements,
  url_centro_log_search,
  url_centro_log_stock,
} from "../../Centro_Logistico/constants/constants";
import {
  init_inventario_location,
  url_inventario_dashboard,
  url_inventario_inputs,
  url_inventario_outputs,
  url_inventario_stock,
  url_inventario_tools,
} from "../../Inventario/constants/constants";
import {
  init_orders_location,
  url_orders_home,
} from "../../Pedidos/constants/constants";
import {
  init_purchase_location,
  url_purchase_dashboard,
  url_purchase_orders,
  url_purchase_orders_grouping,
  url_purchase_suppliers_orders,
  url_purchase_stock,
  url_purchase_suppliers,
} from "../../Compras/constants/constants";

function Sidebar(props) {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { pathname } = useLocation();

  const dashboards_urls = [
    url_crm_dashboard,
    url_centro_log_dashboard,
    url_inventario_dashboard,
    url_purchase_dashboard,
    url_orders_home,
  ];

  const wrapped_screen_urls = [
    url_crm_tasks,
    url_centro_log_entrances,
    url_centro_log_logistics,
    url_centro_log_stock,
    url_inventario_tools,
    url_orders_home,
  ];

  const check_module = () => {
    if (pathname.includes(init_crm_location)) {
      return url_crm_dashboard;
    } else if (pathname.includes(init_centro_log_location)) {
      return url_centro_log_dashboard;
    } else if (pathname.includes(init_inventario_location)) {
      return url_inventario_dashboard;
    } else if (pathname.includes(init_purchase_location)) {
      return url_purchase_dashboard;
    } else if (pathname.includes(init_orders_location)) {
      return init_orders_location;
    }
  };

  return (
    <div
      className={
        wrapped_screen_urls.includes(actual_url) ? "wrap_in_tasks" : "wrap"
      }
    >
      {/* Dashboard (House) icon */}
      <div className="rectangle">
        <div className="rectangle__flex-home">
          <Link to={check_module()}>
            <div
              className={
                dashboards_urls.includes(actual_url) ? "active" : "no-active"
              }
            >
              <FontAwesomeIcon className="icon" icon={faHome} color="white" />
            </div>
          </Link>
        </div>

        {/* Main Icons */}
        <div className="rectangle__flex-others__center">
          <div className="rectangle__flex-others__vertical">
            {pathname.includes(init_crm_location) ? (
              <>
                <Link to={url_crm_clients}>
                  <div
                    className={
                      actual_url === url_crm_clients ? "active" : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faBuilding}
                      color="white"
                    />
                  </div>
                </Link>

                <Link to={url_crm_contacts}>
                  <div
                    className={
                      actual_url === url_crm_contacts ? "active" : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faAddressBook}
                      color="white"
                    />
                  </div>
                </Link>

                <Link to={url_crm_tasks}>
                  <div
                    className={
                      actual_url === url_crm_tasks ? "active" : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faClipboard}
                      color="white"
                      data-fa-mask={faAddressBook}
                    />
                  </div>
                </Link>

                <Link to={url_crm_quote}>
                  <div
                    className={
                      actual_url === url_crm_quote ? "active" : "no-active"
                    }
                  >
                    <img src={require("../images/ClipBoardPlus.svg")} alt="" />
                  </div>
                </Link>

                <Link to={url_crm_search}>
                  <div
                    className={
                      actual_url === url_crm_search ? "active" : "no-active"
                    }
                  >
                    <img
                      src={require("../images/search.svg")}
                      alt=""
                      width={"33px"}
                    />
                  </div>
                </Link>
              </>
            ) : pathname.includes(init_centro_log_location) ? (
              <>
                <Link to={url_centro_log_entrances}>
                  <div
                    className={
                      actual_url === url_centro_log_entrances
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faClipboard}
                      color="white"
                      data-fa-mask={faAddressBook}
                    />
                  </div>
                </Link>

                <Link to={url_centro_log_logistics}>
                  <div
                    className={
                      actual_url === url_centro_log_logistics
                        ? "active"
                        : "no-active"
                    }
                  >
                    <img
                      src={require("../images/truck-solid.svg")}
                      alt=""
                      width={"45px"}
                    />
                  </div>
                </Link>

                <Link to={url_centro_log_movements}>
                  <div
                    className={
                      actual_url === url_centro_log_movements
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faExchangeAlt}
                      color="white"
                    />
                  </div>
                </Link>

                <Link to={url_centro_log_stock}>
                  <div
                    className={
                      actual_url === url_centro_log_stock
                        ? "active"
                        : "no-active"
                    }
                  >
                    <img
                      src={require("../images/container.svg")}
                      width={"45px"}
                      alt=""
                    />
                  </div>
                </Link>

                <Link to={url_centro_log_search}>
                  <div
                    className={
                      actual_url === url_centro_log_search
                        ? "active"
                        : "no-active"
                    }
                  >
                    <img
                      src={require("../images/search.svg")}
                      alt=""
                      width={"33px"}
                    />
                  </div>
                </Link>
              </>
            ) : pathname.includes(init_inventario_location) ? (
              <>
                {/* <Link to={url_inventario_inputs}>
                  <div
                    className={
                      actual_url === url_inventario_inputs
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faExchangeAlt}
                      color="white"
                    />
                  </div>
                </Link>

                <Link to={url_inventario_outputs}>
                  <div
                    className={
                      actual_url === url_inventario_outputs
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faExchangeAlt}
                      color="white"
                    />
                  </div>
                </Link>

                <Link to={url_inventario_stock}>
                  <div
                    className={
                      actual_url === url_inventario_stock
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faExchangeAlt}
                      color="white"
                    />
                  </div>
                </Link> */}

                <Link to={url_inventario_tools}>
                  <div
                    className={
                      actual_url === url_inventario_tools
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faToolbox}
                      color="white"
                    />
                  </div>
                </Link>
              </>
            ) : pathname.includes(init_purchase_location) ? (
              <>
                <Link to={url_purchase_orders}>
                  <div
                    className={
                      actual_url === url_purchase_orders
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faSortAmountDownAlt}
                      color="white"
                    />
                  </div>
                </Link>
                <Link to={url_purchase_orders_grouping}>
                  <div
                    className={
                      actual_url === url_purchase_orders_grouping
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faLayerGroup}
                      color="white"
                    />
                  </div>
                </Link>

                <Link to={url_purchase_suppliers_orders}>
                  <div
                    className={
                      actual_url === url_purchase_suppliers_orders
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faCartArrowDown}
                      color="white"
                      style={{ width: "40px" }}
                    />
                  </div>
                </Link>
                <Link to={url_purchase_stock}>
                  <div
                    className={
                      actual_url === url_purchase_stock ? "active" : "no-active"
                    }
                  >
                    <img
                      src={require("../images/Compras/warehouse-custom.svg")}
                      alt=""
                      width={"39px"}
                    />
                  </div>
                </Link>

                <Link to={url_purchase_suppliers}>
                  <div
                    className={
                      actual_url === url_purchase_suppliers
                        ? "active"
                        : "no-active"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon"
                      icon={faIndustry}
                      color="white"
                      style={{ width: "35px" }}
                    />
                  </div>
                </Link>
              </>
            ) : pathname.includes(init_purchase_location) ? (
              <></>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* Logo */}
        <div className="rectangle__flex-logo">
          <img
            className="sidebar_logo_cn"
            src={require("../images/cn_logo_blanco.svg")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
