import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

function ModalQuestion({ open, question, callback, onClose, params = "" }) {
  const click_button = (e) => {
    const { value } = e.target;
    callback({ value: value, params: params });
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent style={{ textAlign: "center" }}>{question}</DialogContent>
      <br />
      <DialogActions>
        <button
          type="button"
          value={true}
          onClick={(e) => click_button(e)}
          style={{ padding: "5px 15px", fontSize: "18px" }}
        >
          Aceptar
        </button>
        <button
          type="button"
          value={false}
          style={{ padding: "5px 15px", fontSize: "18px" }}
          onClick={(e) => click_button(e)}
        >
          Cancelar
        </button>
      </DialogActions>
    </Dialog>
  );
}

function ModalForm({ open, onClose, successModal, messageModal }) {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>
        {successModal ? (
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        )}
      </DialogTitle>
      <DialogContent>
        {typeof messageModal === "object" ? (
          Object.values(messageModal).map((value) =>
            Array.isArray(value) ? (
              value.map((str) => (
                <>
                  <p>{str}</p>
                  <br />
                </>
              ))
            ) : (
              <>
                <p>{value}</p>
                <br />
              </>
            )
          )
        ) : (
          <>
            <p>{messageModal}</p>
            <br />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

function ModalFetch({ open, onClose, fetchSuccess }) {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>
        {fetchSuccess ? (
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        )}
      </DialogTitle>
      <DialogContent>
        {fetchSuccess ? (
          <p>Operación exitosa</p>
        ) : (
          <p>Algo salió mal, intenta nuevamente</p>
        )}
      </DialogContent>
    </Dialog>
  );
}

export { ModalQuestion, ModalForm, ModalFetch };
