import React, { useState, useEffect } from "react";
import "./Modal_return.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

function ModalReturn({ open, callback, onClose, params = "" }) {
  const [check_paz_y_salvo, setCheck_paz_y_salvo] = useState(false);
  const [check_cot_enviada, setCheck_cot_enviada] = useState(false);
  const [fail_validation, setFail_validation] = useState(false);

  const click_button = (e) => {
    const { value, name } = e.target;
    if (name === "aceptar" && (!check_cot_enviada || !check_paz_y_salvo)) {
      setFail_validation(true);
    } else {
      callback({ value: value, params: params });
    }
  };

  useEffect(() => {
    if (check_paz_y_salvo && check_cot_enviada) {
      setFail_validation(false);
    }
  }, [check_cot_enviada, check_paz_y_salvo]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      aria-labelledby="simple-dialog-title"
    >
      <div className="modal_return_container">
        <p>¿Está seguro que desea programar una devolución?</p>
        <div className="flex_checkbox_return_modal">
          <input
            type="checkbox"
            name="check_cot_enviada"
            checked={check_cot_enviada}
            onChange={(e) => {
              setCheck_cot_enviada(!check_cot_enviada);
            }}
          />
          <p style={{ fontSize: "13px", marginLeft: "4px" }}>
            El cliente se encuentra a paz y salvo
          </p>
        </div>
        <div className="flex_checkbox_return_modal">
          <input
            type="checkbox"
            name="check_paz_y_salvo"
            checked={check_paz_y_salvo}
            onChange={(e) => {
              setCheck_paz_y_salvo(!check_paz_y_salvo);
            }}
          />
          <p style={{ fontSize: "13px", marginLeft: "4px" }}>
            Comercial ya envió la cotización de transporte de devolución
          </p>
        </div>
        {fail_validation && (
          <p style={{ color: "red", fontSize: "13px", marginTop: "10px" }}>
            *Ambas condiciones son requeridas para continuar con la devolución
          </p>
        )}
      </div>
      <br />
      <DialogActions>
        <button
          type="button"
          value={true}
          name="aceptar"
          onClick={(e) => click_button(e)}
          style={{ padding: "5px 15px", fontSize: "18px" }}
        >
          Aceptar
        </button>
        <button
          type="button"
          name="cancelar"
          value={false}
          style={{ padding: "5px 15px", fontSize: "18px" }}
          onClick={(e) => click_button(e)}
        >
          Cancelar
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalReturn;
