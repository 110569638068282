import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  Link as LinkPDF,
} from "@react-pdf/renderer";
import Calibri from "../../../App/fonts/Calibri.ttf";
import Calibri_bold from "../../../App/fonts/calibrib.ttf";
import firma_mf from "../../../App/images/firma_mf.png";
import footer_img from "../../../App/images/footer_img.png";
import { NumeroALetras } from "../../../App/services/services";
import header_contrato from "../../../App/images/header_contrato_v2.jpeg";
import footer_contrato from "../../../App/images/footer_contrato_v2.jpeg";

function Bill_Account({ values_form, data_detail }) {
  Font.register({ family: "Calibri", src: Calibri });
  Font.register({ family: "Calibri_bold", src: Calibri_bold });

  const get_month = (date) => {
    const month = date.getMonth() + 1;
    if (month === 1) {
      return "enero";
    } else if (month === 2) {
      return "febrero";
    } else if (month === 3) {
      return "marzo";
    } else if (month === 4) {
      return "abril";
    } else if (month === 5) {
      return "mayo";
    } else if (month === 6) {
      return "junio";
    } else if (month === 7) {
      return "julio";
    } else if (month === 8) {
      return "agosto";
    } else if (month === 9) {
      return "septiembre";
    } else if (month === 10) {
      return "octubre";
    } else if (month === 11) {
      return "noviembre";
    } else if (month === 12) {
      return "diciembre";
    }
  };

  const fecha_firma_format = new Date(values_form.fecha_firma + "T00:00:00");
  const signature_day = fecha_firma_format.getDate();
  const signature_month = get_month(fecha_firma_format);
  const siganture_year = fecha_firma_format.getFullYear();

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const styles = StyleSheet.create({
    bold: {
      fontFamily: "Calibri_bold",
      fontSize: 15,
    },

    page: {
      paddingBottom: 96,
    },

    header: {
      width: "100%",
      marginTop: -75.5,
    },

    pages_content: {
      margin: "0 96px",
    },

    right: {
      textAlign: "right",
    },

    center: {
      textAlign: "center",
    },

    paragraph: {
      marginTop: "20px",
    },

    text: {
      fontFamily: "Calibri",
      fontSize: 15,
      lineHeight: 1.4,
      textAlign: "justify",
    },

    footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    column_footer: {
      flex: 0.4,
    },

    fixed_header: {
      marginBottom: "75.5px",
    },
    fixed_footer_img: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
    },
  });

  return (
    <Document>
      <Page size={[816, 1056]} style={styles.page}>
        <View style={styles.fixed_header} fixed></View>
        <View style={styles.header}>
          <Image
            src={header_contrato}
            style={{ width: "816px", height: "192px" }}
          />
        </View>

        <View style={styles.pages_content}>
          <Text style={[styles.right, { fontFamily: "Calibri", fontSize: 15 }]}>
            Girardota, {signature_day} de {signature_month} de {siganture_year}
          </Text>
          <View style={{ marginTop: "50px" }}>
            <Text style={[styles.bold, styles.center]}>COBRO DEPOSITO DE GARANTÍAS ALQUILER</Text>
            <Text style={[styles.bold, styles.center]}>
              N° {values_form.cuenta_cobro}
            </Text>
          </View>

          <View style={{ marginTop: "30px" }}>
            <Text style={[styles.bold, styles.center]}>
              {values_form.client_name}
            </Text>
            <Text style={[styles.bold, styles.center]}>
              NIT {values_form.nit}
            </Text>
          </View>

          <View style={{ marginTop: "30px" }}>
            <Text style={[styles.bold, styles.center]}>DEBE A:</Text>
            <Text style={[styles.bold, styles.center]}>
              CONTENEDORES DE ANTIOQUIA S.A. S
            </Text>
            <Text style={[styles.bold, styles.center]}>NIT 900.167.316-4</Text>
          </View>

          <View style={{ marginTop: "30px" }}>
            <Text style={styles.text}>
              LA SUMA DE: {NumeroALetras(values_form.valor_deposito, {})} (
              {toCurrency(values_form.valor_deposito)} COP)
            </Text>
          </View>

          <View style={[styles.paragraph]}>
            <Text style={styles.text}>
              Por concepto: Deposito de garantía por valor al 70% sobre el mes
              de alquiler de:{" "}
              {data_detail.map((obj, index) => (
                <Text style={styles.text}>
                  {obj.tipo != "Mobiliario" ? "Contenedor de " : " "}
                  {obj.tipo} tipo {obj.producto}
                  {index != data_detail.length - 1 && ", "}
                </Text>
              ))}
              . Este monto se devolverá únicamente en el momento que sea
              devuelto el contenedor.
            </Text>
          </View>

          <View style={[styles.paragraph]}>
            <Text style={styles.text}>
              Favor consignar en la cuenta de corriente Bancolombia 39942121407
            </Text>
          </View>

          <View style={{ marginTop: "30px" }}>
            <Text style={styles.text}>Cordialmente,</Text>
          </View>

          <View style={[styles.paragraph]}>
            <View style={styles.footer}>
              <View style={styles.column_footer}>
                <Image
                  src={firma_mf}
                  style={{ width: "143px", height: "72px" }}
                />
                <Text style={styles.text}>Maria Fernanda Jiménez</Text>
                <Text style={styles.text}>Representante Legal</Text>
              </View>
            </View>
          </View>
        </View>
        <View fixed style={styles.fixed_footer_img}>
          <Image src={footer_contrato} style={{ width: "817px"}} />
        </View>
      </Page>
    </Document>
  );
}

export default Bill_Account;
