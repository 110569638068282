import React from "react";
import "./Tester.css";

function Tester() {
  return (
    <>
      {global.url_back === "http://34.218.131.134:8000/api" && (
        <div className="test_indicator">
          <h3 className="col_white">TEST</h3>
        </div>
      )}
    </>
  );
}

export default Tester;
