export const initialState = {
  actual_url: null,
  error_fetching: false,
};

//state : estado
//action: set esto , o setuser, o set xx
const reducer = (state, action) => {
  // console.log(action);

  switch (action.type) {
    case "SET_URL":
      return {
        ...state,
        actual_url: action.actual_url,
      };

    case "SET_ERROR_FETCH":
      return {
        ...state,
        error_fetching: action.error_fetching,
      };

    default:
      // siempre debe existir un default, que significa si nada pasa, devuelva el state, para que la app no crashee
      return state;
  }
};

//

export default reducer;
