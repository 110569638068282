import React, { useState, useEffect } from "react";
import Select from "react-select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ModalForm } from "../../../App/components/shared/Modals";
import fetch_backend from "../../../App/components/shared/Fetch";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";

import { useCookies } from "react-cookie";

import _ from "lodash";

// import "./Modals.css";

function ModalDevolucion({ open, onClose, initValuesModalDevolucion }) {
  const init_form_values = {
    fecha_devolucion: "",
    hora_devolucion: "",
    observaciones: "",
    es_funcional: "",
    proximo_mantenimiento: "",
  };

  const [formValues, setFormValues] = useState({
    ...initValuesModalDevolucion,
    ...init_form_values,
  });
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [isLoading, setIsLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_inventario;

  const change_input = (event) => {
    var { name, value } = event.target;
    if (name === "es_funcional") {
      value = value === "true";
    }
    let values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  };

  const validate = () => {
    const errors = {};

    if (!formValues.fecha_devolucion) {
      errors.fecha_devolucion = 'Falta Seleecionar "Fecha Devolución"';
    }

    if (!formValues.hora_devolucion) {
      errors.hora_devolucion = 'Falta Seleecionar "Hora"';
    }

    if (formValues.en_mantenimiento && !formValues.proximo_mantenimiento) {
      errors.proximo_mantenimiento =
        'Falta Seleecionar "Próximo Mantenimiento"';
    }

    if (formValues.es_funcional === "") {
      errors.es_funcional = 'Falta Seleecionar "¿Es funcional?"';
    }

    if (
      formValues.es_funcional === false &&
      formValues.observaciones.trim() === ""
    ) {
      errors.es_funcional = "Por favor diligencie las observaciones";
    }

    return errors;
  };

  const send_request = async () => {
    const errors = validate();

    if (!_.isEmpty(errors)) {
      console.log(errors);
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
    console.log(formValues);
    setIsLoading(true);
    const response = await fetch_backend(
      "tools/returns/",
      "POST",
      token,
      dispatch,
      formValues
    );
    setIsLoading(false);
    if (response.status === 200) {
      onClose(200);
    }
  };

  useEffect(() => {
    var values = { ...formValues };
    const today = new Date();
    var date = today.toISOString().slice(0, 10);
    var time = today
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .slice(0, 5);
    values.fecha_devolucion = date;
    values.hora_devolucion = time.toString();
    setFormValues(values);
  }, []);
  return (
    <>
      <ModalForm
        open={openModalForm}
        onClose={() => setOpenModalForm(false)}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <Dialog
        onClose={() => onClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h3>Devolución</h3>
        </DialogTitle>
        <DialogContent>
          <p
            style={{ marginBottom: "20px" }}
            className="label_input_modal p_modal_herramientas"
          >
            {initValuesModalDevolucion.descripcion}
          </p>
          <div className="row_modal">
            <div>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                Fecha
              </p>
              <input
                type="date"
                name="fecha_devolucion"
                onChange={(e) => change_input(e)}
                value={formValues?.fecha_devolucion}
              />
            </div>
            <div>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                Hora
              </p>
              <input
                type="time"
                name="hora_devolucion"
                onChange={(e) => change_input(e)}
                value={formValues?.hora_devolucion}
              />
            </div>
          </div>
          {initValuesModalDevolucion.en_mantenimiento && (
            <div className="row_modal">
              <div>
                <p
                  className="label_input_modal p_modal_herramientas"
                  style={{ fontWeight: "bold" }}
                >
                  Próximo Mantenimiento
                </p>
                <input
                  type="date"
                  name="proximo_mantenimiento"
                  onChange={(e) => change_input(e)}
                />
              </div>
              <div />
            </div>
          )}
          <div className="row_modal" style={{ marginBottom: "5px" }}>
            <div style={{ flex: 1 }}>
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "normal" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Observaciones Salida:{" "}
                </span>
                {initValuesModalDevolucion.observaciones_salida}
              </p>
            </div>
          </div>
          <div className="row_modal">
            <div className="obs_devolucion_container">
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                Observaciones
              </p>
              <textarea
                name="observaciones"
                onChange={(e) => change_input(e)}
              />
            </div>
            <div className="es_funcional_devolucion_container">
              <p
                className="label_input_modal p_modal_herramientas"
                style={{ fontWeight: "bold" }}
              >
                ¿Es Funcional?
              </p>
              <div className="simple_flex" style={{ marginTop: "20px" }}>
                <input
                  type="radio"
                  name="es_funcional"
                  value={true}
                  onClick={(e) => change_input(e)}
                />
                <p
                  className="label_input_modal"
                  style={{ fontWeight: "normal", marginLeft: "7px" }}
                >
                  Si
                </p>
              </div>
              <div className="simple_flex" style={{ marginTop: "15px" }}>
                <input
                  type="radio"
                  name="es_funcional"
                  value={false}
                  onClick={(e) => change_input(e)}
                />
                <p
                  className="label_input_modal"
                  style={{ fontWeight: "normal", marginLeft: "7px" }}
                >
                  No
                </p>
              </div>
            </div>
          </div>
          <button
            style={{
              padding: "3px 15px",
              margin: "0 0 0 auto",
            }}
            onClick={() => send_request()}
          >
            Guardar
            {isLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-spin"
                style={{ marginLeft: "8px" }}
              />
            )}
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}

export default ModalDevolucion;
