import React, { useState, useEffect } from "react";
import Select from "react-select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { ModalForm } from "../../../App/components/shared/Modals";

import { useCookies } from "react-cookie";
import { styles_react_select } from "../../../App/components/shared/Select";
import _ from "lodash";

function ModalCostCenter({ open, onClose, costCenters }) {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCostCenter, setSelectedCostCenter] = useState();
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModalForm, setSuccessModalForm] = useState(false);
  const [messageModalForm, setMessageModalForm] = useState();

  const change_react_select = (value) => {
    setSelectedCostCenter(value);
  };

  const validate = () => {
    const errors = {};

    if (!selectedCostCenter) {
      errors.tipo = "Por favor seleccione un centro de costos";
    }

    return errors;
  };

  const validate_selection = () => {
    const errors = validate();
    if (!_.isEmpty(errors)) {
      setSuccessModalForm(false);
      setMessageModalForm(errors);
      setOpenModalForm(true);
      return;
    }
    onClose(selectedCostCenter);
  };

  return (
    <>
      <ModalForm
        open={openModalForm}
        onClose={() => setOpenModalForm(false)}
        successModal={successModalForm}
        messageModal={messageModalForm}
      />
      <Dialog
        onClose={() => onClose(selectedCostCenter)}
        open={open}
        aria-labelledby="simple-dialog-title"
        disableBackdropClick={true}
      >
        <DialogContent style={{ textAlign: "center" }}>
          <p style={{ marginBottom: "20px" }}>Seleccione Centro de Costos</p>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleccione..."
            isSearchable={true}
            menuPosition="fixed"
            options={costCenters}
            styles={styles_react_select}
            onChange={(value) => change_react_select(value)}
          />

          <button
            style={{
              padding: "3px 15px",
              margin: "20px auto 0 auto",
            }}
            onClick={() => validate_selection()}
          >
            Aceptar
          </button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}

export default ModalCostCenter;
