import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { DistanceMatrixService } from "@react-google-maps/api";
import { ModalForm } from "../../../App/components/shared/Modals";
import _ from "lodash";
import "../Entrances/Modals.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Modal_Edit_Requirement({ open, onClose, editReq, onSubmitEditModal }) {
  const [formValues, setFormValues] = useState(editReq);
  const [fetchDistance, setFetchDistance] = useState(false);
  const [destination, setDestination] = useState([
    { placeId: formValues.destiny_id },
  ]);
  const [origins, setOrigins] = useState([{ placeId: formValues.origin_id }]);
  const [editPlaces, setEditPlaces] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [messageModal, setMessageModal] = useState();

  const onCloseModalForm = () => {
    setOpenModalForm(false);
  };

  const validate = () => {
    const errors = {};

    if (!formValues.origen) {
      errors.store_name = 'Falta completar "Origen"';
    }
    if (!formValues.destino) {
      errors.destino = 'Falta completar "Destino"';
    }
    if (!formValues.nombre_contacto) {
      errors.nombre_contacto = 'Falta completar "nombre_contacto"';
    }
    if (!formValues.celular) {
      errors.celular = 'Falta completar "Telefono"';
    }
    if (!formValues.email) {
      errors.email = 'Falta completar "Email"';
    }
    return errors;
  };

  const styles_react_select = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      border: "1px solid #707070",
      height: "32px",
      minHeight: "32px",
      borderRadius: "10px",
      width: "100%",
      // backgroundColor: "blue",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      // backgroundColor: "green",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
      // backgroundColor: "pink",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0",
      // backgroundColor: "red",
      fontSize: "16px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
      // backgroundColor: "yellow",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      // backgroundColor: "orange",
    }),

    placeholder: (styles) => ({
      ...styles,
      fontSize: "16px",
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: "16px",
    }),
  };

  const MapsConfig = {
    apiKey: "AIzaSyBLW2u3Jr70NZTMheJVqo9NLGN75VtS1AI",
    apiOptions: {
      language: "es-419",
      region: "co",
    },
    autocompletionRequest: {
      componentRestrictions: {
        country: "co",
      },
    },
    minLengthAutocomplete: 3,
    selectProps: {
      styles: styles_react_select,
      placeholder: "Seleccione...",
      onChange: (value, e) => onChangeReactSelect(value, e),
    },
  };

  const ChangeInput = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const onChangeReactSelect = (value, e) => {
    const name = e.name;
    setFormValues({ ...formValues, [name]: value.label, value_select: value });
    setFetchDistance(true);
    if (name === "origen") {
      setOrigins([{ placeId: value.value.place_id }]);
    } else {
      setDestination([{ placeId: value.value.place_id }]);
    }
  };

  const style_dialog = makeStyles({
    scrollPaper: {
      alignItems: "flex-end",
    },

    paperFullScreen: {
      height: "50%",
    },
  });

  const classes = style_dialog();

  const saveEdit = () => {
    const errors = validate();

    if (!_.isEmpty(errors)) {
      setSuccessModal(false);
      setMessageModal(errors);
      setOpenModalForm(true);
      return;
    }

    onSubmitEditModal(formValues);
  };

  const getDistance = (response) => {
    let km = response?.rows[0]?.elements[0].distance.value / 1000;
    if (km) {
      km = parseFloat(km.toFixed(1));
      setFormValues({ ...formValues, km: km });
      setFetchDistance(false);
    }
  };

  return (
    <>
      <ModalForm
        open={openModalForm}
        onClose={onCloseModalForm}
        successModal={successModal}
        messageModal={messageModal}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose()}
        classes={{
          scrollPaper: classes.scrollPaper,
          paperFullScreen: classes.paperFullScreen,
        }}
        TransitionComponent={Transition}
      >
        {fetchDistance && (
          <DistanceMatrixService
            options={{
              destinations: destination,
              origins: origins,
              travelMode: "DRIVING",
            }}
            callback={(response) => getDistance(response)}
          />
        )}
        <div className="title_modal_detail_entrance">
          <h4>Editar Requerimiento</h4>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="iconRow x_modal_detail"
            onClick={() => onClose()}
          />
        </div>
        <div className="content_modal_detail_entrance">
          <h4>Informacion Logística</h4>
          <div className="flex_content_modal_detail_entrance">
            <div>
              <p className="p_mov">Tipo</p>
              <p style={{ marginTop: "12px" }}>{formValues.tipo}</p>
            </div>
            <div style={{ width: "30%" }}>
              <p className="p_mov">Origen</p>
              {!editPlaces ? (
                <input type="text" disabled value={formValues.origen} />
              ) : (
                <GooglePlacesAutocomplete
                  // apiKey={MapsConfig.apiKey}
                  autocompletionRequest={MapsConfig.autocompletionRequest}
                  selectProps={{
                    ...MapsConfig.selectProps,
                    name: "origen",
                  }}
                  apiOptions={MapsConfig.apiOptions}
                  minLengthAutocomplete={MapsConfig.minLengthAutocomplete}
                />
              )}
            </div>
            <div style={{ width: "30%" }}>
              <p className="p_mov">Destino</p>
              {!editPlaces ? (
                <input type="text" disabled value={formValues.destino} />
              ) : (
                <GooglePlacesAutocomplete
                  // apiKey={MapsConfig.apiKey}
                  autocompletionRequest={MapsConfig.autocompletionRequest}
                  selectProps={{
                    ...MapsConfig.selectProps,
                    name: "destino",
                  }}
                  apiOptions={MapsConfig.apiOptions}
                  minLengthAutocomplete={MapsConfig.minLengthAutocomplete}
                />
              )}
            </div>
            <div>
              <p className="p_mov">Km</p>
              <p style={{ marginTop: "12px" }}>{formValues.km}</p>
            </div>
            {!editPlaces && (
              <div>
                <p className="p_mov" style={{ marginBottom: "8px" }}>
                  Editar
                </p>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="iconRow"
                  onClick={() => setEditPlaces(true)}
                />
              </div>
            )}
          </div>
          <h4>Persona de Contacto</h4>
          <div className="flex_content_modal_detail_entrance">
            <div>
              <p className="p_mov">Nombre</p>
              <input
                type="text"
                required
                name="nombre_contacto"
                value={formValues.nombre_contacto}
                onChange={(e) => ChangeInput(e)}
              />
            </div>
            <div>
              <p className="p_mov">celular</p>
              <input
                type="text"
                required
                name="celular"
                value={formValues.celular}
                onChange={(e) => ChangeInput(e)}
              />
            </div>
            <div>
              <p className="p_mov">Email</p>
              <input
                type="text"
                required
                name="email"
                value={formValues.email}
                onChange={(e) => ChangeInput(e)}
              />
            </div>
            <button
              style={{
                margin: "auto 0 0 20px",
                padding: "5px 15px",
                maxHeight: "35px",
              }}
              onClick={() => saveEdit()}
            >
              Guardar
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export { Modal_Edit_Requirement };
