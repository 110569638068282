import React, { useEffect } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";

function Suppliers_orders() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);
  return <div>Suppliers_orders</div>;
}

export default Suppliers_orders;
