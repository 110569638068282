import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import "./Modals.css";

function ModalInfoMtto({ open, onClose, initValuesModalInfoMtto }) {
  console.log(initValuesModalInfoMtto);
  return (
    <div>
      <Dialog
        onClose={() => onClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h3>Info. Mantenimiento</h3>
        </DialogTitle>
        <DialogContent>
          <div>
            <p className="label_input_modal" style={{ marginBottom: "15px" }}>
              {initValuesModalInfoMtto.descripcion}
            </p>
            <div className="flex_label_info_mtto">
              <p className="label_input_modal" style={{ fontWeight: "bold" }}>
                Fecha Mantenimiento
              </p>
              <p className="label_input_modal" style={{ fontWeight: "normal" }}>
                {initValuesModalInfoMtto.fecha_mtto}
              </p>
            </div>
            <div className="flex_label_info_mtto">
              <p className="label_input_modal" style={{ fontWeight: "bold" }}>
                Fecha Salida
              </p>
              <p className="label_input_modal" style={{ fontWeight: "normal" }}>
                {initValuesModalInfoMtto.fecha_salida}
              </p>
            </div>
            <div className="flex_label_info_mtto">
              <p className="label_input_modal" style={{ fontWeight: "bold" }}>
                Realizado Por
              </p>
              <p className="label_input_modal" style={{ fontWeight: "normal" }}>
                {initValuesModalInfoMtto.prestatario}
              </p>
            </div>
            <div className="flex_label_info_mtto">
              <p className="label_input_modal" style={{ fontWeight: "bold" }}>
                Entrega Estimada
              </p>
              <p className="label_input_modal" style={{ fontWeight: "normal" }}>
                {initValuesModalInfoMtto.entrega_estimada}
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ModalInfoMtto;
