import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import React, { useEffect, useState } from "react";
import { Modal_Edit_Requirement } from "./Modals";
import "./Entrance_Detail.css";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import {
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { DistanceMatrixService, LoadScript } from "@react-google-maps/api";
import { toCurrency } from "../../../App/services/services";
import { ModalForm, ModalFetch } from "../../../App/components/shared/Modals";
import { useCookies } from "react-cookie";
import Loading from "../../../App/components/Loading/Loading";
import Alert from "@material-ui/lab/Alert";

import _ from "lodash";

function Entrance_Detail() {
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [json_response, setJson_response] = useState({});
  const [requirements, setRequirements] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [req_to_edit, setReq_to_edit] = useState({});
  const [destination, setDestination] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [fetchDistance, setFetchDistance] = useState(false);
  const history = useHistory();
  const { client_data } = useLocation().state;
  const [justServices, setJustServices] = useState(true);
  const [justMobiliario, setJustMobiliario] = useState();

  //dialog modal form
  const [openModalForm, setOpenModalForm] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [messageModal, setMessageModal] = useState();
  const [alertSuccess, setAlertSuccess] = useState(false);

  //fetch states
  const { id_quote } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [openModalFetch, setOpenModalFetch] = useState();
  const [fetchSuccess, setFetchSuccess] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_centro_log;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });

    setIsLoading(true);
    // --PENDING --
    fetch(`${global.url_back}/get_entrys_detail/${id_quote}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            if (json_response.approved_items.length > 0) setJustServices(false);
            check_mobiliario(json_response.approved_items);
            setJson_response(json_response);
            setIsLoading(false);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  }, []);

  const check_mobiliario = (values) => {
    console.log(values);
    const others = values.filter((obj) => obj.tipo !== "Mobiliario").length;
    if (others > 0) {
      setJustMobiliario(false);
    } else {
      setJustMobiliario(true);
    }
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
  };

  const onCloseModalFetch = () => {
    setOpenModalFetch(false);
    if (fetchSuccess) history.goBack();
  };

  const validate = () => {
    const errors = {};

    if (!formValues.origen) {
      errors.store_name = 'Falta completar "Origen"';
    }
    if (!formValues.destino) {
      errors.destino = 'Falta completar "Destino"';
    }
    if (!formValues.nombre) {
      errors.nombre = 'Falta completar "Nombre"';
    }
    if (!formValues.telefono) {
      errors.telefono = 'Falta completar "Telefono"';
    }
    if (!formValues.email) {
      errors.email = 'Falta completar "Email"';
    }
    return errors;
  };

  const styles_react_select = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      border: "1px solid #707070",
      height: "32px",
      minHeight: "32px",
      maxWidth: "300px",
      borderRadius: "10px",
      width: "100%",
      // backgroundColor: "blue",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      // backgroundColor: "green",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
      maxWidth: "300px",
      // backgroundColor: "pink",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0",
      maxWidth: "300px",
      // backgroundColor: "red",
      fontSize: "16px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
      // backgroundColor: "yellow",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      maxWidth: "300px",
      // backgroundColor: "orange",
    }),

    placeholder: (styles) => ({
      ...styles,
      fontSize: "16px",
    }),

    singleValue: (styles) => ({
      ...styles,
      fontSize: "16px",
    }),
  };

  const MapsConfig = {
    apiKey: "AIzaSyBLW2u3Jr70NZTMheJVqo9NLGN75VtS1AI",
    apiOptions: {
      language: "es-419",
      region: "co",
    },
    autocompletionRequest: {
      componentRestrictions: {
        country: "co",
      },
    },
    minLengthAutocomplete: 3,
    selectProps: {
      styles: styles_react_select,
      placeholder: "Seleccione...",
      defaultValue: { value: "", label: "" },
      value: { value: formValues.value_select || "" },
      onChange: (value, e) => onChangeReactSelect(value, e),
    },
  };

  const ChangeInput = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const onChangeReactSelect = (value, e) => {
    const name = e.name;
    console.log(value);

    if (name === "origen") {
      setOrigins([{ placeId: value.value.place_id }]);
      setFormValues({
        ...formValues,
        [name]: value.label,
        value_select: value,
        origin_id: value.value.place_id,
      });
    } else {
      setDestination([{ placeId: value.value.place_id }]);
      setFormValues({
        ...formValues,
        [name]: value.label,
        value_select: value,
        destiny_id: value.value.place_id,
      });
    }
    setFetchDistance(true);
  };

  const new_requirement = (id, tipo) => {
    const errors = validate();

    if (!_.isEmpty(errors)) {
      setSuccessModal(false);
      setMessageModal(errors);
      setOpenModalForm(true);
      return;
    } else {
      setAlertSuccess(true);
      setTimeout(() => setAlertSuccess(false), 2000);
    }

    //create copy of formvalues without reference and push in requirements state
    let values = JSON.parse(JSON.stringify(formValues));
    values["id_quote_detail"] = id;
    values["tipo"] = tipo;

    let list_req = [...requirements];
    list_req.push(values);
    setRequirements(list_req);

    // //clean form
    // Object.keys(formValues).forEach((k) => (formValues[k] = ""));
    // setFormValues(formValues);
  };

  const onCloseModal = () => {
    setOpenModalEdit(false);
    setReq_to_edit({});
  };

  const onSubmitEditModal = (valuesModal) => {
    const index_to_edit = valuesModal.index;
    let reqs = [...requirements];
    reqs[index_to_edit] = valuesModal;
    setRequirements(reqs);
    setOpenModalEdit(false);
  };

  const click_edit_req = (item, index) => {
    item["index"] = index;
    setReq_to_edit(item);
    setOpenModalEdit(true);
  };

  const click_delete_req = (index) => {
    let reqs = [...requirements];
    reqs.splice(index, 1);
    setRequirements(reqs);
  };

  const getDistance = (response) => {
    console.log("fetching distance");
    let km = response?.rows[0]?.elements[0]?.distance?.value / 1000;
    if (km) {
      km = parseFloat(km.toFixed(1));
      setFormValues({ ...formValues, km: km });
      setFetchDistance(false);
    }
  };

  const sendRequirements = () => {
    // --PENDING--
    setIsLoading(true);
    fetch(`${global.url_back}/get_entrys_detail/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requirements),
    })
      .then((response) => {
        if (response.status === 200) {
          setFetchSuccess(true);
        } else {
          setFetchSuccess(false);
        }
        setOpenModalFetch(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  return (
    <>
      {openModalEdit && (
        <Modal_Edit_Requirement
          open={openModalEdit}
          onClose={onCloseModal}
          editReq={req_to_edit}
          onSubmitEditModal={onSubmitEditModal}
        />
      )}
      {fetchDistance && (
        <DistanceMatrixService
          options={{
            destinations: destination,
            origins: origins,
            travelMode: "DRIVING",
          }}
          callback={(response) => getDistance(response)}
        />
      )}

      <ModalForm
        open={openModalForm}
        onClose={onCloseModalForm}
        successModal={successModal}
        messageModal={messageModal}
      />

      <ModalFetch
        open={openModalFetch}
        onClose={onCloseModalFetch}
        fetchSuccess={fetchSuccess}
      />

      <div className="entrance_detail__container">
        <div
          className={`alert_container ${alertSuccess ? "visible" : "hidden"}`}
        >
          <Alert severity="success">Completado</Alert>
        </div>
        <Loading isLoading={isLoading} />
        <div className="detail_labels_container">
          <div className="detail_labels_col">
            <div className="flex_label_input_entrance">
              <p className="p_bold col_green">No. Cot</p>
              <p>{client_data.nro_cotizacion}</p>
            </div>
            <div className="flex_label_input_entrance">
              <p className="p_bold col_green">Cliente</p>
              <p>{client_data.nombre_cliente}</p>
            </div>
            <div className="flex_label_input_entrance">
              <p className="p_bold col_green">Nit</p>
              <p>{client_data.nit}</p>
            </div>
          </div>
          <div className="detail_labels_col">
            <div className="flex_label_input_entrance">
              <p className="p_bold col_green">Destino</p>
              <p>{client_data.destino_exacto}</p>
            </div>
            <div className="flex_label_input_entrance">
              <p className="p_bold col_green">Contacto</p>
              <p>{client_data.contacto}</p>
            </div>
            <div className="flex_label_input_entrance">
              <p className="p_bold col_green">Celular</p>
              <p>{client_data.celular}</p>
            </div>
          </div>
          <div className="detail_labels_col">
            <div className="flex_label_input_entrance">
              <p className="p_bold col_green">Email</p>
              <p>{client_data.email}</p>
            </div>
          </div>
        </div>
        <h4>Aprobado</h4>

        <div className="approved_items_container">
          {!justServices && (
            <div className="approved_items_col">
              <table className="table_approved_items">
                <tr>
                  <th style={{ width: "2%" }}></th>
                  <th style={{ width: "20%" }}>Item</th>
                  <th style={{ width: "23%" }}>Tipo</th>
                  <th style={{ width: "30%" }}>Producto</th>
                  <th style={{ width: "15%" }}>Cant.</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
                {json_response?.approved_items?.map((item, index) => (
                  <tr>
                    <td style={{ fontWeight: "bold" }}>{index + 1}</td>
                    <td>{item.item}</td>
                    <td>{item.tipo}</td>
                    <td>{item.producto}</td>
                    <td>{item.cantidad}</td>
                    <td style={{ padding: "3px 0" }}>
                      {(item.tipo !== "Mobiliario" || justMobiliario) && (
                        <div
                          className="square_plus"
                          onClick={() => new_requirement(item.id, item.tipo)}
                        >
                          <h4 className="col_white">+</h4>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}
          <div className="approved_items_col">
            <div className="flex_info_add">
              <div className="origen_div">
                <p className="p_mov">Origen</p>
                <GooglePlacesAutocomplete
                  autocompletionRequest={MapsConfig.autocompletionRequest}
                  // apiKey={MapsConfig.apiKey}
                  selectProps={{
                    ...MapsConfig.selectProps,
                    name: "origen",
                    value: {
                      ...MapsConfig.selectProps.value,
                      label: formValues.origen,
                    },
                  }}
                  apiOptions={MapsConfig.apiOptions}
                  minLengthAutocomplete={MapsConfig.minLengthAutocomplete}
                />
              </div>
              <div className="origen_div">
                <p className="p_mov">Destino</p>
                <GooglePlacesAutocomplete
                  // apiKey={MapsConfig.apiKey}
                  autocompletionRequest={MapsConfig.autocompletionRequest}
                  selectProps={{
                    ...MapsConfig.selectProps,
                    name: "destino",
                    value: {
                      ...MapsConfig.selectProps.value,
                      label: formValues.destino,
                    },
                  }}
                  apiOptions={MapsConfig.apiOptions}
                  minLengthAutocomplete={MapsConfig.minLengthAutocomplete}
                />
              </div>
              <div>
                <p className="p_mov">Km</p>
                <p>{formValues.km}</p>
              </div>
            </div>
            <p className="col_green p_bold" style={{ marginBottom: "10px" }}>
              Contacto
            </p>
            <div className="flex_info_add">
              <div>
                <p className="p_mov">Nombre</p>
                <input
                  type="text"
                  required
                  name="nombre"
                  value={formValues.nombre}
                  onChange={(e) => ChangeInput(e)}
                />
              </div>
              <div>
                <p className="p_mov">Telefono</p>
                <input
                  type="text"
                  required
                  name="telefono"
                  value={formValues.telefono}
                  onChange={(e) => ChangeInput(e)}
                />
              </div>
              <div>
                <p className="p_mov">Email</p>
                <input
                  type="text"
                  required
                  name="email"
                  value={formValues.email}
                  onChange={(e) => ChangeInput(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <h4>Logística</h4>
        <table style={{ margin: "15px 0 20px 0" }}>
          <tr>
            <th className="thno">No</th>
            <th className="thitem">Item</th>
            <th className="thtipo">Tipo</th>
            <th className="thproducto">Producto</th>
            <th className="thdescripcion">Descripcion</th>
            <th className="thcant">Cant</th>
            <th className="thvlrUnit">Valor Unit</th>
            <th className="thvlrBruto">Valor Bruto</th>
            <th className="thdcto">%Dcto</th>
            <th className="thiva">%Iva</th>
            <th className="thvlrTotal">Valor Total</th>
            <th className="thdelete">+</th>
          </tr>
          {json_response?.logistic_items?.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item.item}</td>
              <td>{item.tipo}</td>
              <td>{item.producto}</td>
              <td style={{ padding: "5px", textAlign: "left" }}>
                <textarea
                  disabled
                  style={{ width: "100%", height: "100%", minHeight: "110px" }}
                >
                  {item.descripcion}
                </textarea>
              </td>
              <td>{item.cant}</td>
              <td>{toCurrency(item.valorUnit)}</td>
              <td>{toCurrency(item.valorBruto)}</td>
              <td>{item.dcto}</td>
              <td>{item.iva}</td>
              <td>{toCurrency(item.valorTotal)}</td>
              <td>
                <div
                  className={`square_plus ${
                    !justServices && "inactive_background"
                  }`}
                  onClick={() => {
                    if (justServices) new_requirement(item.id, item.tipo);
                  }}
                >
                  <h4 className="col_white">+</h4>
                </div>
              </td>
            </tr>
          ))}
        </table>
        {requirements.length > 0 && (
          <>
            <h4>Ítems Programados</h4>
            <div className="scheduled_requirements">
              <table className="table_scheduled_requirements">
                <tr>
                  <th style={{ width: "2%" }}>No</th>
                  <th style={{ width: "10%" }}>Tipo</th>
                  <th style={{ width: "40%", textAlign: "left" }}>Origen</th>
                  <th style={{ width: "40%", textAlign: "left" }}>Destino</th>
                  <th style={{ width: "4%" }}></th>
                  <th style={{ width: "4%" }}></th>
                </tr>
                {requirements?.map((item, index) => (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.tipo}</td>
                      <td style={{ textAlign: "left", padding: "5px 5px" }}>
                        {item.origen}
                      </td>
                      <td style={{ textAlign: "left", padding: "5px 5px" }}>
                        {item.destino}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="#266e45"
                          className="iconRow"
                          onClick={() => click_edit_req(item, index)}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          color="#266e45"
                          className="iconRow"
                          onClick={() => click_delete_req(index)}
                        />
                      </td>
                    </tr>
                  </>
                ))}
              </table>
            </div>
            <button
              style={{ margin: "40px 0 0 0" }}
              onClick={() => sendRequirements()}
            >
              Finalizar
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default Entrance_Detail;
