import React, { useState, useEffect } from "react";
import ClientAdd from "./ClientAdd";
import EditClient from "./EditClient";
import {
  faPlus,
  faSearch,
  faSpinner,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Cookies from "universal-cookie";
import Select from "react-select";
import { Redirect, useHistory } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";

function Clients() {
  const [{ actual_url }, dispatch] = useDataLayerValue();

  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const codigo_vendedor = cookies.get("codigo_vendedor");
  const super_user = cookies.get("super_user");

  const history = useHistory();
  const { path, url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseClients, setResponseClients] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [searchBy, setSearchBy] = useState("Nit");
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);

  const getQuery = () => {
    setIsSearch(!isSearch);
    if (!isSearch) {
      setIsLoading(true);
      fetch(`${global.url_back}/get_clients/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json_response) => {
              setResponseClients(json_response);
              setIsLoading(false);
            });
          } else {
            console.log("error Clients");
            setFetchSuccess(false);
            setOpenDialog(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsLoading(false);
        });
    }
  };

  const fetchQuery = () => {
    setIsSubmitting(true);
    //avoid querys when empty input
    if (query === "") {
      setIsSubmitting(false);
      return;
    }
    fetch(`${global.url_back}/get_clients/?pk=${query}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setIsSubmitting(false);

            history.push(`${url}/edit_client`, {
              state: { json_response: json_response },
            });
          });
        } else {
          console.log("error Clients-query");
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
        setIsSubmitting(false);
      });
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderColor: "#9e9e9e",
      padding: 0,
      height: "32px",
      minHeight: "32px",
      maxWidth: "300px",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      maxWidth: "300px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
      maxWidth: "300px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      maxWidth: "300px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      maxWidth: "300px",
    }),
  };

  return (
    <Switch>
      <Route exact path={path}>
        <div className="container__clients">
          <Dialog
            onClose={() => {
              setOpenDialog(false);
            }}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
          >
            <DialogTitle id="simple-dialog-title">
              {fetchSuccess ? (
                <FontAwesomeIcon icon={faCheckCircle} size="2x" />
              ) : (
                <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
              )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {fetchSuccess
                  ? "Cliente creado con Exito"
                  : "Algo salio mal, intenta nuevamente"}
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <div className="img__icon">
            <img
              src={require("../../../App/images/fabrica.svg")}
              alt=""
              className="img__factory"
            />

            <h2>Clientes</h2>
          </div>
          <div className="buttons_access">
            <Link to={`${url}/clientadd`} style={{ marginRight: "10%" }}>
              <div className="square__button">
                <FontAwesomeIcon
                  className="icon__clientshome"
                  icon={faPlus}
                  color="white"
                />
                <h4 className="white">Agregar</h4>
              </div>
            </Link>
            <div className="square__button" onClick={() => getQuery()}>
              <FontAwesomeIcon
                className="icon__clientshome"
                icon={faSearch}
                color="white"
              />
              <h4 className="white">Editar/Consultar</h4>
            </div>
          </div>
          {isSearch ? (
            <>
              <div className="search__menu">
                <div className="search__by">
                  <div className="search__by__contain">
                    <p className="tagSearch bold">Buscar Por</p>
                    <div className="radio_input_clientes">
                      <input
                        className="inputRadio"
                        type="radio"
                        id="Nit"
                        name="Nit"
                        value={searchBy}
                        checked={searchBy === "Nit"}
                        onClick={() => setSearchBy("Nit")}
                        readOnly
                      />
                      <label>Nit</label>
                    </div>
                    <div className="radio_input_clientes">
                      <input
                        className="inputRadio"
                        type="radio"
                        id="Nombre"
                        name="Nombre"
                        value={searchBy}
                        checked={searchBy === "Nombre"}
                        onClick={() => setSearchBy("Nombre")}
                        readOnly
                      />
                      <label>Nombre</label>
                    </div>
                  </div>
                </div>
                <div className="search__input">
                  <p className="tagSearch bold">
                    Seleccione {searchBy == "Nit" ? "Nit/Cedula" : "Nombre"}
                  </p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isLoading={isLoading}
                    isSearchable={true}
                    name="color"
                    options={
                      searchBy === "Nit"
                        ? responseClients?.ids
                        : responseClients?.names
                    }
                    styles={colourStyles}
                    onChange={(value) => {
                      setQuery(value.value);
                    }}
                  />
                </div>
              </div>
              <button className="btn-userconfig" onClick={() => fetchQuery()}>
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  "Buscar"
                )}
              </button>
            </>
          ) : null}
        </div>
      </Route>
      <Route path={`${path}/edit_client`}>
        <EditClient />
      </Route>

      <Route path={`${path}/clientadd`}>
        <ClientAdd />
      </Route>
    </Switch>
  );
}

export default Clients;
