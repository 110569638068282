import React from "react";
import {
  Route,
  Redirect,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import Cookies from "universal-cookie";
import { init_centro_log_location } from "../../Centro_Logistico/constants/constants";
import { init_crm_location } from "../../CRM/constants/constants";
import { init_inventario_location } from "../../Inventario/constants/constants";
import { init_orders_location } from "../../Pedidos/constants/constants";
import { init_purchase_location } from "../../Compras/constants/constants";

function PrivateRoute({ children, ...rest }) {
  const cookies = new Cookies();
  const { pathname } = useLocation();
  return (
    <Route
      {...rest}
      render={() =>
        pathname.includes(init_crm_location) && cookies.get("token_crm") ? (
          children
        ) : pathname.includes(init_centro_log_location) &&
          cookies.get("token_centro_log") ? (
          children
        ) : pathname.includes(init_inventario_location) &&
          cookies.get("token_inventario") ? (
          children
        ) : pathname.includes(init_purchase_location) &&
          cookies.get("token_crm") ? (
          children
        ) : pathname.includes(init_orders_location) &&
          cookies.get("token_crm") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: pathname.includes("crm")
                ? "/log/crm"
                : pathname.includes("centro_log")
                ? "/log/centro_log"
                : pathname.includes("inventario")
                ? "/log/inventario"
                : pathname.includes("purchase")
                ? "/log/purchase"
                : pathname.includes("orders")
                ? "/log/orders"
                : pathname.includes("compras"),
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
