import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Loading.css";
function Loading({ isLoading, children }) {
  return (
    <>
      <div className={`loading_container ${isLoading ? "visible" : "hidden"}`}>
        <FontAwesomeIcon icon={faSpinner} className="loading_icon fa-spin" />
      </div>
    </>
  );
}

export default Loading;
