import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";

function Outputs() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);

  return (
    <div>
      <h1>Outputs</h1>
    </div>
  );
}

export default Outputs;
