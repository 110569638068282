import React, { useEffect, useState } from "react";
import "./Entrances.css";
import { ModalQuestion } from "../../../App/components/shared/Modals";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import TableHeader from "../../../App/components/shared/TableHeader";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ModalFetch } from "../../../App/components/shared/Modals";
import Loading from "../../../App/components/Loading/Loading";

function Entrances() {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [sortAscen, setSortAscen] = useState();
  const [json_response, setJson_response] = useState([]);
  const [clicked_column, setClicked_column] = useState("");
  const [backupJson_response, setBackupJson_response] = useState();
  const [open_modal_question_close, setOpen_modal_question_close] =
    useState(false);
  const [id_for_close, setId_for_close] = useState();

  //fetch states
  //fetch holisss
  const [isLoading, setIsLoading] = useState();
  const [openModalFetch, setOpenModalFetch] = useState();
  const [fetchSuccess, setFetchSuccess] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_centro_log;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });

    setIsLoading(true);
    fetch(`${global.url_back}/get_entrys/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setJson_response(json_response);
            setBackupJson_response(json_response);
            setIsLoading(false);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  }, []);

  const onCloseModalFetch = () => {
    setOpenModalFetch(false);
  };

  const sortFunction = (sub) => {
    console.log(sub);
    setSortAscen(!sortAscen);
    const listTasks = [...json_response];
    listTasks.sort((a, b) =>
      a[sub].toString().localeCompare(b[sub].toString())
    );

    if (sortAscen) {
      setJson_response(listTasks);
    } else {
      setJson_response(listTasks.reverse());
    }
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const handleSearch = (value) => {
    let newList = [...backupJson_response];
    if (value !== "") {
      newList = newList.filter(
        (row) =>
          row.contacto.toLowerCase().includes(value.toLowerCase()) ||
          row.nombre_cliente.toLowerCase().includes(value.toLowerCase())
      );
      setJson_response(newList);
    } else {
      setJson_response(backupJson_response);
    }
  };

  const click_req = (row) => {
    history.push(`${path}/${row.nro_cotizacion}`, { client_data: row });
  };

  const click_close_icon = (id) => {
    setId_for_close(id);
    setOpen_modal_question_close(true);
  };

  const close_entrance = (value) => {
    const answer = value.value === "true";
    setOpen_modal_question_close(false);

    if (answer) {
      setIsLoading(true);
      fetch(`${global.url_back}/get_entrys/${value.params}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            json_response.splice(
              json_response.findIndex((i) => i.nro_cotizacion === value.params),
              1
            );
            setJson_response(json_response);
            setFetchSuccess(true);
          } else {
            setFetchSuccess(false);
          }
          setOpenModalFetch(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setFetchSuccess(false);
          setOpenModalFetch(true);
        });
    }
  };

  return (
    <div className="wrapped__container_top_bar ">
      <ModalQuestion
        open={open_modal_question_close}
        onClose={() => setOpen_modal_question_close(false)}
        question="¿Está seguro que desea cerrar esta entrada?"
        params={id_for_close}
        callback={close_entrance}
      />
      <Loading isLoading={isLoading} />

      <ModalFetch
        open={openModalFetch}
        onClose={onCloseModalFetch}
        fetchSuccess={fetchSuccess}
      />

      <div className="top-bar">
        <h3>Entradas</h3>
      </div>
      <div className="search_div">
        <div className="search_container">
          <input
            type="text"
            onChange={(event) => handleSearch(event.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} color="#383838" />
        </div>
        <div className="guides_2">
          <div className="sub_guide">
            <div
              className="square_estado"
              style={{ backgroundColor: "red", marginRight: "5px" }}
            ></div>
            <p>Sin Gestionar</p>
          </div>
          <div className="sub_guide">
            <div
              className="square_estado"
              style={{ backgroundColor: "green", marginRight: "5px" }}
            ></div>
            <p>Gestionada</p>
          </div>
        </div>
      </div>
      <div className="task-content">
        <table className="table_tasks">
          <tr className="rowHeaders">
            <th style={{ width: "3%" }} />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="No. Cot"
              txt_filter="nro_cotizacion"
              width="6%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Cliente"
              txt_filter="nombre_cliente"
              width="20.5%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Destino"
              txt_filter="ciudad"
              width="14%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Contacto"
              txt_filter="contacto"
              width="16%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Celular"
              txt_filter="celular"
              width="10%"
            />
            <TableHeader
              sortFunction={sortFunction}
              sortAscen={sortAscen}
              click_column={click_column}
              clicked_column={clicked_column}
              col_name="Fecha Ingreso Logística"
              txt_filter="fecha_logistica"
              width="20.5%"
            />
            <TableHeader col_name="Req." width="5%" />
            <TableHeader col_name="Cerrar" width="5%" />
          </tr>
          {json_response?.map((row, index) => (
            <>
              <tr className="rowTasks">
                <td>
                  <div className="wrapper-estado">
                    <div
                      className="square_estado"
                      style={{ backgroundColor: `${row.color}` }}
                    ></div>
                  </div>
                </td>
                <td>{row.nro_cotizacion}</td>
                <td>{row.nombre_cliente}</td>
                <td>{row.ciudad}</td>
                <td>{row.contacto}</td>
                <td>{row.celular}</td>
                <td>{row.fecha_logistica}</td>
                <td>
                  <div className="square_plus" onClick={() => click_req(row)}>
                    <h4 className="col_white">+</h4>
                  </div>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color={row.color === "green" ? "383838" : "#e6e6e6"}
                    className="iconRow"
                    style={row.color === "green" ? { cursor: "pointer" } : {}}
                    onClick={() => {
                      if (row.color === "green") {
                        click_close_icon(row.nro_cotizacion);
                      }
                    }}
                  />
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
    </div>
  );
}

export default Entrances;
