import React, { useEffect, useState } from "react";

const fetch_backend = async (url, method, token, dispatch, payload = {}) => {
  var json_response = {};
  try {
    var response;
    if (method === "GET") {
      response = await fetch(`${global.url_back}/${url}`, {
        method: `${method}`,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
    } else {
      response = await fetch(`${global.url_back}/${url}`, {
        method: `${method}`,
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    }

    if (response.status != 200 && dispatch) {
      dispatch({
        type: "SET_ERROR_FETCH",
        error_fetching: true,
      });
      json_response.status = response.status;
      return json_response;
    }

    try {
      json_response = await response.json();
    } catch (error) {
      console.log(`error while parsing response: ${error}`);
    }

    json_response.status = response.status;
    console.log(json_response);
    return json_response;
  } catch (error) {
    console.log(`error fetching, details: ${error}`);
    if (dispatch) {
      dispatch({
        type: "SET_ERROR_FETCH",
        error_fetching: true,
      });
    }
    json_response.status = 500
    return json_response
  }
};

export default fetch_backend;
