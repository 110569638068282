import React, { useState, useEffect } from "react";
import Select from "react-select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function ModalDetail({ open, onClose, data }) {
  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      aria-labelledby="simple-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
        <h3>Detalle</h3>
      </DialogTitle>
      <DialogContent>
        <div
          className="title_modal_detail_entrance"
          style={{ marginBottom: "0px" }}
        >
          <FontAwesomeIcon
            className="iconRow x_modal_detail"
            onClick={() => onClose()}
          />
        </div>
        <div className="content_modal_detail_entrance">
          <h4 style={{ marginBottom: "15px" }}>Informacion Logística</h4>
          <div className="flex_content_modal_detail_entrance">
            <div>
              <p className="p_mov">Tipo</p>
              <p style={{ marginTop: "12px" }}>{data?.tipo}</p>
            </div>
            <div>
              <p className="p_mov">Origen</p>
              <p style={{ marginTop: "12px" }}>{data?.origen}</p>
            </div>
            <div>
              <p className="p_mov">Destino</p>
              <p style={{ marginTop: "12px" }}>{data?.destino}</p>
            </div>
            <div>
              <p className="p_mov">Km</p>
              <p style={{ marginTop: "12px" }}>{data?.km}</p>
            </div>
          </div>
          <h4 style={{ marginBottom: "10px" }}>Persona de Contacto</h4>
          <div className="flex_content_modal_detail_entrance">
            <div>
              <p className="p_mov">Nombre</p>
              <p style={{ marginTop: "12px" }}>{data?.nombre_contacto}</p>
            </div>
            <div>
              <p className="p_mov">Celular</p>
              <p style={{ marginTop: "12px" }}>{data?.celular}</p>
            </div>
            <div>
              <p className="p_mov">Email</p>
              <p style={{ marginTop: "12px" }}>{data?.email}</p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalDetail;
