import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";

function Dashboard() {
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);

  return (
    <div className="main_container_dashboard">
      <Link to={`${url}/userconfig`}>
        <div style={{ margin: "0 0 0 auto", width: "30px" }}>
          <FontAwesomeIcon
            className="icon2"
            icon={faUser}
            color="#383838"
            size="2x"
          />
        </div>
      </Link>
    </div>
  );
}

export { Dashboard };
