import React, { useState, useEffect } from "react";
import "./Search.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faInfoCircle,
  faSpinner,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faCheckCircle,
  faTimesCircle,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import Select from "react-select";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { url_crm_quote } from "../../constants/constants";

function Search() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [query, setQuery] = useState("");
  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const [countryClient, setCountryClient] = useState("Colombia");
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("Nit");
  const [responseClients, setResponseClients] = useState();
  const [edit1, setEdit1] = useState(false);
  const [edit2, setEdit2] = useState(false);
  const [responseQuotes2, setResponseQuotes2] = useState();

  var fecha_inicial = moment().subtract(1, "month").format("YYYY-MM-DD");

  var fecha_final = moment().format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(fecha_inicial);

  const [fecha_f, setFechaF] = useState(fecha_final);

  const [razon_social, setRazon] = useState("");

  const [nit_search, setNit] = useState("");

  const [Contact_search, setContact] = useState("");

  const [state_search, setState_search] = useState("");

  const [cambio_con, setcambio] = useState(true);

  const [isClearable2, stclear2] = useState(true);

  const [responseSearch, setResponseSearch] = useState();

  const sendSearch = () => {
    setIsSubmitting(true);

    // alert(JSON.stringify(values, null, 2));
    fetch(`${global.url_back}/get_search/${codigo_vendedor}/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        razon_social: razon_social,
        nit: nit_search,
        fecha: fecha,
        fecha_f: fecha_f,
        state_search: state_search,
        contact_search: Contact_search,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setResponseSearch(json_response);
            setIsLoading(false);
            setIsSubmitting(false);
          });
        } else {
          setIsSubmitting(false);
          setFetchSuccess(false);
          setOpenDialog(true);
          console.log("error fetchingform-clientAdd");
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setFetchSuccess(false);
        setOpenDialog(true);
        console.log(error);
      });
  };

  const getQuery2 = (value) => {
    if (searchBy !== "Nombre") {
      setIsLoading(true);
      fetch(`${global.url_back}/get_contacts/${codigo_vendedor}/?pk=${value}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json_response) => {
              setResponseQuotes2(json_response);
              console.log(json_response);
              setIsLoading(false);
            });
          } else {
            console.log("error Quote");
            setFetchSuccess(false);
            setOpenDialog(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsLoading(false);
        });
    } else {
      setQuery(value);
      console.log("ok");
    }
  };

  const history = useHistory();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const { path, url } = useRouteMatch();

  const [clickSort, setClickSort] = useState();
  const [sortAscen, setSortAscen] = useState("asc");

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderColor: "#9e9e9e",
      padding: 0,
      height: "32px",
      minHeight: "32px",
      maxWidth: "300px",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      maxWidth: "300px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
      maxWidth: "300px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      maxWidth: "300px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      maxWidth: "300px",
    }),
  };
  const [responseVendors, setResponseVendors] = useState();
  const codigo_vendedor = cookies.get("codigo_vendedor");

  const sortFunction = (sub1, sub2 = "") => {
    setSortAscen(!sortAscen);

    const listTasks = [...responseSearch];

    if (sub2 !== "") {
      listTasks.sort((a, b) => a[sub1][sub2].localeCompare(b[sub1][sub2]));
    } else {
      listTasks.sort((a, b) => a[sub1].localeCompare(b[sub1]));
    }

    if (sortAscen) {
      setResponseSearch(listTasks);
    } else {
      setResponseSearch(listTasks.reverse());
    }
  };

  const sortFunction_number = (sub1) => {
    setSortAscen(!sortAscen);
    const listTasks = [...responseSearch];

    listTasks.sort((a, b) => {
      if (a[sub1] < b[sub1]) {
        return -1;
      }
      if (a[sub1] > b[sub1]) {
        return 1;
      }
      return 0;
    });

    if (sortAscen) {
      setResponseSearch(listTasks);
    } else {
      setResponseSearch(listTasks.reverse());
    }
  };

  const sortFunction_proxSgto = (sub1, sub2) => {
    setSortAscen(!sortAscen);

    const listTasks = [...responseSearch];

    listTasks.sort((a, b) => {
      if (a[sub1][sub2] === null) {
        return 1;
      }
      if (b[sub1][sub2] === null) {
        return -1;
      }
      if (a[sub1][sub2] < b[sub1][sub2]) {
        return -1;
      }
      if (a[sub1][sub2] > b[sub1][sub2]) {
        return 1;
      }
      return 0;
    });
    if (sortAscen) {
      setResponseSearch(listTasks);
    } else {
      setResponseSearch(listTasks.reverse());
    }
  };

  useEffect(() => {
    fetch(`${global.url_back}/get_searchinfo/${codigo_vendedor}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseVendors(json_response);
          });
        } else {
          console.log("error UserConfig");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  }, []);

  const get_Quote = (nro_cotizacion) => {
    fetch(`${global.url_back}/get_quote_detail/?pk=${nro_cotizacion}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            history.push(`${url_crm_quote}/quoteadd`, {
              state: { json_response: json_response },
            });
          });
        } else {
          console.log("error Quote-query");
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setOpenDialog(true);
      });
  };

  return (
    <div className="container__search">
      <div className="search_header_container">
        <Dialog
          onClose={() => {
            setOpenDialog(false);
          }}
          aria-labelledby="simple-dialog-title"
          open={openDialog}
        >
          <DialogTitle id="simple-dialog-title">
            <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Algo salio mal, intenta nuevamente
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <div className="header_search">
          <h1>Buscar Cotizaciones por:</h1>
        </div>

        <div className="input_tag_addclient" style={{ width: "400px" }}>
          <p className="labeltag">Razon Social:*</p>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleccione..."
            isLoading={isLoading}
            isSearchable={true}
            name="color"
            isDisabled={edit2 ? true : false}
            isClearable={true}
            defaultValue={""}
            options={
              searchBy === "Nit"
                ? responseVendors?.razon_social
                : responseClients?.names
            }
            styles={colourStyles}
            onChange={(value) => {
              if (value === null) {
                value = "";
                setEdit1(false);
                setcambio(true);
                setRazon("");
              } else {
                setRazon(value.value);
                setNit("");
                getQuery2(value.value);
                setEdit1(true);
                setcambio(false);
              }
            }}
          />
        </div>
        <div className="form_search">
          <div
            className="col-contacsearch"
            style={{ marginRight: "4%", marginTop: "5px" }}
          >
            <div className="input_tag_addclient">
              <p className="labeltag">Identificación (Nit / Cedula)</p>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                defaultValue={""}
                isLoading={isLoading}
                isSearchable={true}
                isDisabled={edit1 ? true : false}
                isClearable={true}
                name="color"
                options={
                  searchBy === "Nit"
                    ? responseVendors?.nit
                    : responseClients?.names
                }
                styles={colourStyles}
                onChange={(value) => {
                  if (value === null) {
                    value = "";
                    setEdit2(false);
                    setcambio(true);
                    setNit("");
                  } else {
                    setRazon("");
                    setNit(value.value);
                    setEdit2(true);
                    getQuery2(value.value);
                    setcambio(false);
                  }
                }}
              />

              <p
                className="labeltag"
                style={{ marginRight: "4%", marginTop: "5px" }}
              >
                Fecha desde
              </p>
              <div className="quoteadd_flex__search">
                <div className="input_tag_addclient">
                  <input
                    type="date"
                    className="input__info_search"
                    value={fecha}
                    onChange={(event) => setFecha(event.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-contacsearch"
            style={{ marginRight: "4%", marginTop: "5px" }}
          >
            <div className="input_tag_addclient">
              <p className="labeltag2">Seleccione Contacto</p>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Seleccione..."
                isLoading={isLoading}
                isSearchable={true}
                isClearable={true}
                name="color"
                options={
                  cambio_con === true
                    ? responseVendors?.contactos
                    : responseQuotes2?.quotes
                }
                styles={colourStyles}
                onChange={(value) => {
                  if (value === null) {
                    value = "";
                    setContact("");
                    setNit("");
                    setRazon("");
                    setEdit2(false);
                    setEdit1(false);
                    setcambio(true);
                    window.location.reload(true);
                  } else {
                    setContact(value.value);
                    console.log(Contact_search);
                    setEdit2(true);
                    setEdit1(true);
                    setcambio(false);
                  }
                }}
              />

              <p
                className="labeltag2"
                style={{ marginRight: "4%", marginTop: "5px" }}
              >
                Fecha Hasta
              </p>
              <div className="quoteadd_flex__search">
                <div className="input_tag_addclient">
                  <input
                    type="date"
                    className="input__info_search"
                    value={fecha_f}
                    onChange={(event) => setFechaF(event.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-contacsearch"
            style={{ marginRight: "4%", marginTop: "5px" }}
          >
            <div className="input_tag_addclient">
              <p className="labeltag2">Estado de Cotización</p>
              <select
                name="canalContacto"
                setState_search
                onChange={(event) => setState_search(event.target.value)}
                className="select__addclient"
              >
                <option value="">-</option>

                <option value="borrador">Borrador</option>
                <option value="pdf">PDF</option>
              </select>

              <button
                className="btn_search"
                type="submit"
                onClick={() => sendSearch()}
              >
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin"
                    style={{ fontSize: "15px" }}
                  />
                ) : (
                  "Buscar"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <table className="table_search">
        <tr className="rowHeaders rowSearch">
          <th className="thestado_task"> </th>
          <th
            className="thcodigo"
            onClick={() => {
              sortFunction_number("codigo_vendedor");
              setClickSort("codigo_vendedor");
            }}
          >
            Codigo
            {clickSort === "codigo_vendedor" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>
          <th
            className="thNoCot_task"
            onClick={() => {
              sortFunction_number("nocot");
              setClickSort("noCot");
            }}
          >
            No.Cot
            {clickSort === "noCot" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>
          <th
            className="thCliente_task"
            onClick={() => {
              sortFunction("cliente");
              setClickSort("cliente");
            }}
          >
            Cliente{" "}
            {clickSort === "cliente" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>
          <th
            className="thFecha"
            onClick={() => {
              sortFunction("fecha");
              setClickSort("fecha");
            }}
          >
            Fecha Cotización
            {clickSort === "fecha" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>
          <th
            className="thContacto_task"
            onClick={() => {
              sortFunction("contacto");
              setClickSort("contacto");
            }}
          >
            Contacto
            {clickSort === "contacto" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>
          <th
            className="thNro_contacto"
            onClick={() => {
              sortFunction("nro_contacto");
              setClickSort("nro_contacto");
            }}
          >
            Nro. Contacto
            {clickSort === "nro_contacto" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>
          <th
            className="thUltimoSgto_task"
            onClick={() => {
              sortFunction("ultimo");
              setClickSort("ultimoSgto");
            }}
          >
            Ultimo Sgto
            {clickSort === "ultimoSgto" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>
          <th
            className="thMonto_task"
            onClick={() => {
              sortFunction_number("monto");
              setClickSort("monto");
            }}
          >
            Monto
            {clickSort === "monto" ? (
              sortAscen ? (
                <FontAwesomeIcon icon={faLongArrowAltUp} color="white" />
              ) : (
                <FontAwesomeIcon icon={faLongArrowAltDown} color="white" />
              )
            ) : null}
          </th>

          <th className="thDetalle_task">Detalle</th>
        </tr>
        {responseSearch?.map((row, index) => (
          <>
            <tr className="rowTasks">
              <td>
                <div
                  className="square_estado"
                  style={{ backgroundColor: `${row.color}` }}
                ></div>
              </td>
              <td>
                <div className="wrapper-icon-codigo">
                  <FontAwesomeIcon
                    icon={faUserAlt}
                    color="#383838"
                    style={{ fontSize: "18px" }}
                  />
                  <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                    {row.codigo_vendedor}
                  </div>
                </div>
              </td>
              <td>{row.nocot}</td>
              <td>{row.cliente}</td>
              <td>{row.fecha}</td>
              <td>{row.contacto}</td>
              <td>{row.nro_contacto}</td>
              <td>{row.ultimo}</td>
              <td>{row.monto}</td>

              <td>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => get_Quote(row.nocot, row.color)}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#383838"
                    className="iconRow"
                  />
                </div>
              </td>
            </tr>
          </>
        ))}
      </table>
    </div>
  );
}

export default Search;
