import React, { useState, useEffect } from "react";

import { faAddressBook } from "@fortawesome/free-regular-svg-icons";
import {
  faPlus,
  faSearch,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "universal-cookie";
import {
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import ContactAdd from "./ContactAdd";
import EditContact from "./EditContact";
import Select from "react-select";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";

function Contacts() {
  const [{ actual_url }, dispatch] = useDataLayerValue();

  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const codigo_vendedor = cookies.get("codigo_vendedor");
  const super_user = cookies.get("super_user");

  const history = useHistory();
  const { path, url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseQuotes, setResponseQuotes] = useState();
  const [responseQuotes2, setResponseQuotes2] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [searchBy, setSearchBy] = useState("Nombre");
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });
  }, []);

  const getQuery2 = (value) => {
    if (searchBy !== "Nombre") {
      setIsLoading(true);
      fetch(`${global.url_back}/get_contacts/${codigo_vendedor}/?pk=${value}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json_response) => {
              setResponseQuotes2(json_response);
              setIsLoading(false);
            });
          } else {
            console.log("error Quote");
            setFetchSuccess(false);
            setOpenDialog(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsLoading(false);
        });
    } else {
      setQuery(value);
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderColor: "#9e9e9e",
      padding: 0,
      height: "32px",
      minHeight: "32px",
      maxWidth: "300px",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      maxWidth: "300px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
      maxWidth: "300px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      maxWidth: "300px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      maxWidth: "300px",
    }),
  };

  const getQuery = () => {
    setIsSearch(!isSearch);
    if (!isSearch) {
      setIsLoading(true);
      fetch(`${global.url_back}/get_contacts/${codigo_vendedor}/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json_response) => {
              setResponseQuotes(json_response);

              setIsLoading(false);
            });
          } else {
            console.log("error Contact");
            setFetchSuccess(false);
            setOpenDialog(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsLoading(false);
        });
    }
  };

  const fetchQuery = () => {
    //avoid query when empty input
    if (query === "") {
      return;
    }
    setIsSubmitting(true);

    fetch(`${global.url_back}/get_contact_view/?pk=${query}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response.json().then((json_response) => {
            setIsSubmitting(false);

            history.push(`${url}/edit_contact`, {
              state: { json_response: json_response },
            });
          });
        } else {
          console.log("error Quote-query");
          setFetchSuccess(false);
          setOpenDialog(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
        setIsSubmitting(false);
      });
  };

  return (
    <Switch>
      <Route exact path={path}>
        <div className="container__clients">
          <div className="img__icon">
            <FontAwesomeIcon
              className="img__icon_fa"
              icon={faAddressBook}
              color="black"
            />
            <h2>Contactos</h2>
          </div>
          <div className="buttons_access">
            <Link to={`${url}/contactadd`} style={{ marginRight: "10%" }}>
              <div className="square__button">
                <FontAwesomeIcon
                  className="icon__clientshome"
                  icon={faPlus}
                  color="white"
                />
                <h4 className="white">Agregar</h4>
              </div>
            </Link>

            <div className="square__button" onClick={() => getQuery()}>
              <FontAwesomeIcon
                className="icon__clientshome"
                icon={faSearch}
                color="white"
              />

              <h4 className="white">Editar/Consultar</h4>
            </div>
          </div>
          {isSearch ? (
            <>
              <div className="search__menu">
                <div className="search__by">
                  <div className="search__by__contain">
                    <p className="tagSearch bold">Buscar Por</p>
                    <div className="radio_input_clientes">
                      <input
                        className="inputRadio"
                        type="radio"
                        id="Nombre"
                        name="searchby"
                        value={searchBy}
                        checked={searchBy === "Nombre"}
                        onClick={() => setSearchBy("Nombre")}
                      />
                      <label for="contactChoice1">Nombre Contacto</label>
                    </div>
                    <div className="radio_input_clientes">
                      <input
                        className="inputRadio"
                        type="radio"
                        id="Empresa"
                        name="searchby"
                        value={searchBy}
                        checked={searchBy === "Empresa-Nit"}
                        onClick={() => setSearchBy("Empresa-Nit")}
                      />
                      <label for="contactChoice1">Empresa-Nit</label>

                      <div className="radio_input_clientes">
                        <input
                          className="inputRadio"
                          type="radio"
                          id="Empresa"
                          name="searchby"
                          value={searchBy}
                          checked={searchBy === "Empresa-Nombre"}
                          onClick={() => setSearchBy("Empresa-Nombre")}
                        />
                        <label for="contactChoice1">Empresa-Nombre</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search__input">
                  <p className="tagSearch bold ">
                    Seleccione{" "}
                    {searchBy == "Nombre"
                      ? "Nombre"
                      : searchBy === "Empresa-Nit"
                      ? "Nit"
                      : "Nombre Empresa"}
                  </p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleccione..."
                    isLoading={isLoading}
                    isSearchable={true}
                    options={
                      searchBy == "Nombre"
                        ? responseQuotes?.quotes
                        : searchBy === "Empresa-Nit"
                        ? responseQuotes?.nit
                        : responseQuotes?.names
                    }
                    styles={colourStyles}
                    onChange={(value) => {
                      getQuery2(value.value);
                    }}
                  />

                  {searchBy === "Nombre" ? null : (
                    <>
                      <p
                        className="tagSearch bold "
                        style={{ marginTop: "10px" }}
                      >
                        Seleccione Contacto
                      </p>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Seleccione..."
                        isLoading={isLoading}
                        isSearchable={true}
                        name="color"
                        options={responseQuotes2?.quotes}
                        styles={colourStyles}
                        onChange={(value) => {
                          setQuery(value.value);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <button className="btn-userconfig" onClick={() => fetchQuery()}>
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  "Buscar"
                )}
              </button>
            </>
          ) : null}
        </div>
      </Route>

      <Route path={`${path}/edit_contact`}>
        <EditContact />
      </Route>
      <Route path={`${path}/contactadd`}>
        <ContactAdd />
      </Route>
    </Switch>
  );
}

export default Contacts;
