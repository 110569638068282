import { faMortarPestle } from "@fortawesome/free-solid-svg-icons";
import React from "react";

function Departments() {
  return (
    <>
      <option value="Antioquia">Antioquia</option>
      <option value="Boyacá">Boyacá</option>
      <option value="Córdoba">Córdoba</option>
      <option value="Chocó">Chocó</option>
      <option value="Nariño">Nariño</option>
      <option value="Santander">Santander</option>
      <option value="Atlántico">Atlántico</option>
      <option value="Bolívar">Bolívar</option>
      <option value="Caldas">Caldas</option>
      <option value="Caquetá">Caquetá</option>
      <option value="Cauca">Cauca</option>
      <option value="Cesar">Cesar</option>
      <option value="Cundinamarca">Cundinamarca</option>
      <option value="Huila">Huila</option>
      <option value="La Guajira">La Guajira</option>
      <option value="Magdalena">Magdalena</option>
      <option value="Meta">Meta</option>
      <option value="Quindío">Quindío</option>
      <option value="Risaralda">Risaralda</option>
      <option value="Sucre">Sucre</option>
      <option value="Tolima">Tolima</option>
      <option value="Arauca">Arauca</option>
      <option value="Casanare">Casanare</option>
      <option value="Putumayo">Putumayo</option>
      <option value="Amazonas">Amazonas</option>
      <option value="Guainía">Guainía</option>
      <option value="Vaupés">Vaupés</option>
      <option value="Vichada">Vichada</option>
      <option value="Guaviare">Guaviare</option>
      <option value="Archipiélago de San Andrés, Providencia y Santa Catalina">
        Archipiélago de San Andrés, Providencia y Santa Catalina
      </option>
      <option value="Bogotá D.C.">Bogotá D.C.</option>
      <option value="Norte de Santander">Norte de Santander</option>
      <option value="Valle del Cauca">Valle del Cauca</option>
    </>
  );
}

function Mpos(dpto) {
  const mpos = {
    Antioquia: [
      "Medellín",
      "Abejorral",
      "Abriaquí",
      "Alejandría",
      "Amagá",
      "Amalfi",
      "Andes",
      "Angelópolis",
      "Angostura",
      "Anorí",
      "Anza",
      "Apartadó",
      "Arboletes",
      "Argelia",
      "Armenia",
      "Barbosa",
      "Bello",
      "Betania",
      "Betulia",
      "Ciudad Bolívar",
      "Briceño",
      "Buriticá",
      "Cáceres",
      "Caicedo",
      "Caldas",
      "Campamento",
      "Cañasgordas",
      "Caracolí",
      "Caramanta",
      "Carepa",
      "Carolina",
      "Caucasia",
      "Chigorodó",
      "Cisneros",
      "Cocorná",
      "Concepción",
      "Concordia",
      "Copacabana",
      "Dabeiba",
      "Don Matías",
      "Ebéjico",
      "El Bagre",
      "Entrerrios",
      "Envigado",
      "Fredonia",
      "Giraldo",
      "Girardota",
      "Gómez Plata",
      "Guadalupe",
      "Guarne",
      "Guatapé",
      "Heliconia",
      "Hispania",
      "Itagui",
      "Ituango",
      "Belmira",
      "Jericó",
      "La Ceja",
      "La Estrella",
      "La Pintada",
      "La Unión",
      "Liborina",
      "Maceo",
      "Marinilla",
      "Montebello",
      "Murindó",
      "Mutatá",
      "Nariño",
      "Necoclí",
      "Nechí",
      "Olaya",
      "Peñol",
      "Peque",
      "Pueblorrico",
      "Puerto Berrío",
      "Puerto Nare",
      "Puerto Triunfo",
      "Remedios",
      "Retiro",
      "Rionegro",
      "Sabanalarga",
      "Sabaneta",
      "Salgar",
      "San Francisco",
      "San Jerónimo",
      "San Luis",
      "San Pedro",
      "San Rafael",
      "San Roque",
      "San Vicente",
      "Santa Bárbara",
      "Santo Domingo",
      "El Santuario",
      "Segovia",
      "Sopetrán",
      "Támesis",
      "Tarazá",
      "Tarso",
      "Titiribí",
      "Toledo",
      "Turbo",
      "Uramita",
      "Urrao",
      "Valdivia",
      "Valparaíso",
      "Vegachí",
      "Venecia",
      "Yalí",
      "Yarumal",
      "Yolombó",
      "Yondó",
      "Zaragoza",
      "San Pedro de Uraba",
      "Santafé de Antioquia",
      "Santa Rosa de Osos",
      "San Andrés de Cuerquía",
      "Vigía del Fuerte",
      "San José de La Montaña",
      "San Juan de Urabá",
      "El Carmen de Viboral",
      "San Carlos",
      "Frontino",
      "Granada",
      "Jardín",
      "Sonsón",
    ],
    Boyacá: [
      "Tununguá",
      "Motavita",
      "Ciénega",
      "Tunja",
      "Almeida",
      "Aquitania",
      "Arcabuco",
      "Berbeo",
      "Betéitiva",
      "Boavita",
      "Boyacá",
      "Briceño",
      "Buena Vista",
      "Busbanzá",
      "Caldas",
      "Campohermoso",
      "Cerinza",
      "Chinavita",
      "Chiquinquirá",
      "Chiscas",
      "Chita",
      "Chitaraque",
      "Chivatá",
      "Cómbita",
      "Coper",
      "Corrales",
      "Covarachía",
      "Cubará",
      "Cucaita",
      "Cuítiva",
      "Chíquiza",
      "Chivor",
      "Duitama",
      "El Cocuy",
      "El Espino",
      "Firavitoba",
      "Floresta",
      "Gachantivá",
      "Gameza",
      "Garagoa",
      "Guacamayas",
      "Guateque",
      "Guayatá",
      "Güicán",
      "Iza",
      "Jenesano",
      "Jericó",
      "Labranzagrande",
      "La Capilla",
      "La Victoria",
      "Macanal",
      "Maripí",
      "Miraflores",
      "Mongua",
      "Monguí",
      "Moniquirá",
      "Muzo",
      "Nobsa",
      "Nuevo Colón",
      "Oicatá",
      "Otanche",
      "Pachavita",
      "Páez",
      "Paipa",
      "Pajarito",
      "Panqueba",
      "Pauna",
      "Paya",
      "Pesca",
      "Pisba",
      "Puerto Boyacá",
      "Quípama",
      "Ramiriquí",
      "Ráquira",
      "Rondón",
      "Saboyá",
      "Sáchica",
      "Samacá",
      "San Eduardo",
      "San Mateo",
      "Santana",
      "Santa María",
      "Santa Sofía",
      "Sativanorte",
      "Sativasur",
      "Siachoque",
      "Soatá",
      "Socotá",
      "Socha",
      "Sogamoso",
      "Somondoco",
      "Sora",
      "Sotaquirá",
      "Soracá",
      "Susacón",
      "Sutamarchán",
      "Sutatenza",
      "Tasco",
      "Tenza",
      "Tibaná",
      "Tinjacá",
      "Tipacoque",
      "Toca",
      "Tópaga",
      "Tota",
      "Turmequé",
      "Tutazá",
      "Umbita",
      "Ventaquemada",
      "Viracachá",
      "Zetaquira",
      "Togüí",
      "Villa de Leyva",
      "Paz de Río",
      "Santa Rosa de Viterbo",
      "San Pablo de Borbur",
      "San Luis de Gaceno",
      "San José de Pare",
      "San Miguel de Sema",
      "Tuta",
      "Tibasosa",
      "La Uvita",
      "Belén",
    ],
    Córdoba: [
      "San Bernardo del Viento",
      "Montería",
      "Ayapel",
      "Buenavista",
      "Canalete",
      "Cereté",
      "Chimá",
      "Chinú",
      "Cotorra",
      "Lorica",
      "Los Córdobas",
      "Momil",
      "Moñitos",
      "Planeta Rica",
      "Pueblo Nuevo",
      "Puerto Escondido",
      "Purísima",
      "Sahagún",
      "San Andrés Sotavento",
      "San Antero",
      "San Pelayo",
      "Tierralta",
      "Tuchín",
      "Valencia",
      "San José de Uré",
      "Ciénaga de Oro",
      "San Carlos",
      "Montelíbano",
      "La Apartada",
      "Puerto Libertador",
    ],
    Chocó: [
      "Istmina",
      "Quibdó",
      "Acandí",
      "Alto Baudo",
      "Atrato",
      "Bagadó",
      "Bahía Solano",
      "Bajo Baudó",
      "Bojaya",
      "Cértegui",
      "Condoto",
      "Juradó",
      "Lloró",
      "Medio Atrato",
      "Medio Baudó",
      "Medio San Juan",
      "Nóvita",
      "Nuquí",
      "Río Iro",
      "Río Quito",
      "Riosucio",
      "Sipí",
      "Unguía",
      "El Litoral del San Juan",
      "El Cantón del San Pablo",
      "El Carmen de Atrato",
      "San José del Palmar",
      "Belén de Bajira",
      "Carmen del Darien",
      "Tadó",
      "Unión Panamericana",
    ],
    Nariño: [
      "Santacruz",
      "Pasto",
      "Albán",
      "Aldana",
      "Ancuyá",
      "Barbacoas",
      "Colón",
      "Consaca",
      "Contadero",
      "Córdoba",
      "Cuaspud",
      "Cumbal",
      "Cumbitara",
      "El Charco",
      "El Peñol",
      "El Rosario",
      "El Tambo",
      "Funes",
      "Guachucal",
      "Guaitarilla",
      "Gualmatán",
      "Iles",
      "Imués",
      "Ipiales",
      "La Cruz",
      "La Florida",
      "La Llanada",
      "La Tola",
      "La Unión",
      "Leiva",
      "Linares",
      "Los Andes",
      "Magüí",
      "Mallama",
      "Mosquera",
      "Nariño",
      "Olaya Herrera",
      "Ospina",
      "Francisco Pizarro",
      "Policarpa",
      "Potosí",
      "Providencia",
      "Puerres",
      "Pupiales",
      "Ricaurte",
      "Roberto Payán",
      "Samaniego",
      "Sandoná",
      "San Bernardo",
      "San Lorenzo",
      "San Pablo",
      "Santa Bárbara",
      "Sapuyes",
      "Taminango",
      "Tangua",
      "Túquerres",
      "Yacuanquer",
      "San Pedro de Cartago",
      "El Tablón de Gómez",
      "Buesaco",
      "San Andrés de Tumaco",
      "Belén",
      "Chachagüí",
      "Arboleda",
    ],
    Santander: [
      "Puerto Wilches",
      "Puerto Parra",
      "Bucaramanga",
      "Aguada",
      "Albania",
      "Aratoca",
      "Barbosa",
      "Barichara",
      "Barrancabermeja",
      "Betulia",
      "Bolívar",
      "Cabrera",
      "California",
      "Carcasí",
      "Cepitá",
      "Cerrito",
      "Charalá",
      "Charta",
      "Chipatá",
      "Cimitarra",
      "Concepción",
      "Confines",
      "Contratación",
      "Coromoro",
      "Curití",
      "El Guacamayo",
      "El Playón",
      "Encino",
      "Enciso",
      "Florián",
      "Floridablanca",
      "Galán",
      "Gambita",
      "Girón",
      "Guaca",
      "Guadalupe",
      "Guapotá",
      "Guavatá",
      "Güepsa",
      "Jesús María",
      "Jordán",
      "La Belleza",
      "Landázuri",
      "La Paz",
      "Lebríja",
      "Los Santos",
      "Macaravita",
      "Málaga",
      "Matanza",
      "Mogotes",
      "Molagavita",
      "Ocamonte",
      "Oiba",
      "Onzaga",
      "Palmar",
      "Páramo",
      "Piedecuesta",
      "Pinchote",
      "Puente Nacional",
      "Rionegro",
      "San Andrés",
      "San Gil",
      "San Joaquín",
      "San Miguel",
      "Santa Bárbara",
      "Simacota",
      "Socorro",
      "Suaita",
      "Sucre",
      "Suratá",
      "Tona",
      "Vélez",
      "Vetas",
      "Villanueva",
      "Zapatoca",
      "Palmas del Socorro",
      "San Vicente de Chucurí",
      "San José de Miranda",
      "Santa Helena del Opón",
      "Sabana de Torres",
      "El Carmen de Chucurí",
      "Valle de San José",
      "San Benito",
      "Hato",
      "Chimá",
      "Capitanejo",
      "El Peñón",
    ],
    Atlántico: [
      "Barranquilla",
      "Baranoa",
      "Candelaria",
      "Galapa",
      "Luruaco",
      "Malambo",
      "Manatí",
      "Piojó",
      "Polonuevo",
      "Sabanagrande",
      "Sabanalarga",
      "Santa Lucía",
      "Santo Tomás",
      "Soledad",
      "Suan",
      "Tubará",
      "Usiacurí",
      "Juan de Acosta",
      "Palmar de Varela",
      "Campo de La Cruz",
      "Repelón",
      "Puerto Colombia",
      "Ponedera",
    ],
    Bolívar: [
      "Achí",
      "Arenal",
      "Arjona",
      "Arroyohondo",
      "Calamar",
      "Cantagallo",
      "Cicuco",
      "Córdoba",
      "Clemencia",
      "El Guamo",
      "Magangué",
      "Mahates",
      "Margarita",
      "Montecristo",
      "Mompós",
      "Morales",
      "Norosí",
      "Pinillos",
      "Regidor",
      "Río Viejo",
      "San Estanislao",
      "San Fernando",
      "San Juan Nepomuceno",
      "Santa Catalina",
      "Santa Rosa",
      "Simití",
      "Soplaviento",
      "Talaigua Nuevo",
      "Tiquisio",
      "Turbaco",
      "Turbaná",
      "Villanueva",
      "Barranco de Loba",
      "Santa Rosa del Sur",
      "Hatillo de Loba",
      "El Carmen de Bolívar",
      "San Martín de Loba",
      "Altos del Rosario",
      "San Jacinto del Cauca",
      "San Pablo de Borbur",
      "San Jacinto",
      "El Peñón",
      "Cartagena",
      "María la Baja",
      "San Cristóbal",
      "Zambrano",
    ],
    Caldas: [
      "Manizales",
      "Aguadas",
      "Anserma",
      "Aranzazu",
      "Belalcázar",
      "Chinchiná",
      "Filadelfia",
      "La Dorada",
      "La Merced",
      "Manzanares",
      "Marmato",
      "Marulanda",
      "Neira",
      "Norcasia",
      "Pácora",
      "Palestina",
      "Pensilvania",
      "Riosucio",
      "Risaralda",
      "Salamina",
      "Samaná",
      "San José",
      "Supía",
      "Victoria",
      "Villamaría",
      "Viterbo",
      "Marquetalia",
    ],
    Caquetá: [
      "Florencia",
      "Albania",
      "Curillo",
      "El Doncello",
      "El Paujil",
      "Morelia",
      "Puerto Rico",
      "Solano",
      "Solita",
      "Valparaíso",
      "San José del Fragua",
      "Belén de Los Andaquies",
      "Cartagena del Chairá",
      "Milán",
      "La Montañita",
      "San Vicente del Caguán",
    ],
    Cauca: [
      "Popayán",
      "Almaguer",
      "Argelia",
      "Balboa",
      "Bolívar",
      "Buenos Aires",
      "Cajibío",
      "Caldono",
      "Caloto",
      "Corinto",
      "El Tambo",
      "Florencia",
      "Guachené",
      "Guapi",
      "Inzá",
      "Jambaló",
      "La Sierra",
      "La Vega",
      "López",
      "Mercaderes",
      "Miranda",
      "Morales",
      "Padilla",
      "Patía",
      "Piamonte",
      "Piendamó",
      "Puerto Tejada",
      "Puracé",
      "Rosas",
      "Santa Rosa",
      "Silvia",
      "Sotara",
      "Suárez",
      "Sucre",
      "Timbío",
      "Timbiquí",
      "Toribio",
      "Totoró",
      "Villa Rica",
      "Santander de Quilichao",
      "San Sebastián",
      "Páez",
    ],
    Cesar: [
      "Valledupar",
      "Aguachica",
      "Agustín Codazzi",
      "Astrea",
      "Becerril",
      "Bosconia",
      "Chimichagua",
      "Chiriguaná",
      "Curumaní",
      "El Copey",
      "El Paso",
      "Gamarra",
      "González",
      "La Gloria",
      "Manaure",
      "Pailitas",
      "Pelaya",
      "Pueblo Bello",
      "La Paz",
      "San Alberto",
      "San Diego",
      "San Martín",
      "Tamalameque",
      "Río de Oro",
      "La Jagua de Ibirico",
    ],
    Cundinamarca: [
      "Anapoima",
      "Arbeláez",
      "Beltrán",
      "Bituima",
      "Bojacá",
      "Cabrera",
      "Cachipay",
      "Cajicá",
      "Caparrapí",
      "Caqueza",
      "Chaguaní",
      "Chipaque",
      "Choachí",
      "Chocontá",
      "Cogua",
      "Cota",
      "Cucunubá",
      "El Colegio",
      "El Rosal",
      "Fomeque",
      "Fosca",
      "Funza",
      "Fúquene",
      "Gachala",
      "Gachancipá",
      "Gachetá",
      "Girardot",
      "Granada",
      "Guachetá",
      "Guaduas",
      "Guasca",
      "Guataquí",
      "Guatavita",
      "Guayabetal",
      "Gutiérrez",
      "Jerusalén",
      "Junín",
      "La Calera",
      "La Mesa",
      "La Palma",
      "La Peña",
      "La Vega",
      "Lenguazaque",
      "Macheta",
      "Madrid",
      "Manta",
      "Medina",
      "Mosquera",
      "Nariño",
      "Nemocón",
      "Nilo",
      "Nimaima",
      "Nocaima",
      "Venecia",
      "Pacho",
      "Paime",
      "Pandi",
      "Paratebueno",
      "Pasca",
      "Puerto Salgar",
      "Pulí",
      "Quebradanegra",
      "Quetame",
      "Quipile",
      "Apulo",
      "Ricaurte",
      "San Bernardo",
      "San Cayetano",
      "San Francisco",
      "Sesquilé",
      "Sibaté",
      "Silvania",
      "Simijaca",
      "Soacha",
      "Subachoque",
      "Suesca",
      "Supatá",
      "Susa",
      "Sutatausa",
      "Tabio",
      "Tausa",
      "Tena",
      "Tenjo",
      "Tibacuy",
      "Tibirita",
      "Tocaima",
      "Tocancipá",
      "Topaipí",
      "Ubalá",
      "Ubaque",
      "Une",
      "Útica",
      "Vianí",
      "Villagómez",
      "Villapinzón",
      "Villeta",
      "Viotá",
      "Zipacón",
      "San Juan de Río Seco",
      "Villa de San Diego de Ubate",
      "Guayabal de Siquima",
      "San Antonio del Tequendama",
      "Agua de Dios",
      "Carmen de Carupa",
      "Vergara",
      "Albán",
      "Anolaima",
      "Chía",
      "El Peñón",
      "Sopó",
      "Gama",
      "Sasaima",
      "Yacopí",
      "Fusagasugá",
      "Zipaquirá",
      "Facatativá",
    ],
    Huila: [
      "Neiva",
      "Acevedo",
      "Agrado",
      "Aipe",
      "Algeciras",
      "Altamira",
      "Baraya",
      "Campoalegre",
      "Colombia",
      "Elías",
      "Garzón",
      "Gigante",
      "Guadalupe",
      "Hobo",
      "Iquira",
      "Isnos",
      "La Argentina",
      "La Plata",
      "Nátaga",
      "Oporapa",
      "Paicol",
      "Palermo",
      "Palestina",
      "Pital",
      "Pitalito",
      "Rivera",
      "Saladoblanco",
      "Santa María",
      "Suaza",
      "Tarqui",
      "Tesalia",
      "Tello",
      "Teruel",
      "Timaná",
      "Villavieja",
      "Yaguará",
      "San Agustín",
    ],
    "La Guajira": [
      "Riohacha",
      "Albania",
      "Barrancas",
      "Dibula",
      "Distracción",
      "El Molino",
      "Fonseca",
      "Hatonuevo",
      "Maicao",
      "Manaure",
      "Uribia",
      "Urumita",
      "Villanueva",
      "La Jagua del Pilar",
      "San Juan del Cesar",
    ],
    Magdalena: [
      "Santa Marta",
      "Algarrobo",
      "Aracataca",
      "Ariguaní",
      "Cerro San Antonio",
      "Chivolo",
      "Concordia",
      "El Banco",
      "El Piñon",
      "El Retén",
      "Fundación",
      "Guamal",
      "Nueva Granada",
      "Pedraza",
      "Pivijay",
      "Plato",
      "Remolino",
      "Salamina",
      "San Zenón",
      "Santa Ana",
      "Sitionuevo",
      "Tenerife",
      "Zapayán",
      "Zona Bananera",
      "San Sebastián de Buenavista",
      "Sabanas de San Angel",
      "Pijiño del Carmen",
      "Santa Bárbara de Pinto",
      "Pueblo Viejo",
      "Ciénaga",
    ],
    Meta: [
      "Villavicencio",
      "Acacias",
      "Cabuyaro",
      "Cubarral",
      "Cumaral",
      "El Calvario",
      "El Castillo",
      "El Dorado",
      "Granada",
      "Guamal",
      "Mapiripán",
      "Mesetas",
      "La Macarena",
      "Uribe",
      "Lejanías",
      "Puerto Concordia",
      "Puerto Gaitán",
      "Puerto López",
      "Puerto Lleras",
      "Puerto Rico",
      "Restrepo",
      "San Juanito",
      "San Martín",
      "Vista Hermosa",
      "Barranca de Upía",
      "Fuente de Oro",
      "San Carlos de Guaroa",
      "San Juan de Arama",
      "Castilla la Nueva",
    ],
    Quindío: [
      "Armenia",
      "Buenavista",
      "Circasia",
      "Córdoba",
      "Filandia",
      "La Tebaida",
      "Montenegro",
      "Pijao",
      "Quimbaya",
      "Salento",
      "Calarcá",
      "Génova",
    ],
    Risaralda: [
      "Pereira",
      "Apía",
      "Balboa",
      "Dosquebradas",
      "Guática",
      "La Celia",
      "La Virginia",
      "Marsella",
      "Mistrató",
      "Pueblo Rico",
      "Quinchía",
      "Santuario",
      "Santa Rosa de Cabal",
      "Belén de Umbría",
    ],
    Sucre: [
      "Sincelejo",
      "Buenavista",
      "Caimito",
      "Coloso",
      "Coveñas",
      "Chalán",
      "El Roble",
      "Galeras",
      "Guaranda",
      "La Unión",
      "Los Palmitos",
      "Majagual",
      "Morroa",
      "Ovejas",
      "Palmito",
      "San Benito Abad",
      "San Marcos",
      "San Onofre",
      "San Pedro",
      "Sucre",
      "Tolú Viejo",
      "San Luis de Sincé",
      "San Juan de Betulia",
      "Santiago de Tolú",
      "Sampués",
      "Corozal",
    ],
    Tolima: [
      "Alpujarra",
      "Alvarado",
      "Ambalema",
      "Armero",
      "Ataco",
      "Cajamarca",
      "Chaparral",
      "Coello",
      "Coyaima",
      "Cunday",
      "Dolores",
      "Espinal",
      "Falan",
      "Flandes",
      "Fresno",
      "Guamo",
      "Herveo",
      "Honda",
      "Icononzo",
      "Mariquita",
      "Melgar",
      "Murillo",
      "Natagaima",
      "Ortega",
      "Palocabildo",
      "Piedras",
      "Planadas",
      "Prado",
      "Purificación",
      "Rio Blanco",
      "Roncesvalles",
      "Rovira",
      "Saldaña",
      "Santa Isabel",
      "Venadillo",
      "Villahermosa",
      "Villarrica",
      "Valle de San Juan",
      "Carmen de Apicala",
      "San Luis",
      "San Antonio",
      "Casabianca",
      "Anzoátegui",
      "Ibagué",
      "Líbano",
      "Lérida",
      "Suárez",
    ],
    Arauca: [
      "Arauquita",
      "Cravo Norte",
      "Fortul",
      "Puerto Rondón",
      "Saravena",
      "Tame",
      "Arauca",
    ],
    Casanare: [
      "Yopal",
      "Aguazul",
      "Chámeza",
      "Hato Corozal",
      "La Salina",
      "Monterrey",
      "Pore",
      "Recetor",
      "Sabanalarga",
      "Sácama",
      "Tauramena",
      "Trinidad",
      "Villanueva",
      "San Luis de Gaceno",
      "Paz de Ariporo",
      "Nunchía",
      "Maní",
      "Támara",
      "Orocué",
    ],
    Putumayo: [
      "Mocoa",
      "Colón",
      "Orito",
      "Puerto Caicedo",
      "Puerto Guzmán",
      "Leguízamo",
      "Sibundoy",
      "San Francisco",
      "San Miguel",
      "Santiago",
      "Valle de Guamez",
      "Puerto Asís",
      "Villagarzón",
    ],
    Amazonas: [
      "Leticia",
      "El Encanto",
      "La Chorrera",
      "La Pedrera",
      "La Victoria",
      "Puerto Arica",
      "Puerto Nariño",
      "Puerto Santander",
      "Tarapacá",
      "Puerto Alegría",
      "Miriti Paraná",
    ],
    Guainía: [
      "Inírida",
      "Barranco Minas",
      "Mapiripana",
      "San Felipe",
      "Puerto Colombia",
      "La Guadalupe",
      "Cacahual",
      "Pana Pana",
      "Morichal",
    ],
    Vaupés: ["Mitú", "Caruru", "Pacoa", "Taraira", "Papunaua", "Yavaraté"],
    Vichada: ["Puerto Carreño", "La Primavera", "Santa Rosalía", "Cumaribo"],
    Guaviare: ["Calamar", "San José del Guaviare", "Miraflores", "El Retorno"],
    "Archipiélago de San Andrés": [
      " Providencia y Santa Catalina",
      " Providencia y Santa Catalina",
    ],
    "Bogotá D.C.": ["Bogotá D.C."],
    "Norte de Santander": [
      "Silos",
      "Cácota",
      "Toledo",
      "Mutiscua",
      "El Zulia",
      "Salazar",
      "Cucutilla",
      "Puerto Santander",
      "Gramalote",
      "El Tarra",
      "Teorama",
      "Arboledas",
      "Lourdes",
      "Bochalema",
      "Convención",
      "Hacarí",
      "Herrán",
      "Tibú",
      "San Cayetano",
      "San Calixto",
      "La Playa",
      "Chinácota",
      "Ragonvalia",
      "La Esperanza",
      "Villa del Rosario",
      "Chitagá",
      "Sardinata",
      "Abrego",
      "Los Patios",
      "Ocaña",
      "Bucarasica",
      "Santiago",
      "Labateca",
      "Cachirá",
      "Villa Caro",
      "Durania",
      "Pamplona",
      "Pamplonita",
      "Cúcuta",
      "El Carmen",
    ],
    "Valle del Cauca": [
      "El Dovio",
      "Roldanillo",
      "Argelia",
      "Sevilla",
      "Zarzal",
      "El Cerrito",
      "Cartago",
      "Caicedonia",
      "El Cairo",
      "La Unión",
      "Restrepo",
      "Dagua",
      "Guacarí",
      "Ansermanuevo",
      "Bugalagrande",
      "La Victoria",
      "Ginebra",
      "Yumbo",
      "Obando",
      "Bolívar",
      "Cali",
      "San Pedro",
      "Guadalajara de Buga",
      "Calima",
      "Andalucía",
      "Pradera",
      "Yotoco",
      "Palmira",
      "Riofrío",
      "Alcalá",
      "Versalles",
      "El Águila",
      "Toro",
      "Candelaria",
      "La Cumbre",
      "Ulloa",
      "Trujillo",
      "Vijes",
      "Tuluá",
      "Florida",
      "Jamundí",
      "Buenaventura",
    ],
  };
  return mpos[dpto];
}

function Industries() {
  return (
    <>
      <option value="MINERIA">MINERIA</option>
      <option value="ENERGETICA">ENERGÉTICA</option>
      <option value="CONSTRUCCION">CONSTRUCCIÓN</option>
      <option value="INGENIERIA">INGENIERÍA</option>
      <option value="MANUFACTURA">MANUFACTURA</option>
      <option value="AGRICULTURA">AGRICULTURA</option>
      <option value="PRIVADO">PRIVADO</option>
      <option value="EDUCACIÓN">EDUCACIÓN</option>
      <option value="PUBLICO-ESTATAL">PUBLICO-ESTATAL</option>
      <option value="TRANSPORTE">TRANSPORTE</option>
      <option value="ALIMENTOS">ALIMENTOS</option>
      <option value="COMUNICACIONES">COMUNICACIONES</option>
      <option value="COMERCIO MAQUINAS Y EQUIPOS">
        COMERCIO MAQUINAS Y EQUIPOS
      </option>
      <option value="SALUD">SALUD</option>
    </>
  );
}

function Cities(props) {
  return (
    <datalist id={props.id}>
            <option>Abejorral</option>
            <option>Abriaquí</option>
            <option>Acacías</option>
            <option>Aguachica</option>
            <option>Agustín Codazzi</option>
            <option>Alejandría</option>
            <option>Amagá</option>
            <option>Amalfi</option>
            <option>Andes</option>
            <option>Angelópolis</option>
            <option>Angostura</option>
            <option>Anorí</option>
            <option>Anzá</option>
            <option>Apartadó</option>
            <option>Arauca</option>
            <option>Arauquita</option>
            <option>Arboletes</option>
            <option>Arjona</option>
            <option>Armenia</option>
            <option>Baranoa</option>
            <option>Barbacoas</option>
            <option>Barbosa</option>
            <option>Barrancabermeja</option>
            <option>Barranquilla</option>
            <option>Bello</option>
            <option>Belmira</option>
            <option>Betania</option>
            <option>Betulia</option>
            <option>Bogotá</option>
            <option>Briceño</option>
            <option>Bucaramanga</option>
            <option>Buenaventura</option>
            <option>Buga</option>
            <option>Buriticá</option>
            <option>Cáceres</option>
            <option>Cajicá</option>
            <option>Calarcá</option>
            <option>Caldas</option>
            <option>Cali</option>
            <option>Campamento</option>
            <option>Candelaria</option>
            <option>Caracolí</option>
            <option>Carepa</option>
            <option>Cartagena</option>
            <option>Cartago</option>
            <option>Caucasia</option>
            <option>Cereté</option>
            <option>Chaparral</option>
            <option>Chía</option>
            <option>Chigorodó</option>
            <option>Chinchiná</option>
            <option>Chiquinquirá</option>
            <option>Ciénaga de Oro</option>
            <option>Ciénaga</option>
            <option>Cisneros</option>
            <option>Ciudad Bolívar</option>
            <option>Cocorná</option>
            <option>Concepción</option>
            <option>Copacabana</option>
            <option>Corozal</option>
            <option>Cúcuta</option>
            <option>Cumaribo</option>
            <option>Dosquebradas</option>
            <option>Duitama</option>
            <option>Ebéjico</option>
            <option>El Bagre</option>
            <option>El Banco</option>
            <option>El Carmen de Bolívar</option>
            <option>El Carmen de Viboral</option>
            <option>El Cerrito</option>
            <option>El Retiro</option>
            <option>El Tambo</option>
            <option>Envigado</option>
            <option>Espinal</option>
            <option>Facatativá</option>
            <option>Florencia</option>
            <option>Florida</option>
            <option>Floridablanca</option>
            <option>Fredonia</option>
            <option>Fundación</option>
            <option>Funza</option>
            <option>Fusagasugá</option>
            <option>Galapa</option>
            <option>Garzón</option>
            <option>Giraldo</option>
            <option>Girardot</option>
            <option>Girardota</option>
            <option>Girón</option>
            <option>Granada</option>
            <option>Guadalupe</option>
            <option>Guarne</option>
            <option>Heliconia</option>
            <option>Hispania</option>
            <option>Ibagué</option>
            <option>Ipiales</option>
            <option>Itagüí</option>
            <option>Jamundí</option>
            <option>La Ceja</option>
            <option>La Dorada</option>
            <option>La Estrella</option>
            <option>La Jagua de Ibirico</option>
            <option>La Pintada</option>
            <option>La Plata</option>
            <option>La Unión</option>
            <option>Lorica</option>
            <option>Los Patios</option>
            <option>Maceo</option>
            <option>Madrid</option>
            <option>Magangué</option>
            <option>Maicao</option>
            <option>Malambo</option>
            <option>Manaure</option>
            <option>Manizales</option>
            <option>Marinilla</option>
            <option>Medellín</option>
            <option>Mocoa</option>
            <option>Montelíbano</option>
            <option>Montería</option>
            <option>Mosquera</option>
            <option>Murindó</option>
            <option>Mutatá</option>
            <option>Nariño</option>
            <option>Nechí</option>
            <option>Necoclí</option>
            <option>Neiva</option>
            <option>Ocaña</option>
            <option>Palmira</option>
            <option>Pamplona</option>
            <option>Pasto</option>
            <option>Pereira</option>
            <option>Piedecuesta</option>
            <option>Pitalito</option>
            <option>Planeta Rica</option>
            <option>Plato</option>
            <option>Popayán</option>
            <option>Pueblorrico</option>
            <option>Puerto Asís</option>
            <option>Puerto Colombia</option>
            <option>Puerto Nare</option>
            <option>Puerto Triunfo</option>
            <option>Quibdó</option>
            <option>Riohacha</option>
            <option>Rionegro</option>
            <option>Riosucio</option>
            <option>Riosucio</option>
            <option>Sabanalarga</option>
            <option>Sabaneta</option>
            <option>Sahagún</option>
            <option>San Andrés de Cuerquia</option>
            <option>San Andrés</option>
            <option>San Francisco</option>
            <option>San Gil</option>
            <option>San José de la Montaña</option>
            <option>San José del Guaviare</option>
            <option>San Juan de Urabá</option>
            <option>San Luis</option>
            <option>San Marcos</option>
            <option>San Onofre</option>
            <option>San Pedro de Urabá</option>
            <option>San Pelayo</option>
            <option>San Rafael</option>
            <option>San Roque</option>
            <option>San Vicente del Caguán</option>
            <option>San Vicente</option>
            <option>Santa Marta</option>
            <option>Santa Rosa de Cabal</option>
            <option>Santander de Quilichao</option>
            <option>Saravena</option>
            <option>Segovia</option>
            <option>Sincelejo</option>
            <option>Soacha</option>
            <option>Sogamoso</option>
            <option>Soledad</option>
            <option>Sopetrán</option>
            <option>Támesis</option>
            <option>Tarazá</option>
            <option>Tarso</option>
            <option>Tibú</option>
            <option>Tierralta</option>
            <option>Titiribí</option>
            <option>Toledo</option>
            <option>Tuchín</option>
            <option>Tuluá</option>
            <option>Tumaco</option>
            <option>Tunja</option>
            <option>Turbaco</option>
            <option>Turbo</option>
            <option>Uramita</option>
            <option>Uribia</option>
            <option>Valledupar</option>
            <option>Valparaíso</option>
            <option>Vegachí</option>
            <option>Vigía del Fuerte</option>
            <option>Villa del Rosario</option>
            <option>Villamaría</option>
            <option>Villavicencio</option>
            <option>Yalí</option>
            <option>Yopal</option>
            <option>Yumbo</option>
            <option>Zipaquirá</option>
            <option>Zona Bananera</option>
    </datalist>
  );
}

function Month_numbers() {
  return (
    <>
      <option value="">-</option>;
      {Array.from(Array(31).keys()).map((number) => (
        <option value={number}>{number}</option>
      ))}
    </>
  );
}

function Months() {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return (
    <>
      <option value="">-</option>;
      {months.map((month, index) => (
        <option value={month}>{month}</option>
      ))}
    </>
  );
}

const toCurrency = (number) => {
  const formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  return formatter.format(number);
};

var NumeroALetras = (function () {
  // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
  function Unidades(num) {
    switch (num) {
      case 1:
        return "UN";
      case 2:
        return "DOS";
      case 3:
        return "TRES";
      case 4:
        return "CUATRO";
      case 5:
        return "CINCO";
      case 6:
        return "SEIS";
      case 7:
        return "SIETE";
      case 8:
        return "OCHO";
      case 9:
        return "NUEVE";
    }

    return "";
  } //Unidades()

  function Decenas(num) {
    let decena = Math.floor(num / 10);
    let unidad = num - decena * 10;

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return "DIEZ";
          case 1:
            return "ONCE";
          case 2:
            return "DOCE";
          case 3:
            return "TRECE";
          case 4:
            return "CATORCE";
          case 5:
            return "QUINCE";
          default:
            return "DIECI" + Unidades(unidad);
        }
      case 2:
        switch (unidad) {
          case 0:
            return "VEINTE";
          default:
            return "VEINTI" + Unidades(unidad);
        }
      case 3:
        return DecenasY("TREINTA", unidad);
      case 4:
        return DecenasY("CUARENTA", unidad);
      case 5:
        return DecenasY("CINCUENTA", unidad);
      case 6:
        return DecenasY("SESENTA", unidad);
      case 7:
        return DecenasY("SETENTA", unidad);
      case 8:
        return DecenasY("OCHENTA", unidad);
      case 9:
        return DecenasY("NOVENTA", unidad);
      case 0:
        return Unidades(unidad);
    }
  } //Unidades()

  function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0) return strSin + " Y " + Unidades(numUnidades);

    return strSin;
  } //DecenasY()

  function Centenas(num) {
    let centenas = Math.floor(num / 100);
    let decenas = num - centenas * 100;

    switch (centenas) {
      case 1:
        if (decenas > 0) return "CIENTO " + Decenas(decenas);
        return "CIEN";
      case 2:
        return "DOSCIENTOS " + Decenas(decenas);
      case 3:
        return "TRESCIENTOS " + Decenas(decenas);
      case 4:
        return "CUATROCIENTOS " + Decenas(decenas);
      case 5:
        return "QUINIENTOS " + Decenas(decenas);
      case 6:
        return "SEISCIENTOS " + Decenas(decenas);
      case 7:
        return "SETECIENTOS " + Decenas(decenas);
      case 8:
        return "OCHOCIENTOS " + Decenas(decenas);
      case 9:
        return "NOVECIENTOS " + Decenas(decenas);
    }

    return Decenas(decenas);
  } //Centenas()

  function Seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let letras = "";
    if (cientos > 0)
      if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
      else letras = strSingular;

    if (resto > 0) {
      letras += "";
    } else if (resto === 0) {
      letras += "";
    }
    return letras;
  } //Seccion()

  function Miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMiles = Seccion(num, divisor, "MIL", "MIL");
    let strCentenas = Centenas(resto);

    if (strMiles == "") return strCentenas;

    return strMiles + " " + strCentenas;
  } //Miles()

  function Millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMillones = Seccion(num, divisor, "UN MILLON", "MILLONES");
    let strMiles = Miles(resto);

    if (strMillones == "") return strMiles;

    if (Number.isInteger(num / divisor)) return strMillones + " DE" + strMiles;
    else return strMillones + " " + strMiles;
  } //Millones()

  return function NumeroALetras(num, currency) {
    currency = currency || {};
    let data = {
      numero: num,
      enteros: Math.floor(num),
      centavos: Math.round(num * 100) - Math.floor(num) * 100,
      letrasCentavos: "",
      letrasMonedaPlural: currency.plural || "PESOS", //'PESOS', 'Dólares', 'Bolívares', 'etcs'
      letrasMonedaSingular: currency.singular || "PESO", //'PESO', 'Dólar', 'Bolivar', 'etc'
      letrasMonedaCentavoPlural: currency.centPlural || "CENTAVOS",
      letrasMonedaCentavoSingular: currency.centSingular || "CENTAVO",
    };

    if (data.centavos > 0) {
      data.letrasCentavos =
        "CON " +
        (function () {
          if (data.centavos == 1)
            return (
              Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular
            );
          else
            return (
              Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural
            );
        })();
    }

    if (data.enteros == 0)
      return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros == 1)
      return (
        Millones(data.enteros) +
        " " +
        data.letrasMonedaSingular +
        " " +
        data.letrasCentavos
      );
    else
      return (
        Millones(data.enteros) +
        " " +
        data.letrasMonedaPlural +
        " " +
        data.letrasCentavos
      );
  };
})();

export {
  Cities,
  Industries,
  Month_numbers,
  Months,
  NumeroALetras,
  toCurrency,
  Departments,
  Mpos,
};
