import React, { useEffect } from "react";
import "./App.css";
import "../shared/common_css.css";
import Routes from "../../router/Routes";
import Cookies from "universal-cookie";
import { CookiesProvider } from "react-cookie";
import { DataLayer } from "../../reducer/DataLayer";
import reducer, { initialState } from "../../reducer/reducer";
import { BrowserRouter as Router } from "react-router-dom";

// global.url_back = global.url_back = "http://34.218.131.134:8000/api";
global.url_back = "https://api.contenedoresantioquiaapi.com/api";
global.cancel_rut = true;

function App() {
  return (
    <CookiesProvider>
      <DataLayer initialState={initialState} reducer={reducer}>
        <Router>
          <Routes />
        </Router>
      </DataLayer>
    </CookiesProvider>
  );
}

export default App;
