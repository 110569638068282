import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import "./Modals.css";

function ModalEditAdd({
  open,
  initValuesModal,
  titleModal,
  OnClose,
  onSave,
  products,
}) {
  const [formValues, setFormValues] = useState({});
  const [productsPerType, setProductsPerType] = useState([]);

  const filter_products_per_type = (filter_value) => {
    const products_type = products.filter((obj) => obj.type === filter_value);
    products_type[0].related_type_products.push({
      id: "None",
      label: "Proyecto",
    });
    setProductsPerType(products_type[0]?.related_type_products);
  };

  useEffect(() => {
    setFormValues(initValuesModal);
    if (initValuesModal.tipo) {
      filter_products_per_type(initValuesModal.tipo);
    }

    if (
      initValuesModal.tipo != "Mobiliario" &&
      initValuesModal.tipo != "Otro" &&
      initValuesModal.id
    ) {
      const ref = initValuesModal.ref_contenedor.split(" ");
      initValuesModal.ref1 = ref[0];
      initValuesModal.ref2 = ref[1];
      initValuesModal.ref3 = ref[3];
    }
  }, []);

  const sendForm = (e) => {
    e.preventDefault();
    let values = { ...formValues };
    if (values.tipo != "Mobiliario" && values.tipo != "Otro") {
      values.ref_contenedor = `${values.ref1} ${values.ref2} - ${values.ref3}`;
    }
    values.ubicacion = "Girardota";
    values.mantenimiento = "";
    values.color = "red";

    console.log(JSON.stringify(values));
    onSave(values);
    OnClose();
  };

  const ChangeInput = (event) => {
    var { name, value } = event.target;
    if (name == "ingresa_planta") {
      value = JSON.parse(value);
    }

    if (name == "tipo") {
      filter_products_per_type(value);
      setFormValues({ ...formValues, [name]: value, producto: "" });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => OnClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h3>{titleModal}</h3>
        </DialogTitle>
        <form onSubmit={(e) => sendForm(e)}>
          <DialogContent>
            <div className="modal_edit_add_container">
              <div className="flex_label_input">
                <p className="label_input_modal">Fecha Ingreso</p>
                <div style={{ flex: 0.6 }}>
                  <input
                    type="date"
                    className="input_modal"
                    required
                    onChange={(e) => ChangeInput(e)}
                    name="fecha_ingreso"
                    value={formValues.fecha_ingreso}
                  />
                </div>
              </div>

              <div className="flex_label_input">
                <p className="label_input_modal">Tipo</p>
                <div style={{ flex: 0.6 }}>
                  <select
                    type="text"
                    className="select_modal"
                    required
                    onChange={(e) => ChangeInput(e)}
                    name="tipo"
                    value={formValues.tipo}
                  >
                    <option value="">-</option>
                    {products.map((item) => (
                      <option value={item.type}>{item.type}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex_label_input">
                <p className="label_input_modal">Producto</p>
                <div style={{ flex: 0.6 }}>
                  <select
                    type="text"
                    className="select_modal"
                    required
                    onChange={(e) => ChangeInput(e)}
                    name="producto"
                    value={formValues.producto}
                  >
                    <option value="">-</option>
                    {productsPerType?.map((item) => (
                      <option value={item.label}>{item.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex_label_input">
                <p className="label_input_modal">Referencia</p>
                {formValues.tipo != "Mobiliario" &&
                formValues.tipo != "Otro" ? (
                  <div className="container_inputs_ref">
                    <input
                      type="text"
                      className="input_ref"
                      style={{ flex: 0.35 }}
                      required
                      onChange={(e) => ChangeInput(e)}
                      name="ref1"
                      value={formValues.ref1}
                      maxLength={4}
                      minLength={4}
                    />
                    <input
                      type="text"
                      className="input_ref"
                      style={{ flex: 0.45 }}
                      required
                      onChange={(e) => ChangeInput(e)}
                      name="ref2"
                      value={formValues.ref2}
                      maxLength={6}
                      minLength={6}
                    />
                    <input
                      style={{ flex: 0.1 }}
                      type="text"
                      className="input_ref"
                      required
                      onChange={(e) => ChangeInput(e)}
                      name="ref3"
                      value={formValues.ref3}
                      maxLength={5}
                      minLength={1}
                    />
                  </div>
                ) : (
                  <div style={{ flex: 0.6 }}>
                    <input
                      type="text"
                      className="input_modal"
                      required
                      onChange={(e) => ChangeInput(e)}
                      name="ref_contenedor"
                      value={formValues.ref_contenedor}
                    />
                  </div>
                )}
              </div>
              <div className="flex_label_input">
                <p className="label_input_modal">Ingresa a Planta</p>
                <div style={{ display: "flex", flex: 0.6 }}>
                  <div className="simple_flex" style={{ marginRight: "20px" }}>
                    <input
                      type="radio"
                      name="ingresa_planta"
                      value={true}
                      onClick={(e) => ChangeInput(e)}
                      required
                    />
                    <p
                      className="label_input_modal"
                      style={{
                        fontWeight: "normal",
                        marginLeft: "7px",
                        fontSize: "14px",
                      }}
                    >
                      Si
                    </p>
                  </div>
                  <div style={{ flex: 0.47 }}>
                    <div className="simple_flex">
                      <input
                        type="radio"
                        name="ingresa_planta"
                        value={false}
                        onClick={(e) => ChangeInput(e)}
                        required
                      />
                      <p
                        className="label_input_modal"
                        style={{
                          fontWeight: "normal",
                          marginLeft: "7px",
                          fontSize: "14px",
                        }}
                      >
                        No
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <button
              type="submit"
              style={{ padding: "5px 15px", fontSize: "18px" }}
            >
              Guardar
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

function ModalMaintenance({
  open,
  onClose,
  initValuesModalMaintenance,
  onSave,
}) {
  const [formValues, setFormValues] = useState({});

  const sendForm = (e) => {
    e.preventDefault();
    let values = { ...formValues };
    console.log(JSON.stringify(formValues));
    //clean form
    Object.keys(values).forEach((k) => (values[k] = ""));
    setFormValues(values);
    onClose();
  };

  const ChangeInput = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  return (
    <div>
      <Dialog
        onClose={() => onClose()}
        open={open}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle id="simple-dialog-title" style={{ margin: "0 auto" }}>
          <h3>Mantenimiento</h3>
        </DialogTitle>
        <form onSubmit={(e) => sendForm(e)}>
          <DialogContent>
            <div className="modal_maintenance_container">
              <div className="simple_flex" style={{ marginBottom: "15px" }}>
                <p className="label_input_modal">Fecha</p>
                <p className="label_input_modal">
                  {initValuesModalMaintenance.mantenimiento}
                </p>
              </div>
              <div>
                <p
                  className="label_input_modal"
                  style={{ marginBottom: "10px" }}
                >
                  Realizado
                </p>
                <div className="simple_flex" style={{ marginBottom: "10px" }}>
                  <input
                    type="Radio"
                    value="cliente"
                    name="realizado_por"
                    checked={
                      formValues.realizado_por === "cliente" ? true : false
                    }
                    onClick={(e) => ChangeInput(e)}
                    required
                  />
                  <label className="label_radio_detail_logistic">Cliente</label>
                </div>
                <div className="simple_flex" style={{ marginBottom: "15px" }}>
                  <input
                    type="Radio"
                    value="propio"
                    name="realizado_por"
                    checked={
                      formValues.realizado_por === "propio" ? true : false
                    }
                    onClick={(e) => ChangeInput(e)}
                    required
                  />
                  <label className="label_radio_detail_logistic">Propio</label>
                </div>
                <div>
                  <p className="label_input_modal">Próximo Mantenimiento</p>
                  <input
                    type="date"
                    style={{ margin: "10px 0 15px 0" }}
                    className="input_modal"
                    required
                    onChange={(e) => ChangeInput(e)}
                    name="proximo_mtto"
                    value={formValues.proximo_mtto}
                  />
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <button
              type="submit"
              style={{ padding: "5px 15px", fontSize: "18px" }}
              onClick={() =>
                onSave({ ...formValues, id: initValuesModalMaintenance.id })
              }
            >
              Guardar
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export { ModalEditAdd, ModalMaintenance };
