import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
  PDFViewer,
  BlobProvider,
  Link as LinkPDF,
} from "@react-pdf/renderer";
import Folks_Normal from "../../../App/fonts/Folks-Normal.ttf";
import Folks_Bold from "../../../App/fonts/Folks-Bold.ttf";
import cn_logo_verde_letra from "../../../App/images/cn_logo_verde_letra.png";
import qr_drive_cont from "../../../App/images/qr_drive_cont.png";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
  TableRow,
} from "@david.kucsai/react-pdf-table";

function Remision({ data_tables }) {
  Font.register({ family: "Folks", src: Folks_Normal });
  Font.register({ family: "Folks_bold", src: Folks_Bold });

  const width_tables_col1 = 0.18;
  const width_tables_col2 = 0.82;

  const styles = StyleSheet.create({
    bold: {
      fontFamily: "Folks_bold",
      fontSize: 15,
    },

    boldish: {
      fontFamily: "Folks_bold",
    },

    page: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 80,
      paddingRight: 80,
    },

    header: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "50px",
    },

    info_flex: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: "10px",
    },

    text: {
      fontFamily: "Folks",
      fontSize: 15,
      textAlign: "justify",
    },

    h1: {
      fontFamily: "Folks_bold",
      fontSize: 18,
    },

    table_style: {
      marginBottom: "20px",
    },

    tabletxt: {
      textAlign: "left",
      fontFamily: "Folks",
      padding: "3px 5px",
      fontSize: 13,
    },

    top_border: {
      width: "100%",
      height: "0.8px",
      backgroundColor: "#181818",
    },
    paragraph: {
      marginBottom: "20px",
    },
    obs: {
      position: "absolute",
      bottom: 25,
      left: 80,
    },
  });

  return (
    <Document>
      <Page size={[816, 1056]} style={styles.page} wrap={true}>
        <View style={styles.header}>
          <View>
            <Text style={styles.h1}>Contenedores de Antioquia S.A.S</Text>
            <Text style={styles.text}>KM 1 ENTRADA A GIRARDOTA</Text>
            <Text style={styles.text}>NIT: 900.167.316-4</Text>
            <Text style={styles.text}>CEL: (316) 834-06-63</Text>
          </View>
          <Image
            src={cn_logo_verde_letra}
            style={{ width: "179px", height: "105px" }}
          />
        </View>
        <View style={styles.info_flex}>
          <Text style={styles.h1}>Remisión N° {data_tables?.remision}</Text>
          <Text style={styles.h1}>TIPO: {data_tables?.tipo_remision} </Text>
        </View>
        <View style={styles.table_style}>
          <View style={styles.top_border}></View>
          <Table data={data_tables?.table1}>
            <TableBody>
              <DataTableCell
                getContent={(row) => row.col1}
                weighting={width_tables_col1}
                style={[styles.tabletxt, styles.boldish]}
              />
              <DataTableCell
                getContent={(row) => row.col2}
                weighting={width_tables_col2}
                style={styles.tabletxt}
              />
            </TableBody>
          </Table>
        </View>
        <View style={styles.table_style}>
          <View style={styles.top_border}></View>
          <Table data={data_tables?.table2}>
            <TableBody>
              <DataTableCell
                getContent={(row) => row.col1}
                weighting={width_tables_col1}
                style={[styles.tabletxt, styles.boldish]}
              />
              <DataTableCell
                getContent={(row) => row.col2}
                weighting={width_tables_col2}
                style={styles.tabletxt}
              />
            </TableBody>
          </Table>
        </View>
        <View style={styles.table_style}>
          <View style={styles.top_border}></View>
          <Table data={data_tables?.table3}>
            <TableBody>
              <DataTableCell
                getContent={(row) => row.col1}
                weighting={width_tables_col1}
                style={[styles.tabletxt, styles.boldish]}
              />
              <DataTableCell
                getContent={(row) => row.col2}
                weighting={width_tables_col2}
                style={styles.tabletxt}
              />
            </TableBody>
          </Table>
        </View>
        <View style={styles.info_flex}></View>
        <View style={[styles.info_flex, { marginTop: "30px" }]}>
          <View>
            <Text style={[styles.text, { marginBottom: "50px" }]}>
              Contenedores de Antioquia S.A.S
            </Text>
            <Text style={styles.text}>_______________________________</Text>
            <Text style={styles.text}>{data_tables.user}</Text>
          </View>
          <View>
            <Image
              src={qr_drive_cont}
              style={{ width: "100px", height: "100px", marginTop: -12 }}
            />
          </View>
          <View>
            <Text style={[styles.text, { marginBottom: "50px" }]}>
              Recibido Por:
            </Text>
            <Text style={styles.text}>_______________________________</Text>
            <Text style={styles.text}>FIRMA Y SELLO</Text>
          </View>
        </View>
        <View>
          <Text style={[styles.text, { textAlign: "center" }]}>Scanear QR</Text>
          <Text style={[styles.text, { textAlign: "center" }]}>
            Para ver videos informativos de instalación y recomendaciones
            generales.
          </Text>
        </View>
        <View style={styles.obs}>
          <Text style={styles.text}>
            OBSERVACIONES:
            _______________________________________________________________________________
          </Text>
        </View>
      </Page>

      <Page size={[816, 1056]} style={styles.page}>
        <Image
          src={cn_logo_verde_letra}
          style={{ width: "72px", height: "42px", marginBottom: "30px" }}
        />
        <View style={styles.paragraph}>
          <Text style={styles.text}>OBSERVACIONES:</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            Para garantizar el buen rendimiento de los sistemas de aire
            acondicionado recuerde tener en cuenta lo siguiente
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -No manipular, ni cambiar de sitio los equipos, en caso de requerir
            alguna modificación se debe realizar por personal idóneo y
            autorizado previamente por Contenedores de Antioquia S.A.S
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -La temperatura promedio sugerida para el confort de las personas y
            garantizar un rendimiento eficiente del equipo es entre 20°C y 24°C.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -La ventilación del equipo, botón FAN debe estar en ALTO, para que
            el aire recircule por todo el contenedor.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -Mientras el sistema de aire esté en funcionamiento mantener puertas
            y ventanas cerradas.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>MANTENIMIENTO PREVENTIVO</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            La frecuencia de mantenimiento preventivo o limpieza interna del
            equipo debe ser cada 3 meses.
          </Text>
          <Text style={styles.text}>
            En caso de que el contenedor sea una oficina en alquiler y se
            encuentre fuera del área metropolitana por un tiempo superior a 3
            meses es responsabilidad del cliente velar por el correcto
            funcionamiento del aire acondicionado garantizando la ejecución de
            los mantenimientos preventivos.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>PARA TENER EN CUENTA:</Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -Cuando el contenedor se encuentre en una zona donde exista mucho
            polvo, tierra o alguna polución se recomienda que el mantenimiento
            preventivo sea cada 2 meses, ya que los filtros tienden a acumular
            la suciedad afectando el rendimiento del equipo.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -Si el contenedor está ubicado en una zona donde la temperatura es
            alta, el proceso de enfriamiento es más lento y no producirá el
            mismo efecto que estando en una zona o lugar con un clima más
            cálido. Debido a que las laminas del contenedor se calientan.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -La condensadora no debe tener objetos o paredes obstruyendo la
            salida del aire, esto genera un recalentamiento del equipo causando
            daños severos.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -El contenedor debe estar ubicado en un terreno estable o sobre
            dados que garanticen la estabilidad del mismo, esto evita un
            desnivel en la evaporadora previniendo goteo de la misma.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -Es necesario el mantenimiento hecho por un especialista para
            limpiar en profundidad, lubricar y chequear el funcionamiento de
            todos los elementos críticos.
          </Text>
        </View>
        <View style={styles.paragraph}>
          <Text style={styles.text}>
            -Ahorra energía, apaga el equipo cuando no sea necesario su uso.
          </Text>
        </View>
      </Page>
    </Document>
  );
}

export default Remision;
