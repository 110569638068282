import React, { useEffect, useState } from "react";
import ModalReturn from "./Modal_return";
import "./Stock.css";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUndoAlt,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import TableHeader from "../../../App/components/shared/TableHeader";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { ModalEditAdd, ModalMaintenance } from "./Modals";
import {
  ModalQuestion,
  ModalFetch,
} from "../../../App/components/shared/Modals";
import Loading from "../../../App/components/Loading/Loading";
import { useCookies } from "react-cookie";

function Stock() {
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [sortAscen, setSortAscen] = useState();
  const [json_response, setJson_response] = useState();
  const [products, setProducts] = useState();
  const [backupJson_response, setBackupJson_response] = useState();
  const [clicked_column, setClicked_column] = useState("");
  const [initValuesModal, setInitValuesModal] = useState({});
  const [initValuesModalMaintenance, setInitValuesModalMaintenance] = useState(
    {}
  );
  const [openModalContainer, setOpenModalContainer] = useState(false);
  const [filterFactory, setFilterFactory] = useState();
  const [openModalMaintenance, setOpenModalMaintenance] = useState(false);
  const [openModalReturn, setOpenModalReturn] = useState(false);
  const [titleModal, setTitleModal] = useState();
  const [idForReturn, setIdForReturn] = useState();
  const today = new Date();
  // const today = new Date("2021-12-24T00:00:00");

  //fetch states
  const [isLoading, setIsLoading] = useState();
  const [openModalFetch, setOpenModalFetch] = useState();
  const [fetchSuccess, setFetchSuccess] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_centro_log;

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });

    initFetch();
  }, []);

  const initFetch = () => {
    setIsLoading(true);
    fetch(`${global.url_back}/get_inventary/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setJson_response(json_response.stock);
            setBackupJson_response(json_response.stock);
            setProducts(json_response.products);
            setIsLoading(false);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  const onCloseModalFetch = () => {
    setOpenModalFetch(false);
  };

  const sortFunction = (sub) => {
    setSortAscen(!sortAscen);
    const listTasks = [...json_response];
    listTasks.sort((a, b) =>
      a[sub].toString().localeCompare(b[sub].toString())
    );
    if (!sortAscen) {
      setJson_response(listTasks.reverse());
    }
    setJson_response(listTasks);
  };

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const handleSearch = (value) => {
    let newList = [...json_response];
    if (value !== "") {
      newList = newList.filter(
        (row) =>
          row.nombre_cliente.toLowerCase().includes(value.toLowerCase()) ||
          row.ref_contenedor.toLowerCase().includes(value.toLowerCase())
      );
      setJson_response(newList);
    } else {
      setJson_response(backupJson_response);
    }
  };

  const comboSearch = (e) => {
    const value = e.target.value;
    if (filterFactory === value) {
      setFilterFactory("");
      setJson_response(backupJson_response);
      return;
    }
    setFilterFactory(value);
    let newList = [...backupJson_response];
    if (value === "planta") {
      newList = newList.filter((row) => row.en_planta === true);
      setJson_response(newList);
    } else if (value === "alquilado") {
      newList = newList.filter((row) => row.en_planta === false);
      setJson_response(newList);
    } else {
      setJson_response(backupJson_response);
    }
  };

  const openAddModal = () => {
    setTitleModal("Agregar");
    setInitValuesModal({});
    setOpenModalContainer(true);
  };

  const openEditModal = (index) => {
    const row = json_response[index];
    setTitleModal("Editar");
    setInitValuesModal(row);
    setOpenModalContainer(true);
  };

  const detailMaintenance = (row) => {
    setOpenModalMaintenance(true);
    setInitValuesModalMaintenance({
      mantenimiento: row.mantenimiento,
      id: row.id,
    });
  };

  const clickReturn = (id) => {
    setIdForReturn(id);
    setOpenModalReturn(true);
  };

  const callbackReturn = (value) => {
    const answer = value.value === "true";
    setOpenModalReturn(false);

    if (answer) {
      const values = { id: value.params };
      setIsLoading(true);
      // --PENDING --
      fetch(`${global.url_back}/devolution/${value.params}/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            //--PENDING--
          } else {
            setFetchSuccess(false);
            setOpenModalFetch(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setFetchSuccess(false);
          setOpenModalFetch(true);
        });
    }
  };

  const onSaveMaintenance = (values) => {
    setIsLoading(true);
    // --PENDING --
    fetch(`${global.url_back}/maintenance_action/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.status === 200) {
          let stock = [...json_response];
          stock
            .filter((row) => row.id === values.id)
            .forEach((obj) => (obj.mantenimiento = values.proximo_mtto));

          setJson_response(stock);
          setFetchSuccess(true);
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        json_response
          .filter((row) => row.id === values.id)
          .forEach((obj) => (obj.mantenimiento = values.prox_mtto));
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  const onSaveEditAdd = (values) => {
    let method;
    if (values.id) {
      method = "PUT";
    } else {
      method = "POST";
    }
    setIsLoading(true);
    fetch(`${global.url_back}/get_inventary/`, {
      method: method,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.status === 200) {
          initFetch();
          return;
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  const OnCloseModalContainer = () => setOpenModalContainer(false);
  const OnCloseModalMaintenance = () => setOpenModalMaintenance(false);
  const OnCloseReturn = () => setOpenModalReturn(false);

  return (
    <>
      {openModalContainer && (
        <ModalEditAdd
          open={openModalContainer}
          initValuesModal={initValuesModal}
          titleModal={titleModal}
          OnClose={OnCloseModalContainer}
          onSave={onSaveEditAdd}
          products={products}
        />
      )}

      {openModalMaintenance && (
        <ModalMaintenance
          open={openModalMaintenance}
          onClose={OnCloseModalMaintenance}
          initValuesModalMaintenance={initValuesModalMaintenance}
          onSave={onSaveMaintenance}
        />
      )}

      <ModalReturn
        open={openModalReturn}
        onClose={OnCloseReturn}
        callback={callbackReturn}
        params={idForReturn}
      />

      <ModalFetch
        open={openModalFetch}
        onClose={onCloseModalFetch}
        fetchSuccess={fetchSuccess}
      />

      <div className="wrapped__container_top_bar ">
        <Loading isLoading={isLoading} />
        <div className="top-bar">
          <h3>Inventario</h3>
        </div>
        <div className="search_div">
          <div className="search_container">
            <input
              type="text"
              onChange={(event) => handleSearch(event.target.value)}
            />
            <FontAwesomeIcon icon={faSearch} color="#383838" />
          </div>
          <div className="guides_stock">
            <div className="simple_flex">
              <div className="sub_guide">
                <input
                  type="Radio"
                  name="filtro_alquilados"
                  value="planta"
                  checked={filterFactory === "planta"}
                  onClick={(e) => {
                    comboSearch(e);
                  }}
                />
                <p style={{ marginLeft: "5px" }}>En Planta</p>
              </div>
              <div className="sub_guide">
                <input
                  type="Radio"
                  name="filtro_alquilados"
                  value="alquilado"
                  checked={filterFactory === "alquilado"}
                  onClick={(e) => {
                    comboSearch(e);
                  }}
                />
                <p style={{ marginLeft: "5px" }}>Alquilados</p>
              </div>
            </div>
            <div className="sub_guide">
              <div className="square_plus" onClick={() => openAddModal()}>
                <h3 className="col_white">+</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="task-content">
          <table className="table_tasks">
            <tr className="rowHeaders">
              <th style={{ width: "3%" }} />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Referencia"
                txt_filter="ref_contenedor"
                width="13%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Tipo"
                txt_filter="tipo"
                width="7%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Producto"
                txt_filter="producto"
                width="13%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Cliente"
                txt_filter="nombre_cliente"
                width="20%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Ubicación"
                txt_filter="ubicacion"
                width="13%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Fecha Remisión"
                txt_filter="fecha_remision"
                width="12%"
              />
              <TableHeader
                sortFunction={sortFunction}
                sortAscen={sortAscen}
                click_column={click_column}
                clicked_column={clicked_column}
                col_name="Prox Mtto."
                txt_filter="mantenimiento"
                width="12%"
              />
              <TableHeader col_name="Editar" width="3%" />
              <TableHeader col_name="Devolucion" width="3%" />
            </tr>
            {json_response?.map((row, index) => (
              <>
                <tr className="rowTasks">
                  <td>
                    <div className="wrapper-estado">
                      <div
                        className="square_estado"
                        style={{ backgroundColor: `${row.color}` }}
                      ></div>
                    </div>
                  </td>
                  <td>{row.ref_contenedor}</td>
                  <td>{row.tipo}</td>
                  <td>{row.producto}</td>
                  <td>{row.nombre_cliente}</td>
                  <td>{row.ubicacion}</td>
                  <td>{row.fecha_remision}</td>
                  <td>
                    {new Date(`${row.mantenimiento}T00:00:00`) <= today ? (
                      <FontAwesomeIcon
                        icon={faWrench}
                        color="red"
                        className="iconRow"
                        onClick={() => detailMaintenance(row)}
                      />
                    ) : (
                      row.mantenimiento
                    )}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEdit}
                      color={row.en_planta ? "#266e45" : "#e6e6e6"}
                      className="iconRow"
                      onClick={() => {
                        if (row.en_planta) openEditModal(index);
                      }}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faUndoAlt}
                      color={row.en_planta ? "#e6e6e6" : "#266e45"}
                      className="iconRow"
                      onClick={() => {
                        if (!row.en_planta) clickReturn(row.id);
                      }}
                    />
                  </td>
                </tr>
              </>
            ))}
          </table>
        </div>
      </div>
    </>
  );
}

export default Stock;
