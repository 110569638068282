import React, { useEffect, useState } from "react";
import { useDataLayerValue } from "../../../App/reducer/DataLayer";
import { useRouteMatch, useHistory } from "react-router-dom";
import "./Search.css";
import Select from "react-select";
import TableHeader from "../../../App/components/shared/TableHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../App/components/Loading/Loading";
import { useCookies } from "react-cookie";
import { ModalFetch } from "../../../App/components/shared/Modals";
import { url_centro_log_logistics } from "../../constants/constants";
import ModalDetail from "./ModalDetail";
import { isNumber } from "lodash";

function Search() {
  const { path, url } = useRouteMatch();
  const [{ actual_url }, dispatch] = useDataLayerValue();
  const [sortAscen, setSortAscen] = useState();
  const [clicked_column, setClicked_column] = useState("");
  const [json_response, setJson_response] = useState();
  const [selectOptions, setSelectoptions] = useState();
  const [formValues, setFormValues] = useState();
  const [result, setResult] = useState();
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [modalData, setModalData] = useState();
  const history = useHistory();

  //fetch states
  const [isLoading, setIsLoading] = useState();
  const [openModalFetch, setOpenModalFetch] = useState();
  const [fetchSuccess, setFetchSuccess] = useState();
  const [cookies] = useCookies();
  const token = cookies.token_centro_log;

  const StyleSelect = {
    control: (styles) => ({
      ...styles,
      fontFamily: "Roboto, sans-serif",
      borderColor: "#9e9e9e",
      padding: 0,
      height: "32px",
      minHeight: "32px",
    }),
    option: (styles) => ({
      ...styles,
      color: "#383838",
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
    }),
  };

  useEffect(() => {
    dispatch({
      type: "SET_URL",
      actual_url: path,
    });

    setIsLoading(true);
    fetch(`${global.url_back}/search_logistic/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setJson_response(json_response);
            setIsLoading(false);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  }, []);

  const onCloseModalFetch = () => {
    setOpenModalFetch(false);
  };

  const onCloseModalDetail = () => {
    setOpenModalDetail(false);
  };

  function isNumeric(x) {
    let value = !isNaN(x) ? x : parseFloat(x.replace(/[\$,]/g, ""));
    return { isNum: !isNaN(value), value };
  }

  function sortFunction(sub) {
    setSortAscen(!sortAscen);
    const listTasks = [...result];
    // Filter the nulls in an array and the rest in another
    let nulls = listTasks.filter((item) => item[sub] == null);
    let empty_str = listTasks.filter((item) => item[sub] == "");
    let toSort = listTasks.filter((item) => item[sub]);

    // Sort the non-null values
    let sorted = toSort.sort((a, b) => {
      // Check if both values are numeric
      let aa = isNumeric(a[sub]);
      let bb = isNumeric(b[sub]);

      // If numerics
      if (aa.isNum && bb.isNum) {
        return aa.value - bb.value;
      }

      // If strings
      return a[sub].toLowerCase() > b[sub].toLowerCase() ? 1 : -1;
    });

    sorted = sorted.concat(nulls);
    sorted = sorted.concat(empty_str);
    if (!sortAscen) {
      sorted.reverse();
    }

    setResult(sorted);
  }

  const click_column = (col_name) => {
    setClicked_column(col_name);
  };

  const ChangeInput = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const ChangeSelect = (value) => {
    if (value) {
      setFormValues({ ...formValues, razon_social: value?.value });
    } else {
      setFormValues({ ...formValues, razon_social: "" });
    }
  };

  const send_search = () => {
    setIsLoading(true);
    // --PENDING --
    fetch(`${global.url_back}/search_logistic/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            console.log(json_response);
            setIsLoading(false);
            setResult(json_response);
          });
        } else {
          setFetchSuccess(false);
          setOpenModalFetch(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setFetchSuccess(false);
        setOpenModalFetch(true);
      });
  };

  return (
    <div
      className="container__search"
      style={{ boxSizing: "border-box", paddingTop: "10px" }}
    >
      <ModalDetail
        open={openModalDetail}
        onClose={onCloseModalDetail}
        data={modalData}
      />
      <ModalFetch
        open={openModalFetch}
        onClose={onCloseModalFetch}
        fetchSuccess={fetchSuccess}
      />
      <Loading isLoading={isLoading} />
      <h3>Busqueda Requerimientos</h3>
      <div className="search_filters_container">
        <div className="filters_col">
          <div className="razon_social_container_search">
            <p className="labeltag">Razon Social:</p>
            <Select
              styles={StyleSelect}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Seleccione..."
              isSearchable={true}
              name="razon_social"
              options={json_response?.razon_social}
              isClearable={true}
              onChange={(value) => ChangeSelect(value)}
            />
          </div>
          <div>
            <p className="labeltag">Tipo Requerimiento</p>
            <select name="tipo_req" onChange={(e) => ChangeInput(e)}>
              <option>-</option>
              {json_response?.tipo_req?.map((opt) => (
                <option value={opt}>{opt}</option>
              ))}
            </select>
          </div>
          <div>
            <p className="labeltag">Fecha Desde</p>
            <input
              type="date"
              name="fecha_desde"
              onChange={(e) => ChangeInput(e)}
            />
          </div>
        </div>
        <div className="filters_col">
          <div>
            <p className="labeltag">Nro. Requerimiento</p>
            <input
              type="text"
              name="nro_req"
              onChange={(e) => ChangeInput(e)}
            />
          </div>
          <div>
            <p className="labeltag">Tipo de Transporte</p>
            <select name="tipo_transp" onChange={(e) => ChangeInput(e)}>
              <option>-</option>
              {json_response?.tipo_transporte?.map((opt) => (
                <option value={opt.value}>{opt.label}</option>
              ))}
            </select>
          </div>
          <div>
            <p className="labeltag">Fecha Hasta</p>
            <input
              type="date"
              name="fecha_hasta"
              onChange={(e) => ChangeInput(e)}
            />
          </div>
        </div>
        <div className="filters_col">
          <div>
            <p className="labeltag">Nro. Cotizacion</p>
            <input
              type="text"
              name="nro_cot"
              onChange={(e) => ChangeInput(e)}
            />
          </div>
          <div></div>
          <button
            style={{ margin: "0", maxWidth: "115px" }}
            onClick={() => send_search()}
          >
            Buscar
          </button>
        </div>
      </div>
      <table style={{ width: "100%" }}>
        <tr className="rowHeaders rowSearch">
          <th style={{ width: "3%" }} />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Nro Cot."
            txt_filter="nro_cot"
            width="7%"
          />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Tipo Req."
            txt_filter="tipo_req"
            width="7%"
          />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Nro Req."
            txt_filter="nro_req"
            width="7%"
          />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Referencia"
            txt_filter="ref_contenedor"
            width="16%"
          />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Cliente"
            txt_filter="nombre_cliente"
            width="18%"
          />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Contacto"
            txt_filter="nombre_contacto"
            width="16%"
          />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Celular"
            txt_filter="celular"
            width="11%"
          />
          <TableHeader
            sortFunction={sortFunction}
            sortAscen={sortAscen}
            click_column={click_column}
            clicked_column={clicked_column}
            col_name="Fecha"
            txt_filter="fecha_logistica"
            width="10%"
          />
          <TableHeader col_name="Detalle" width="5%" />
        </tr>
        {result?.map((row) => (
          <>
            <tr className="rowTasks">
              <td>
                <div className="wrapper-estado">
                  <div
                    className="square_estado"
                    style={{ backgroundColor: `${row.color}` }}
                  ></div>
                </div>
              </td>
              <td>{row.nro_cot}</td>
              <td>{row.tipo_req}</td>
              <td>{row.nro_req}</td>
              <td>{row.ref_contenedor}</td>
              <td>{row.nombre_cliente}</td>
              <td>{row.nombre_contacto}</td>
              <td>{row.celular}</td>
              <td>{row.fecha_logistica}</td>
              <td>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#383838"
                  className="iconRow"
                  onClick={() => {
                    setModalData(row);
                    setOpenModalDetail(true);
                  }}
                />
              </td>
            </tr>
          </>
        ))}
      </table>
    </div>
  );
}

export { Search };
