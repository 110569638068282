import React, { useState, useEffect } from "react";
import "./ClientAdd.css";
import {
  Cities,
  Industries,
  Month_numbers,
  Months,
} from "../../../App/services/services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Cookies from "universal-cookie";
import { CountryDropdown } from "react-country-region-selector";
import { Prompt } from "react-router";
import {
  url_crm_clients,
  url_crm_quote,
} from "../../../CRM/constants/constants";

function ClientAdd() {
  const cookies = new Cookies();
  const token = cookies.get("token_crm");
  const codigo_vendedor = cookies.get("codigo_vendedor");
  const super_user = cookies.get("super_user");

  const history = useHistory();

  const [responseVendors, setResponseVendors] = useState();
  const [pk, setpk] = useState();
  const [pk_contact, setpkc] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [typePerson, setTypePerson] = useState("Juridica");
  const [countryClient, setCountryClient] = useState("Colombia");
  const [countryContact, setCountryContact] = useState("Colombia");

  const [nit, setNit] = useState("");
  const [dv, setDv] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);

  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [shouldBlockNavigation, setBlock] = useState(true);

  global.cancel_rut = false;

  const doSomething = () => {
    var input;
    input = window.confirm("Desea salir sin guardar los cambios?");
    global.cancel_rut = input;

    return input;
  };

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    fetch(`${global.url_back}/get_code_vendors/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((json_response) => {
            setResponseVendors(json_response);
          });
        } else {
          console.log("error UserConfig");
          setFetchSuccess(false);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setFetchSuccess(false);
        setOpenDialog(true);
      });
  }, []);

  const getDV = (event) => {
    setNit(event.target.value);
    var myNit = event.target.value;
    var dv = 0;
    var vpri, x, y, z;

    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ""); // Espacios
    myNit = myNit.replace(/,/g, ""); // Comas
    myNit = myNit.replace(/\./g, ""); // Puntos
    myNit = myNit.replace(/-/g, ""); // Guiones

    // Se valida el nit
    if (isNaN(myNit)) {
      console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      return "";
    }

    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;
    // console.log(z)
    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
      y = myNit.substr(i, 1);
      // console.log ( y + "x" + vpri[z-i] + ":" ) ;

      x += y * vpri[z - i];
      // console.log ( x ) ;
    }

    y = x % 11;
    // console.log ( y ) ;

    dv = y > 1 ? 11 - y : y;
    if (z >= 9) {
      setDv(dv);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      razonSocial: "",
      codigo: codigo_vendedor,
      ciudad: "",
      direccion: "",
      ind: "",
      telefono: "",
      canalContacto: "",
      comoConocio: "",
      sector: "CONSTRUCCION",
      nombres: "",
      apellidos: "",
      nombreComercial: "",
      cedula: "",
      cargo: "",
      email: "",
      indContacto: "",
      telefonoContacto: "",
      extension: "",
      celular: "",
      genero: "",
      ciudadContacto: "",
      dia: "",
      mes: "",
      profesion: "",
    },
    onSubmit: (values) => {
      setIsSubmitting(true);
      setBlock(false);
      fetch(`${global.url_back}/create_client/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          values: values,
          typePerson: typePerson,
          countryClient: countryClient,
          countryContact: countryContact,
          nit: nit,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((json_response2) => {
              setpk(json_response2.pk);
              setpkc(json_response2.pk_c);
              setIsSubmitting(false);
              setFetchSuccess(true);
              setOpenDialog3(true);
            });
          } else if (response.status === 409) {
            setIsSubmitting(false);
            setFetchSuccess(false);
            setOpenDialog2(true);
            console.log("error Cliente ya existe");
          } else {
            setIsSubmitting(false);
            setFetchSuccess(false);
            setOpenDialog(true);
            console.log("error fetchingform-clientAdd");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setFetchSuccess(false);
          setOpenDialog(true);
          console.log(error);
        });
    },
  });

  const handleClose = () => {
    setOpenDialog3(false);

    history.push(url_crm_clients);
  };

  const handleClickquotes = () => {
    setOpenDialog3(false);

    history.push(`${url_crm_quote}/quoteadd`, {
      state: {
        tipo: typePerson,

        razon_social: formik.values.razonSocial.toUpperCase(),

        nombre_cliente: formik.values.nombres + " " + formik.values.apellidos,

        email_cliente: formik.values.email,

        pk_cliente: pk,
        pk_contacto: pk_contact,
      },
    });
  };

  return (
    <div className="container__clientadd">
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          if (fetchSuccess) {
            setBlock(false);
            history.push(url_crm_clients);
          }
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog}
      >
        <DialogTitle id="simple-dialog-title">
          {fetchSuccess ? (
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fetchSuccess
              ? "Cliente creado con Exito"
              : "Algo salio mal, intenta nuevamente"}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenDialog2(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog2}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="red" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            El NIT ya se encuentra registrado.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenDialog3(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={openDialog3}
      >
        <DialogTitle id="simple-dialog-title">
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cliente creado con exito, ¿desea generar una cotizacion a este
            cliente?
          </DialogContentText>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button
              className="btn-userconfigadd"
              onClick={handleClickquotes}
              type="button"
            >
              Si
            </button>
            <button
              className="btn-userconfigadd"
              onClick={handleClose}
              type="button"
            >
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <img
        src={require("../../../App/images/fabrica.svg")}
        alt=""
        className="img__factory__addclient"
      />
      <h4>Clientes</h4>
      <p style={{ marginBottom: "10px", fontWeight: "bold" }}>
        Tipo de Persona
      </p>
      <div className="clienteadd_radiobutton">
        <input
          className="inputRadio__addclient"
          type="radio"
          id="Natural"
          name="tipoPersona"
          value={typePerson}
          checked={typePerson === "Natural"}
          onClick={() => setTypePerson("Natural")}
        />
        <label for="contactChoice1">Natural</label>
        <input
          className="inputRadio__addclient"
          type="radio"
          id="Juridica"
          name="tipoPersona"
          value={typePerson}
          checked={typePerson === "Juridica"}
          onClick={() => setTypePerson("Juridica")}
        />
        <label for="contactChoice1">Juridica</label>
      </div>
      {typePerson === "Juridica" ? (
        <form onSubmit={formik.handleSubmit}>
          <h4 style={{ margin: "15px 0" }}>Informacion Compañia</h4>
          <div className="flex_razon_codigo">
            <div className="razon_social">
              <p className="labeltag">Razon Social *</p>
              <input
                type="text"
                style={{ width: "100%", textTransform: "uppercase" }}
                name="razonSocial"
                onChange={formik.handleChange}
                value={formik.values.razonSocial.toUpperCase()}
                required
              />
            </div>
            <div className="code">
              <p className="labeltag">Codigo *</p>
              <select
                name="codigo"
                onChange={formik.handleChange}
                value={formik.values.codigo}
                disabled={super_user === "0" ? true : false}
              >
                {responseVendors?.map((codigo) => (
                  <option value={codigo}>{codigo}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex_other_info">
            <div className="column1__clientadd" style={{ marginRight: "15%" }}>
              <div className="flex_double_input">
                <div className="nit">
                  <p className="labeltag">Nit *</p>
                  <input
                    type="number"
                    className="input__info "
                    onChange={(event) => getDV(event)}
                    value={nit}
                  />
                </div>
                <div className="dv">
                  <p className="labeltag">DV</p>
                  <input
                    type="text"
                    className="input__info nit_input"
                    value={dv}
                  />
                </div>
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Pais *</p>
                <CountryDropdown
                  value={countryClient}
                  onChange={(val) => setCountryClient(val)}
                  style={{ marginBottom: "15px" }}
                />
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Ciudad *</p>
                <input
                  required
                  type="text"
                  className="input__info"
                  list="ciudades"
                  name="ciudad"
                  onChange={formik.handleChange}
                  value={formik.values.ciudad}
                />
                {countryClient === "Colombia" ? <Cities id="ciudades" /> : null}
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Direccion</p>
                <input
                  type="text"
                  className="input__info full-width"
                  name="direccion"
                  onChange={formik.handleChange}
                  value={formik.values.direccion}
                />
              </div>
              <div className="flex_double_input">
                <div className="ind">
                  <p className="labeltag">Ind.</p>
                  <input
                    type="number"
                    className="input__info ind__input"
                    name="ind"
                    onChange={formik.handleChange}
                    value={formik.values.ind}
                  />
                </div>
                <div className="nit">
                  <p className="labeltag">Telefono</p>
                  <input
                    type="number"
                    className="input__info"
                    name="telefono"
                    onChange={formik.handleChange}
                    value={formik.values.telefono}
                  />
                </div>
              </div>
            </div>
            <div className="column1__clientadd">
              <div className="input_tag_addclient">
                <p className="labeltag">Canal de Contacto *</p>
                <select
                  name="canalContacto"
                  onChange={formik.handleChange}
                  value={formik.values.canalContacto}
                  className="select__addclient"
                  required
                >
                  <option value="">-</option>
                  <option value="Telefono">Telefono</option>
                  <option value="WhatsApp">WhatsApp</option>
                  <option value="Correo">Correo</option>
                  <option value="Web">Web</option>
                  <option value="Personal">Personal</option>
                  <option value="Redes">Redes</option>
                </select>
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Como nos conocio?</p>
                <select
                  name="comoConocio"
                  onChange={formik.handleChange}
                  value={formik.values.comoConocio}
                  className="select__addclient"
                >
                  <option value="">-</option>
                  <option value="Web">Web</option>
                  <option value="Redes">Redes</option>
                  <option value="Referido">Referido</option>
                </select>
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Sector *</p>
                <select
                  name="sector"
                  onChange={formik.handleChange}
                  value={formik.values.sector}
                  className="select__addclient"
                >
                  <Industries />
                </select>
              </div>
            </div>
          </div>
          <h4 style={{ margin: "15px 0" }}> Informacion de Contacto</h4>
          <div className="other_info_addcontact">
            <div className="col-contact" style={{ marginRight: "15%" }}>
              <div className="input_tag_addclient">
                <p className="labeltag">Nombres *</p>
                <input
                  type="text"
                  className="input__info full-width"
                  name="nombres"
                  onChange={formik.handleChange}
                  value={formik.values.nombres}
                  required
                />
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Apellidos *</p>
                <input
                  type="text"
                  className="input__info full-width"
                  name="apellidos"
                  onChange={formik.handleChange}
                  value={formik.values.apellidos}
                  required
                />
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Cargo</p>
                <input
                  type="text"
                  className="input__info full-width"
                  name="cargo"
                  onChange={formik.handleChange}
                  value={formik.values.cargo}
                />
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Email *</p>
                <input
                  type="email"
                  className="input__info full-width"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  required
                />
              </div>
              <div className="flex_triple_input">
                <div className="flex_triple_input_div1">
                  <p className="labeltag">Ind.</p>
                  <input
                    type="number"
                    className="flex_triple_input_ind"
                    name="indContacto"
                    onChange={formik.handleChange}
                    value={formik.values.indContacto}
                    required={formik.values.celular === "" ? true : false}
                  />
                </div>
                <div className="flex_triple_input_div2">
                  <p className="labeltag">Telefono</p>
                  <input
                    type="number"
                    className="flex_triple_input_tel"
                    name="telefonoContacto"
                    onChange={formik.handleChange}
                    value={formik.values.telefonoContacto}
                    required={formik.values.celular === "" ? true : false}
                  />
                </div>
                <div className="flex_triple_input_div3">
                  <p className="labeltag">Ext.</p>
                  <input
                    type="number"
                    className="flex_triple_input_ext"
                    name="extension"
                    onChange={formik.handleChange}
                    value={formik.values.extension}
                  />
                </div>
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Celular</p>
                <input
                  type="number"
                  className="input__info full-width"
                  name="celular"
                  onChange={formik.handleChange}
                  value={formik.values.celular}
                  required={
                    formik.values.telefonoContacto === "" ? true : false
                  }
                />
              </div>
            </div>
            <div className="col-contact">
              <div className="input_tag_addclient">
                <p className="labeltag">Genero *</p>

                <select
                  className="select__addclient"
                  name="genero"
                  onChange={formik.handleChange}
                  value={formik.values.genero}
                  required
                >
                  <option value="">-</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                </select>
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Pais *</p>
                <CountryDropdown
                  value={countryContact}
                  onChange={(val) => setCountryContact(val)}
                  style={{ marginBottom: "15px" }}
                />
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Ciudad *</p>
                <input
                  required
                  type="text"
                  className="input__info"
                  list="ciudades"
                  name="ciudadContacto"
                  onChange={formik.handleChange}
                  value={formik.values.ciudadContacto}
                />
                {countryClient === "Colombia" ? <Cities id="ciudades" /> : null}
              </div>

              <div className="input_tag_addclient">
                <p className="labeltag">Cumpleaños</p>
                <div className="flex-cumpleaños">
                  <select
                    className="select__addclient"
                    name="dia"
                    onChange={formik.handleChange}
                    value={formik.values.dia}
                    style={{ width: "30%" }}
                  >
                    <Month_numbers />
                  </select>
                  <select
                    className="select__addclient"
                    name="mes"
                    onChange={formik.handleChange}
                    value={formik.values.mes}
                    style={{ width: "60%" }}
                  >
                    <Months />
                  </select>
                </div>
              </div>
              <div className="input_tag_addclient">
                <p className="labeltag">Profesion</p>
                <input
                  type="text"
                  className="input__info full-width"
                  name="profesion"
                  onChange={formik.handleChange}
                  value={formik.values.profesion}
                />
              </div>
              <button
                className="btn-userconfig"
                type="submit"
                style={{ margin: "20px 0 0 auto" }}
              >
                {isSubmitting ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin"
                    style={{ fontSize: "25px" }}
                  />
                ) : (
                  "Guardar"
                )}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <form className="type_natural_info" onSubmit={formik.handleSubmit}>
          <div className="col-contact" style={{ marginRight: "10%" }}>
            <div className="input_tag_addclient">
              <p className="labeltag">Nombres *</p>
              <input
                type="text"
                className="input__info full-width"
                name="nombres"
                onChange={formik.handleChange}
                value={formik.values.nombres}
                required
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Apellidos *</p>
              <input
                type="text"
                className="input__info full-width"
                name="apellidos"
                onChange={formik.handleChange}
                value={formik.values.apellidos}
                required
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Nombre Comercial</p>
              <input
                type="text"
                className="input__info full-width"
                name="nombreComercial"
                onChange={formik.handleChange}
                value={formik.values.nombreComercial}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Cedula *</p>
              <input
                type="number"
                className="input__info full-width"
                name="cedula"
                onChange={formik.handleChange}
                value={formik.values.cedula}
                required
              />
            </div>
            <div className="flex_triple_input">
              <div className="flex_triple_input_div1">
                <p className="labeltag">Ind.</p>
                <input
                  type="number"
                  className="flex_triple_input_ind"
                  name="indContacto"
                  onChange={formik.handleChange}
                  value={formik.values.indContacto}
                  required={formik.values.celular === "" ? true : false}
                />
              </div>
              <div className="flex_triple_input_div2">
                <p className="labeltag">Telefono</p>
                <input
                  type="number"
                  className="flex_triple_input_tel"
                  name="telefonoContacto"
                  onChange={formik.handleChange}
                  value={formik.values.telefonoContacto}
                  required={formik.values.celular === "" ? true : false}
                />
              </div>
              <div className="flex_triple_input_div3">
                <p className="labeltag">Ext.</p>
                <input
                  type="number"
                  className="flex_triple_input_ext"
                  name="extension"
                  onChange={formik.handleChange}
                  value={formik.values.extension}
                />
              </div>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Celular</p>
              <input
                type="number"
                className="input__info full-width"
                name="celular"
                onChange={formik.handleChange}
                value={formik.values.celular}
                required={formik.values.telefonoContacto === "" ? true : false}
              />
            </div>
          </div>
          <div className="col-contact" style={{ marginRight: "10%" }}>
            <div className="input_tag_addclient">
              <p className="labeltag">Email *</p>
              <input
                type="email"
                className="input__info full-width"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Pais *</p>
              <CountryDropdown
                value={countryContact}
                onChange={(val) => setCountryContact(val)}
                style={{ marginBottom: "15px" }}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Ciudad *</p>
              <input
                required
                type="text"
                className="input__info"
                list="ciudades"
                name="ciudadContacto"
                onChange={formik.handleChange}
                value={formik.values.ciudadContacto}
              />
              {countryClient === "Colombia" ? <Cities id="ciudades" /> : null}
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Direccion</p>
              <input
                type="text"
                className="input__info full-width"
                name="direccion"
                onChange={formik.handleChange}
                value={formik.values.direccion}
              />
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Cumpleaños</p>
              <div className="flex-cumpleaños">
                <select
                  className="select__addclient"
                  name="dia"
                  onChange={formik.handleChange}
                  value={formik.values.dia}
                  style={{ width: "30%" }}
                >
                  <Month_numbers />
                </select>
                <select
                  className="select__addclient"
                  name="mes"
                  onChange={formik.handleChange}
                  value={formik.values.mes}
                  style={{ width: "60%" }}
                >
                  <Months />
                </select>
              </div>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Profesion</p>
              <input
                type="text"
                className="input__info full-width"
                name="profesion"
                onChange={formik.handleChange}
                value={formik.values.profesion}
              />
            </div>
          </div>
          <div className="col-contact">
            <div className="input_tag_addclient">
              <p className="labeltag">Genero *</p>
              <select
                className="select__addclient"
                name="genero"
                onChange={formik.handleChange}
                value={formik.values.genero}
                required
              >
                <option value="">-</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
              </select>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Canal de Contacto *</p>
              <select
                name="canalContacto"
                onChange={formik.handleChange}
                value={formik.values.canalContacto}
                className="select__addclient"
                required
              >
                <option value="">-</option>
                <option value="Telefono">Telefono</option>
                <option value="WhatsApp">WhatsApp</option>
                <option value="Correo">Correo</option>
                <option value="Web">Web</option>
                <option value="Personal">Personal</option>
                <option value="Redes">Redes</option>
              </select>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Como nos conocio?</p>
              <select
                name="comoConocio"
                onChange={formik.handleChange}
                value={formik.values.comoConocio}
                className="select__addclient"
              >
                <option value="">-</option>
                <option value="Web">Web</option>
                <option value="Redes">Redes</option>
                <option value="Referido">Referido</option>
              </select>
            </div>
            <div className="input_tag_addclient">
              <p className="labeltag">Sector *</p>
              <select
                name="sector"
                onChange={formik.handleChange}
                value={formik.values.sector}
                className="select__addclient"
              >
                <Industries />
              </select>
            </div>
            <div className="input_tag_addclient code_natural">
              <p className="labeltag">Codigo</p>
              <select
                name="codigo"
                onChange={formik.handleChange}
                value={formik.values.codigo}
                disabled={super_user === "0" ? true : false}
              >
                {responseVendors?.map((codigo) => (
                  <option value={codigo}>{codigo}</option>
                ))}
              </select>
            </div>
            <button
              className="btn-userconfig"
              type="submit"
              style={{ margin: "50px 0 0 auto" }}
            >
              {isSubmitting ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fa-spin"
                  style={{ fontSize: "25px" }}
                />
              ) : (
                "Guardar"
              )}
            </button>
          </div>
        </form>
      )}
      <Prompt
        when={shouldBlockNavigation}
        message={(location, action) => {
          if (action === "PUSH") {
            // console.log("Backing up...");
          }

          return location.pathname.startsWith("/app") ? true : doSomething();
        }}
      />
    </div>
  );
}

export default ClientAdd;
